import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BLUE, LIGHT_GREY, GREY, WHITE } from "../constants/cts_colors";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { IMembersInfos } from "../interfaces/members";
import { useTranslation } from "react-i18next";
import UserMemberLine from "./UserMemberLine";
import { useMembers } from "../common/contexts/memberContext";
import Button from "./Button";
import { ImCross } from "react-icons/im";
import { useCompanies } from "../common/contexts/companyContext";
import { ICompaniesInfos } from "../interfaces/companies";

const SettingsAdmin = ({}: {}) => {
  const { t } = useTranslation();

  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  const initialMembersFormState = {
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    position: "",
    status: true,
  };

  const [memberFormValues, _setMemberFormValues] = useState(
    initialMembersFormState
  );

  const [isOperationPopupOpen, _setOperationPopupOpen] =
    useState<boolean>(true);

  const [isCreationMembersPopupOpen, _setIsCreationMembersPopupOpen] =
    useState<boolean>(false);
  const [isUpdateMembersPopupOpen, _setIsUpdateMembersPopupOpen] =
    useState<boolean>(false);

  const [members, _setMembers] = useState<Array<IMembersInfos>>([]);

  const { onGetAllAdmins, switchToAdminAMember } = useMembers();

  const { onGetAllCompanies } = useCompanies();

  // ***** MEMBERS FUNCTIONS *****
  // MEMBER CHANGE
  const handleMemberChange = (e: any) => {
    // console.log(e);
    const { name, value } = e.target;
    _setMemberFormValues({
      ...memberFormValues,
      [name]: value,
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    onGetAllAdmins()
      .then((response: any) => {
        _setMembers(response);
      })
      .catch((error: any) => alert(error));
  };

  const onRemoveToAdmin = (member: IMembersInfos) => {
    if (confirm("Supprimer " + member.first_name + " des administrateurs ?")) {
      switchToAdminAMember({ id: member.id, isAdmin: false }).then(() => {
        onLoad();
      });
    }
  };

  return (
    <SettingsMembersStyle>
      {/* HEADER OF MEMBERS */}
      <div
        className="mx-28 my-4"
        style={{ borderColor: LIGHT_GREY, borderBottomWidth: 1 }}
      >
        <div
          className="flex basis-12 items-center pt-3 pb-6"
          style={{
            color: GREY,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*<div className="h-full basis-1/12 flex items-center justify-center">
            <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
        </div>*/}
          <p className="basis-3/12">
            {t("members.user", { ns: "settingsPage" })}
          </p>
          <p className="basis-1/12">
            {t("members.status", { ns: "settingsPage" })}
          </p>
          <p className="basis-2/12 col-start-6">
            {t("members.email", { ns: "settingsPage" })}
          </p>
        </div>
      </div>

      <div className="overflow-scroll" style={{ height: "62vh" }}>
        {/* LIST OF MEMBERS */}
        {members &&
          members.map((member, key) => {
            return (
              <UserMemberLine
                key={key}
                member={member}
                onClick={() => onRemoveToAdmin(member)}
              />
            );
          })}

        {members && members.length < 1 && (
          <NoContentSentence className="w-full text-center">
            {t("members.noMembers", { ns: "settingsPage" })}
          </NoContentSentence>
        )}
      </div>
    </SettingsMembersStyle>
  );
};

export default SettingsAdmin;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsMembersStyle = styled.div`
  width: 100%;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;
