import { instanceAxios } from "../utils/axios-api";

export const onAnalyticsPodcastsPageViewApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/podcasts-page-view`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsResourcesPageViewApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/resources-page-view`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsCommunityPageViewApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/community-page-view`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsMembersDirectoryPageViewApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/members-directory-page-view`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsFormationsPageViewApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/formations-page-view`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsPodcastClickApi = async (podcastId: number) => {
  return instanceAxios
    .post(`/analytic-logs/podcast-click`, {podcastId})
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsResourceClickApi = async (resourceId: number) => {
  return instanceAxios
    .post(`/analytic-logs/resource-click`, {resourceId})
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsFormationClickApi = async (formationId: number) => {
  return instanceAxios
    .post(`/analytic-logs/formation-click`, {formationId})
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsLinkedinLinkClickApi = async () => {
  return instanceAxios
    .post(`/analytic-logs/linkedin-link-click`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsPodcastListeningPercentageApi = async ({
  podcastId,
  listeningPercentage,
}: {
  podcastId: number,
  listeningPercentage: number,
}) => {
  return instanceAxios
    .post(`/analytic-logs/podcast-listening-percentage`, {podcastId, listeningPercentage})
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsPodcastListeningMinutesApi = async ({
  podcastId,
  listeningMinutes,
}: {
  podcastId: number,
  listeningMinutes: number,
}) => {
  return instanceAxios
    .post(`/analytic-logs/podcast-listening-minutes`, {podcastId, listeningMinutes})
    .then((response) => (response.data ? response.data.data : null));
};

export const onAnalyticsGetMyListeningsPercentagesForPodcastApi = async (podcastId: number) => {
  return instanceAxios
    .get(`/analytic-logs/get-my-listenings-for-podcast/` + podcastId)
    .then((response) => (response.data ? response.data.data : null));
};