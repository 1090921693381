import React, { ChangeEventHandler, ReactElement } from "react";
import styled from "styled-components";
import { BLACK, COLOR_FORM_INFO_MESSAGE, GREY, RED, WHITE } from "../../constants/cts_colors";
import infoIcon from '../../assets/images/form/info.png';

const Textarea = ({
  placeholder,
  icon,
  name,
  value,
  disabled,
  error,
  onChange,
  onClickIcon,
}: {
  placeholder: string,
  icon?: ReactElement,
  name?: string,
  value?: string | number,
  disabled?: boolean,
  error?: string,
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
  onClickIcon?: Function,
}) => {
  return (
    <>
      <TextareaContainer
        className={error ? 'error' : ''}>
        <FormTextarea placeholder={placeholder} onChange={onChange} name={name} value={value} disabled={disabled} />
        {
          icon &&
          <Icon onClick={onClickIcon ? () => onClickIcon() : () => {}}>{icon}</Icon>
        }
      </TextareaContainer>
      {
        error &&
        <Error>
          <ErrorIcon src={infoIcon} />
          <ErrorText>{error}</ErrorText>
        </Error>
      }
    </>
  )
}

export default Textarea;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const TextareaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 12px;
  border: 1px solid ${GREY};
  border-radius: 16px;

  &.error {
    border-color: ${RED};
  }
`

const FormTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  font-size: 16px;
  color: ${BLACK};
  background-color: ${WHITE};

  // chrome - remove bg on autocomplete
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    background-clip: text;
    box-shadow: inset 0 0 20px 20px transparent;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${WHITE};
  }
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: '#49536E';

  img {
    display: block;
    margin-top: 3px;
    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const Error = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`

const ErrorIcon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
`

const ErrorText = styled.p`
  color: ${COLOR_FORM_INFO_MESSAGE};
  font-size: 14px;
`