import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import {
  onCreateLikeApi,
  onGetAllLikesFromUserApi,
  onGetNumberLikesForPostApi,
} from "../../api/like.api";
import { ILikesContext, ILikesInfos } from "../../interfaces/likes";

const LikesContext = createContext(null);

export function LikesProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateLike = useCallback(
    ({ user_id, post_id, comment_id }: ILikesInfos) => {
      _setIsLoading(true);
      return onCreateLikeApi({
        user_id,
        post_id,
        comment_id,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllLikesFromUser = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetAllLikesFromUserApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetNumberLikesForPost = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetNumberLikesForPostApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <LikesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateLike,
        onGetAllLikesFromUser,
        onGetNumberLikesForPost,
      }}
    />
  );
}

export const useLikes = (): ILikesContext => {
  const context = React.useContext(LikesContext);
  if (!context) throw new Error("useLikes must be used in LikeProvider");

  return context;
};
