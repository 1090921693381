import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import InputWithLabel from "../../components/form/InputWithLabel";
import eyeOpenIcon from "../../assets/images/form/eye-open.png";
import eyeClosedIcon from "../../assets/images/form/eye-closed.png";
import { IUserChangePasswordFormData, IUserInfos } from "../../interfaces/user";
import Button from "../../components/Button";
import styled from "styled-components";
import { useUser } from "../../common/contexts/userContext";
import { PASSWORD_CHANGED } from "../../constants/cts_infoMessages";
import { GREY } from "../../constants/cts_colors";

const ChangePasswordForm = ({user}: {user: IUserInfos}) => {
  const { t } = useTranslation();
  const { onChangePassword } = useUser();
  const [formData, _setFormData] = useState<IUserChangePasswordFormData>({
    newPassword: {
      inputName: 'newPassword',
      value: '',
      isVisible: false,
      error: '',
    },
    repeatNewPassword: {
      inputName: 'repeatNewPassword',
      value: '',
      isVisible: false,
      error: '',
    },
  })
  const [infoMessage, _setInfoMessage] = useState<string>('')

  // HANDLE CHANGE
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    let input = null

    switch (name) {
      case formData.newPassword.inputName:
        input = formData.newPassword
        break;
      case formData.repeatNewPassword.inputName:
        input = formData.repeatNewPassword
        break;
    }

    if (input) {
      _setFormData({
        ...formData,
        [input.inputName]: {
          ...(formData as any)[input.inputName],
          value,
          error: '',
        }
      })
      _setInfoMessage('')
    }
  }

  // change password - handle submit
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onChangePassword({
      userId: user.id,
      newPassword: formData.newPassword,
      repeatPassword: formData.repeatNewPassword,
    })
      .then(() => {
        _setInfoMessage(
          '' + t(PASSWORD_CHANGED, { ns: "infoMessages" })
        )
      })
      .catch((error: {inputName: string, message: string}) => {
        if (error.inputName) {
          _setFormData({
            ...formData,
            [error.inputName]: {
              ...(formData as any)[error.inputName],
              error: error.message,
            },
          })
        } else {
          console.error(error)
        }
      });
  };

  // TOGGLE PASSWORD VISIBILITY
  const togglePasswordVisibility = (name: string) => {
    let input = null
    switch (name) {
      case formData.newPassword.inputName:
        input = formData.newPassword
        break;
      case formData.repeatNewPassword.inputName:
        input = formData.repeatNewPassword
        break;
    }

    if (input) {
      _setFormData({
        ...formData,
        [input.inputName]: {
          ...(formData as any)[input.inputName],
          isVisible: !(formData as any)[input.inputName].isVisible,
        }
      })
    }
  }
  
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      {/* new password */}
      <InputWithLabel
        type={formData.newPassword.isVisible ? 'text' : 'password'}
        label={t("changePasswordForm.form.labels.password", {ns: "accountPage"})}
        placeholder={t("changePasswordForm.form.placeholders.password", {ns: "accountPage"})}
        icon={<Icon src={formData.newPassword.isVisible ? eyeOpenIcon : eyeClosedIcon} />}
        onClickIcon={() => togglePasswordVisibility(formData.newPassword.inputName)}
        name={formData.newPassword.inputName}
        value={formData.newPassword.value}
        onChange={(e) => handleChange(e)}
        error={formData.newPassword.error} />
      {/* repeat new password */}
      <InputWithLabel
        type={formData.repeatNewPassword.isVisible ? 'text' : 'password'}
        label={t("changePasswordForm.form.labels.repeatPassword", {ns: "accountPage"})}
        placeholder={t("changePasswordForm.form.placeholders.repeatPassword", {ns: "accountPage"})}
        icon={<Icon src={formData.repeatNewPassword.isVisible ? eyeOpenIcon : eyeClosedIcon} />}
        onClickIcon={() => togglePasswordVisibility(formData.repeatNewPassword.inputName)}
        name={formData.repeatNewPassword.inputName}
        value={formData.repeatNewPassword.value}
        onChange={(e) => handleChange(e)}
        error={formData.repeatNewPassword.error} />
      {
        infoMessage &&
        <FormInfoMessage>{infoMessage}</FormInfoMessage>
      }
      <Button
        text={t("changePasswordForm.form.submitBtn", {ns: "accountPage"})}
        onClick={(e) => handleSubmit(e)}
      />
    </Form>
  )
}

export default ChangePasswordForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  padding-right: 5px;
`

const Icon = styled.img`
  cursor: pointer;
`

const FormInfoMessage = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${GREY};
`