import React from "react";
import styled from "styled-components";
import { BLACK, BLUE, CLEAR_BLUE, WHITE } from "../../constants/cts_colors";
import { IMembersInfos } from "../../interfaces/members";
import { useTranslation } from "react-i18next";
import { useAnalyticLogs } from "../../common/contexts/analyticLogContext";

const MemberInfosSidebar = ({
  onClose,
  member,
}: {
  onClose: Function;
  member: IMembersInfos;
}) => {
  const { t } = useTranslation();
  const { onAnalyticsLinkedinLinkClick } = useAnalyticLogs()

  const analyticsLinkedinLinkClick = () => {
    onAnalyticsLinkedinLinkClick()
      .then(() => {})
      .catch((error) => console.error(error))
  }

  const getAnecdoteCard = ({anecdoteProperty, anecdote}: {anecdoteProperty: string, anecdote: string}) => {
    let title = ''

    switch (anecdoteProperty) {
      case 'anecdote1':
        title = t("memberInfosSidebar.anecdotes.anecdote1", { ns: "communityPage" })
        break;
      case 'anecdote2':
        title = t("memberInfosSidebar.anecdotes.anecdote2", { ns: "communityPage" })
        break;
      case 'anecdote3':
        title = t("memberInfosSidebar.anecdotes.anecdote3", { ns: "communityPage" })
        break;
    }

    return (
      <Anecdote>
        <AnecdoteTitle>{title}</AnecdoteTitle>
        <AnecdoteText>{anecdote}</AnecdoteText>
      </Anecdote>
    )
  }

  return (
    <Container>
      <div
        className='overlay'
        onMouseDown={(e) => {
          // check if user clicked (left click) on the overlay
          if (e.nativeEvent.button === 0 && e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <SidebarContainer>
          <InsideSidebarContainer>
            {/* profile */}
            <Profile>
              {/* image */}
              <Image>
                <ImageFrame>
                  {
                    member.image &&
                    <img src={member.image} alt="member" />
                  }
                </ImageFrame>
              </Image>
              {/* infos */}
              <Infos>
                {/* name */}
                <Name>{member.first_name + ' ' + member.last_name}</Name>
                {/* position */}
                <Position>{member.position}</Position>
                {/* company */}
                <Company>{member.companyInfos.name}</Company>
                {/* email */}
                <Email>{member.email}</Email>
              </Infos>
            </Profile>
            {/* linkedin link */}
            {
              member.linkedinLink &&
              <LinkedinBtn
                onClick={() => analyticsLinkedinLinkClick()}
                href={member.linkedinLink}
                target="_blank"
                rel="noopener noreferrer">
                {t("memberInfosSidebar.linkedinBtn", { ns: "communityPage" })}
              </LinkedinBtn>
            }

            {/* anecdotes */}
            {
              member.anecdotes &&
              <Anecdotes>
                {
                  member.anecdotes.anecdote1 &&
                  getAnecdoteCard({anecdoteProperty: 'anecdote1', anecdote: member.anecdotes.anecdote1})
                }
                {
                  member.anecdotes.anecdote2 &&
                  getAnecdoteCard({anecdoteProperty: 'anecdote2', anecdote: member.anecdotes.anecdote2})
                }
                {
                  member.anecdotes.anecdote3 &&
                  getAnecdoteCard({anecdoteProperty: 'anecdote3', anecdote: member.anecdotes.anecdote3})
                }
              </Anecdotes>
            }

            {/* pitch */}
            {
              member.pitch &&
              <>
                <Separator />
                <PitchTitle>
                  {t("memberInfosSidebar.pitchTitle", { ns: "communityPage" })}
                </PitchTitle>
                <PitchText>
                  {member.pitch}
                </PitchText>
              </>
            }
          </InsideSidebarContainer>
        </SidebarContainer>
      </div>
    </Container>
  );
};

export default MemberInfosSidebar;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 430px;
  height: 100%;
  background-color: ${CLEAR_BLUE};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px 0px 0px 16px;
  padding: 64px 48px;
  display: flex;
`;

const InsideSidebarContainer = styled.div`
  flex-grow: 1;
  overflow: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const Image = styled.div`
  display: block;
  min-width: 100px;
  max-width: 100px;
  border-radius: 50%;
  background-color: ${CLEAR_BLUE};
  border: 0.92px solid ${BLUE};
`

const ImageFrame = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const Profile = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
`

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Name = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${BLUE};
`

const Position = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${BLACK};
`

const Company = styled.span`
  font-size: 14px;
  color: ${BLACK};
`

const Email = styled.span`
  font-size: 14px;
  color: ${BLUE};
`

const LinkedinBtn = styled.a`
  margin-top: 32px;
  display: block;
  padding: 20.5px 32px;
  background-color: ${BLUE};
  border-radius: 100px;
  color: ${WHITE};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.9;
  }
`

const Anecdotes = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Anecdote = styled.div`
  background-color: ${WHITE};
  border-radius: 8px;
  padding: 24px 16px;
`

const AnecdoteTitle = styled.span`
  font-size: 16px;
`

const AnecdoteText = styled.p`
  margin-top: 8px;
  font-size: 24px;
  font-weight: bold;
`

const Separator = styled.div`
  margin: 32px 0px;
  width: 100%;
  height: 2px;
  background-color: ${BLUE};
  border-radius: 1000;
`

const PitchTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
`

const PitchText = styled.p`
  font-size: 16px;
  white-space: pre-wrap; // preserve line breaks
`
