import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useParams,
  useLocation,
} from "react-router-dom";
import { useAuthentication } from "../common/contexts/authenticationContext";
import WrapperConnected from "../components/wrapper-conected";
import LoginPage from "./login/login";
import LostPassword from "./lostPassword/lostPassword";
import ResetPassword from "./resetPassword/resetPassword";
import LegalNotices from "./legalNotices/legalNotices";
import Contact from "./contact/contact";
import ChangePassword from "./changePassword/changePassword";
import Account from "./account/account";
import About from "./about/about";
import PATH from "../constants/cts_routes";
import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";
import { DEFAULT_LANGUAGE } from "../constants/cts_language";
import { useApp } from "../common/contexts/appContext";
import { IUserAuthenticationInfos } from "../interfaces/user";
import SignUpPage from "./signUp/signUp";
import TeachUpPage from "./teachup/teachup";
import CommunityPage from "./community/community";
import SettingsPage from "./settings/settings";
import { USER_ADMIN_ROLE } from "../constants/cts_user";
import { languages } from "../constants/cts_languages";
import LibraryPage from "./library/library";
import DashboardPage from "./dashboard/dashboard";
import AccountSettings from "./account/accountSettings";
import FavoritePage from "./favorites/favorites";

// ⚠️ Lors de la création d'une nouvelle route, il faut ajouter le chemin dans le
// fichier "front/src/constants/cts_routes.tsx"

const AppRoutes = () => {
  const initialAuthenticatedUserInfos = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    image: "",
    role: 2,
    password: "",
    id: -1,
    created_at: "",
    isWaitingAuthenticationResponse: true,
    isAuthenticated: false,
    access_to_community: false,
    access_to_learning: false,
    pitch: "",
    anecdotes: null,
    linkedinLink: "",
    position: "",
  };

  const { onGetUserIfIsAuthenticated, user, isAuthenticated } =
    useAuthentication();

  const [checkUser, _setCheckUser] = useState<IUserAuthenticationInfos>(
    initialAuthenticatedUserInfos
  );

  // set user authenticated infos
  useEffect(() => {
    onGetUserIfIsAuthenticated();
  }, [user]);

  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/:lang" element={<LanguagePage />}>
          {/* NO CONNECTED USER */}
          <Route element={<AuthRoutes user={user} />}>
            <Route
              index
              element={
                // <PrivateRoute>
                <WrapperConnected>
                  <LoginPage />
                </WrapperConnected>
                // </PrivateRoute>
              }
            />

            <Route
              path={PATH.signup}
              element={
                <WrapperConnected>
                  <SignUpPage />
                </WrapperConnected>
              }
            />

            <Route
              path={PATH.login}
              element={
                // <PrivateRoute>
                <WrapperConnected>
                  <LoginPage />
                </WrapperConnected>
                // </PrivateRoute>
              }
            />
            <Route
              path={PATH.lost_pwd}
              element={
                <WrapperConnected>
                  <LostPassword />
                </WrapperConnected>
              }
            />
            <Route
              path={PATH.reset_pwd}
              element={
                <WrapperConnected>
                  <ResetPassword />
                </WrapperConnected>
              }
            />
          </Route>

          <Route
            path={PATH.legals}
            element={
              <WrapperConnected>
                <LegalNotices />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.contact}
            element={
              <WrapperConnected>
                <Contact />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.about}
            element={
              <WrapperConnected>
                <About />
              </WrapperConnected>
            }
          />

          {/* USER CONNECTED */}
          <Route element={<ProtectedRoutes user={user} path={PATH.login} />}>
            {user && user.role === USER_ADMIN_ROLE && (
              <Route
                path={PATH.settings}
                element={
                  <WrapperConnected>
                    <SettingsPage />
                  </WrapperConnected>
                }
              />
            )}

            <Route
              path={PATH.favorites}
              element={
                <WrapperConnected>
                  <FavoritePage />
                </WrapperConnected>
              }
            />

            <Route
              path={PATH.home}
              element={
                <WrapperConnected>
                  <DashboardPage />
                </WrapperConnected>
              }
            />

            {user && user.access_to_community && (
              <Route
                path={PATH.community}
                element={
                  <WrapperConnected>
                    <CommunityPage />
                  </WrapperConnected>
                }
              />
            )}
            {user && user.access_to_learning && (
              <Route
                path={PATH.teachup}
                element={
                  // <PrivateRoute>
                  <WrapperConnected>
                    <TeachUpPage />
                  </WrapperConnected>
                  // </PrivateRoute>
                }
              />
            )}
            <Route
              path={PATH.library}
              element={
                <WrapperConnected>
                  <LibraryPage />
                </WrapperConnected>
              }
            />

            <Route
              path={PATH.change_pwd}
              element={
                // <PrivateRoute>
                <WrapperConnected>
                  <ChangePassword />
                </WrapperConnected>
                // </PrivateRoute>
              }
            />
            <Route
              path={PATH.account}
              element={
                // <PrivateRoute>
                <WrapperConnected>
                  <Account />
                </WrapperConnected>
                // </PrivateRoute>
              }
            />
            <Route
              path={PATH.accountSettings}
              element={
                // <PrivateRoute>
                <WrapperConnected>
                  <AccountSettings />
                </WrapperConnected>
                // </PrivateRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/*" element={<RedirectToIndexLanguagePage />} />
      </Routes>
    </I18nextProvider>
  );
};

const AuthRoutes = ({ user }: { user: any }) => {
  if (user) {
    return <Navigate to={PATH.home} replace />;
  } else {
    return <Outlet />;
  }
};

const ProtectedRoutes = ({ path, user }: { path: string; user: any }) => {
  console.log("Protected routes", window.location.href)
  if (user) {
    return <Outlet />;
  } else {
    console.log("redirectTo", window.location.href)
    localStorage.setItem('redirectTo', window.location.href)
    return <Navigate to={path} replace />;
  }
};

const LanguagePage: React.FC = (): JSX.Element => {
  const { lang } = useParams();
  const { onSendLanguage } = useApp();
  const { pathname } = useLocation();

  useEffect(() => {
    // send selected language to the server
    // the language will be changed on api
    onSendLanguage(lang || DEFAULT_LANGUAGE)
      // change language in front
      .then(() => i18n.changeLanguage(lang));

    if (!languages.find((l) => l.code === lang)) {
      window.location.href = `/${DEFAULT_LANGUAGE}${pathname}`;
    }
  }, [lang]);

  return <Outlet />;
};

const RedirectToIndexLanguagePage = () => {
  console.log('redirection lang')
  let navigatorLangageDetected = navigator.language
    ? navigator.language
    : DEFAULT_LANGUAGE;
  // remove - to language string
  navigatorLangageDetected = navigatorLangageDetected.split("-")[0];
  return <Navigate to={`/${navigatorLangageDetected}/${PATH.home}`} />;
};

export default AppRoutes;
