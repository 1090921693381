import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import {
  onCreateTagApi,
  onDeleteTagApi,
  onGetAllTagsApi,
} from "../../api/tag.api";
import { ITagsContext, ITagsInfos } from "../../interfaces/tags";

const TagsContext = createContext(null);

export function TagsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateTag = useCallback(({ name, lang }: ITagsInfos) => {
    _setIsLoading(true);
    return onCreateTagApi({
      name,
      lang,
    })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllTags = useCallback(() => {
    _setIsLoading(true);
    return onGetAllTagsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onDeleteTag = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteTagApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <TagsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateTag,
        onGetAllTags,
        onDeleteTag,
      }}
    />
  );
}

export const useTags = (): ITagsContext => {
  const context = React.useContext(TagsContext);
  if (!context) throw new Error("useTags must be used in TagProvider");

  return context;
};
