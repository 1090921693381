import React, { Ref, useEffect, useState } from "react";
import styled from "styled-components";
import { BLACK, BLUE, GREY, LIGHT_GREY, WHITE } from "../constants/cts_colors";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IPostsInfos } from "../interfaces/posts";
import { useComments } from "../common/contexts/commentContext";
import ReactQuill from "react-quill";
import { useUser } from "../common/contexts/userContext";
import { IUserInfos } from "../interfaces/user";

let atValuesForMention = [
  { id: 1, value: "Fredrik Sundqvist" },
  { id: 2, value: "Patrik Sjölin" },
];

const modulesForQuill = {
  magicUrl: true,
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    source: async function (searchTerm: any, renderList: any) {
      const matchedPeople = await suggestPeople(searchTerm);
      renderList(matchedPeople);
    },
  },
};

async function suggestPeople(searchTerm: any) {
  return atValuesForMention
    .filter((person) =>
      (person.value || "")
        .toLowerCase()
        .includes((searchTerm || "").toLowerCase())
    )
    .slice(0, 10);
}

const AddComment = ({
  post,
  userId,
  inputRef,
  onSentComment,
}: {
  post: IPostsInfos;
  userId: number;
  inputRef: Ref<HTMLInputElement>;
  onSentComment: Function;
}) => {
  const { onCreateComment } = useComments();
  const { onGetAllUsers } = useUser();
  const [allUsersForMention, _setAllUsersForMention] = useState<
    Array<IUserInfos>
  >([]);

  const initialCommentFormState = {
    user_id: userId,
    post_id: post.id,
    comment_id: "",
    content: "",
  };

  const [commentFormValues, _setCommentFormValues] = useState(
    initialCommentFormState
  );

  useEffect(() => {
    let tmp = Array<any>();

    allUsersForMention.map((user: any) => {
      tmp.push({
        id: user.id,
        value: `${user.first_name} ${user.last_name}`,
      });
    });

    atValuesForMention.pop();
    atValuesForMention = tmp;
  }, [allUsersForMention]);

  useEffect(() => {
    // get all users for mention them
    onGetAllUsers().then((resUsers: any) => {
      _setAllUsersForMention(resUsers);
    });
  }, []);

  // HANDLE COMMENT SUBMIT
  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    console.log("SEND COMMENT ON POST ", post);

    onCreateComment({
      user_id: commentFormValues.user_id,
      post_id: post.id,
      comment_id: commentFormValues.comment_id,
      content: commentFormValues.content,
    }).then(() => {
      _setCommentFormValues(initialCommentFormState);
      onSentComment(post.id);
    });
  };

  // ON CHANGE COMMENT
  const handleCommentChange = (value: string) => {
    _setCommentFormValues({
      ...commentFormValues,
      ['content']: value,
    });
  };

  return (
    <CommentBox>
      <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.34264412.1707782400&semt=ais" />
      <ReactQuill
        modules={modulesForQuill}
        style={{
          width: 'calc(100% - 128px)',
          marginLeft: '64px',
          marginRight: '64px',
          height: '32px',
          marginTop: '19px',
        }}
        placeholder="Ajouter un commentaire"
        className="empty-theme"
        value={commentFormValues.content ? commentFormValues.content : ""}
        onChange={handleCommentChange}
      />
      <button onClick={handleCommentSubmit}>
        <IoIosArrowRoundForward size={18} />
      </button>
    </CommentBox>
  );
};

export default AddComment;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const CommentBox = styled.form`
  width: 100%;
  position: relative;
  border: 1px solid ${BLUE};
  border-radius: 96px;
  height: 64px;

  & > img {
    position: absolute;
    width: 32px;
    left: 20px;
    top: 16px;
    border-radius: 50%;
  }

  & > input {
    border: 1px solid ${BLUE};
    width: 100%;
    border-radius: 96px;
    padding: 0px 64px;
    font-size: 16px;
    font-weight: bold;
  }

  & > button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 16px;
    background-color: ${BLUE};
    color: ${WHITE};
    border-radius: 50%;
    width: 32px;
    height: 32px;

    transition: all 25ms ease-in-out;

    :hover {
      border: 1px solid ${BLUE};
      background-color: ${WHITE};
      color: ${BLUE};
    }
  }

  .empty-theme {
    .ql-toolbar {
      display: none !important;
    }
    .ql-container {
      border: none;

      .ql-editor > p > .mention {
        font-weight: 600;
        color: ${BLUE};
      }
    }
    .ql-editor {
      padding: 0;
    }
    .ql-editor.ql-blank::before {
      color: ${BLACK};
      font-style: normal;
      left: 0;
    }
    * {
      font-size: 16px;
      font-weight: bold;
    }
    .ql-container > .ql-mention-list-container {
      padding: 16px 0px;
      background-color: ${WHITE};
      border: 1px solid ${LIGHT_GREY};
      font-family: "League Spartan";
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      font-size: 16px;
      border-radius: 12px;
      z-index: 10;

      /* style for user popup */
      ul > li {
        transition: all 250ms;
        background-color: ${WHITE};
        cursor: pointer;
        padding: 6px 8px;
        font-weight: bold;
        color: ${BLACK};
        border-top: 1px solid ${WHITE};
        border-bottom: 1px solid ${WHITE};

        :hover {
          background-color: ${LIGHT_GREY};
          border-top: 1px solid ${GREY};
          border-bottom: 1px solid ${GREY};
        }
      }
    }
  }
  }
`;
