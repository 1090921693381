import React, { ChangeEventHandler, ReactElement } from 'react'
import Textarea from './Textarea';
import styled from 'styled-components';
import { BLUE } from '../../constants/cts_colors';

const TextareaWithLabel = ({
  label,
  placeholder,
  icon,
  name,
  value,
  disabled,
  error,
  onChange,
  onClickIcon,
}: {
  label: string,
  placeholder: string,
  icon?: ReactElement,
  name?: string,
  value?: string | number,
  disabled?: boolean,
  error?: string,
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
  onClickIcon?: Function,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Textarea icon={icon} placeholder={placeholder} onChange={onChange} name={name} value={value} onClickIcon={onClickIcon} disabled={disabled} error={error} />
    </Container>
  )
}

export default TextareaWithLabel;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: ${BLUE};
`
