// the values are used for translation
// no match
export const PASSWORD_NO_MATCH = "passwordNoMatch";
// empty
export const EMPTY_EMAIL = "emptyEmail";
export const EMPTY_PASSWORD = "emptyPassword";
export const EMPTY_FIRSTNAME = "emptyFirstName";
export const EMPTY_LASTNAME = "emptyLastName";
export const EMPTY_FIRSTNAME_AND_LASTNAME = "emptyFirstNameAndLastName";
export const EMPTY_TOKEN = "emptyToken";
export const EMPTY_CONTACT_MESSAGE = "emptyContactMessage";
export const EMPTY_ITEM_TITLE = "emptyItemTitle";
export const EMPTY_ITEM_DESCRIPTION = "emptyItemDescription";
export const EMPTY_ITEM_IMAGE = "emptyItemImage";
export const EMPTY_ITEM_PRICE = "emptyItemPrice";
export const EMPTY_ITEM_STATUS = "emptyItemStatus";

export const EMPTY_FORMATION_TITLE = "emptyFormationTitle";
export const EMPTY_FORMATION_IMAGE = "emptyFormationImage";
export const EMPTY_FORMATION_LANGUAGE = "emptyFormationLanguage";
export const EMPTY_FORMATION_LINK = "emptyFormationLink";

export const EMPTY_COMPANY_NAME = "emptyCompanyName";

export const EMPTY_MEMBER_FIRST_NAME = "emptyMemberFirstName";
export const EMPTY_MEMBER_LAST_NAME = "emptyMemberLastName";
export const EMPTY_MEMBER_EMAIL = "emptyMemberEmail";
export const EMPTY_MEMBER_COMPANY = "emptyMemberCompany";
export const EMPTY_MEMBER_POSITION = "emptyMemberPosition";

export const EMPTY_COACH_FIRST_NAME = "emptyCoachFirstName";
export const EMPTY_COACH_LAST_NAME = "emptyCoachLastName";
export const EMPTY_COACH_EMAIL = "emptyCoachEmail";
export const EMPTY_COACH_PHONE_NUMBER = "emptyCoachPhoneNumber";

export const EMPTY_GROUP_NAME = "emptyGroupName";
export const EMPTY_GROUP_MEMBERS = "emptyGroupMembers";

export const EMPTY_POST_CONTENT = "emptyPostContent";

export const EMPTY_COMMENT_CONTENT = "emptyCommentContent";

// not valid
export const NOT_VALID_EMAIL = "notValidEmail";
export const NOT_VALID_PASSWORD = "notValidPassword";
export const NOT_VALID_PHONE_NUMBER = "notValidPhoneNumber";
