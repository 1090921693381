import { instanceAxios } from "../utils/axios-api";

export const onCreateTagApi = ({ name, lang }: { name: string, lang: string }) => {
  const formData = new FormData();
  formData.append("name", name.toString() || "");
  formData.append("lang", lang || "");

  return instanceAxios
    .post("/tags/create-tag", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllTagsApi = () => {
  return instanceAxios
    .get(`/tags/get-all-tags`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteTagApi = (id: number) => {
  return instanceAxios
    .put(`/tags/delete-tag/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};
