import styled from "styled-components";
import { BLUE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { USER_DEFAULT_PROFILE_IMAGE } from "../constants/cts_user";
import React, { useState } from "react";
import { IoMdRemoveCircle, IoMdRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneAudio } from "react-icons/ai";
import { RiVideoLine } from "react-icons/ri";

const FileCard = ({
  file,
  onRemoveFile,
}: {
  file: any;
  onRemoveFile: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { t } = useTranslation();

  const [isHovered, _setIsHovered] = useState<boolean>(false);

  return (
    <div
      // style={{ maxWidth: "75px", maxHeight: "125px" }}
      className="flex flex-col items-evenly justify-center gap-1 rounded border-2	"
    >
      <div className="w-full flex justify-end p-1">
        <div
          className="cursor-pointer"
          onClick={onRemoveFile}
          onMouseEnter={() => {
            _setIsHovered(true);
          }}
          onMouseLeave={() => {
            _setIsHovered(false);
          }}
        >
          {!isHovered && <IoMdRemoveCircleOutline />}
          {isHovered && <IoMdRemoveCircle />}
        </div>
      </div>
      <div
        className="flex justify-center items-center"
        style={{ width: "102px" }}
      >
        {file.type.split("/")[0] === "image" && (
          <img className="p-1" src={file.url} alt={file.url} />
        )}
        {file.type.split("/")[0] === "audio" && (
          <div className="flex m-auto">
            <AiTwotoneAudio size={24} />
          </div>
        )}
        {file.type.split("/")[0] === "video" && (
          <div className="flex m-auto">
            <RiVideoLine size={25} />
          </div>
        )}
      </div>

      <p className="text-sm text-center p-1">{file.name || file.title}</p>
    </div>
  );
};

export default FileCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ProfileImageStyles = styled.div`
  & > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid ${BLUE};
  }
`;
