import React from "react";
import styled from "styled-components";
import { BLUE, WHITE } from "../constants/cts_colors";
const Button = ({
  text,
  disabled,
  onClick,
}: {
  text: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <ButtonStyles disabled={disabled} onClick={onClick}>
      {text}
    </ButtonStyles>
  );
};

export default Button;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ButtonStyles = styled.button`
  background-color: ${BLUE};
  color: ${WHITE};
  border-radius: 5px;
  padding: 13.5px;
  border: 1px solid ${WHITE};
  width: 100%;

  transition: 250ms;

  :disabled {
    opacity: 0.5;
    :hover {
      background-color: ${BLUE};
      color: ${WHITE};
      border: 1px solid ${WHITE};
    }
  }

  :hover {
    background-color: ${WHITE};
    border: 1px solid ${BLUE};
    color: ${BLUE};
  }
`;
