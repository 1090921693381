import React, { FormEventHandler, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  BLUE,
  COLOR_BLACK,
  COLOR_GREY,
  COLOR_GREY_LIGHT,
  COLOR_WHITE,
  WHITE,
} from "../constants/cts_colors";
import {
  FORM_STYLE_FOOTER,
  FORM_STYLE_INFO_MESSAGES,
  FORM_STYLE_INPUT_WITH_ICON,
  FORM_STYLE_SUBMIT_BTN,
  FORM_STYLE_TEXTAREA_WITH_ICON,
} from "../constants/cts_form";

const Form = ({
  id,
  children,
  onHandleSubmit,
  submitBtn,
}: {
  id?: string;
  children: ReactNode;
  onHandleSubmit: FormEventHandler<HTMLFormElement>;
  submitBtn: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <FormContainer id={id} onSubmit={onHandleSubmit}>
      <FormInputs>{children}</FormInputs>

      {/* form footer */}
      <div className={FORM_STYLE_FOOTER}>
        {/* form info messages */}
        <div className={FORM_STYLE_INFO_MESSAGES}></div>

        {/* submit btn */}
        <button className={FORM_STYLE_SUBMIT_BTN} type="submit">
          {submitBtn}
        </button>
      </div>
    </FormContainer>
  );
};

export default Form;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.form`
  width: 100%;
  padding: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: var(--Blanc, #fff);

  /* Card shadow big */
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);

  /* form footer */
  button.${FORM_STYLE_SUBMIT_BTN} {
    display: block;
    width: 65px;
    height: 65px;
    margin: 0px auto;
    background: ${BLUE};

    border: 1px solid ${BLUE};
    border-radius: 50%;

    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);

    transition: 250ms;

    transform: translate(0, 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :hover {
      background-color: ${WHITE};
      color: ${BLUE};

      & > svg {
        stroke: ${BLUE};
        fill: ${BLUE};

        path {
          stroke: ${BLUE};
        }
      }
    }

    svg {
      width: 30px;
      height: auto;
      stroke: ${COLOR_WHITE};
      fill: ${COLOR_WHITE};

      path {
        stroke: ${COLOR_WHITE};
      }
    }
  }
`;

const FormInputs = styled.div`
  label {
    display: block;
    margin-top: 10px;
    color: ${COLOR_GREY};
    font-size: 12px;
  }

  /* input box */
  div.${FORM_STYLE_INPUT_WITH_ICON} {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${COLOR_GREY_LIGHT};

    input {
      width: 100%;
      color: ${COLOR_BLACK};
      border: none;
      padding: 10px 0px;

      // Removing input background colour for Chrome autocomplete
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        box-shadow: 0 0 0 30px white inset !important;
      }

      &:focus {
        outline: none;
      }
    }

    svg {
      width: 15px;
      fill: ${COLOR_GREY};
    }
  }

  /* textarea box */
  div.${FORM_STYLE_TEXTAREA_WITH_ICON} {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid ${COLOR_GREY_LIGHT};

    textarea {
      width: 100%;
      height: 100px;
      color: ${COLOR_BLACK};
      border: none;
      padding: 10px 0px;
      resize: none;
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    svg {
      width: 15px;
      fill: ${COLOR_GREY};
    }
  }
`;
