import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LIGHT_GREY, GREY, WHITE, BLUE } from "../constants/cts_colors";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { ImCross } from "react-icons/im";
import { ICompaniesInfos } from "../interfaces/companies";
import { useCompanies } from "../common/contexts/companyContext";
import CompanyLine from "./CompanyLine";
import LinkPill from "./LinkPill";
import { useFormations } from "../common/contexts/formationContext";
import { IFormationsInfos } from "../interfaces/formations";
import { IoAddCircle } from "react-icons/io5";

const SettingsCompanies = ({}: {}) => {
  const { t } = useTranslation();

  const initialCompaniesFormState = {
    name: "",
    formations: "",
  };

  const [isAccessPopupOpen, _setIsAccessPopupOpen] = useState<boolean>(false);
  const refAccessPopup = useRef<HTMLDivElement>(null);

  const { onGetAllFormations, onGetFormation } = useFormations();
  const [allFormations, _setAllFormations] = useState<Array<IFormationsInfos>>(
    []
  );
  const [selectedFormations, _setSelectedFormations] = useState<
    Array<IFormationsInfos>
  >([]);
  const [formationsIdsTable, _setFormationsIdsTable] = useState<Array<number>>(
    []
  );
  const [actualSelectedFormation, _setActualSelectedFormation] =
    useState<number>(0);

  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  // ***** COMPANIES *****
  const [companiesFormValues, _setCompanyFormValues] = useState(
    initialCompaniesFormState
  );

  const {
    onCreateCompany,
    onGetAllCompanies,
    onGetCompany,
    onDeleteCompany,
    onUpdateCompany,
    onGetSearchCompanies,
  } = useCompanies();

  const [companies, _setCompanies] = useState<Array<ICompaniesInfos>>([]);

  const refCreationCompagniesPopup = useRef<HTMLDivElement>(null);

  const [isOperationPopupOpen, _setOperationPopupOpen] =
    useState<boolean>(true);

  const [isCreationCompagniesPopupOpen, _setIsCreationCompagniesPopupOpen] =
    useState<boolean>(false);
  const [isUpdateCompagniesPopupOpen, _setIsUpdateCompagniesPopupOpen] =
    useState<boolean>(false);

  const [idUpdateCompany, _setIdUpdateCompany] = useState<number>();

  // ***** COMPANY FUNCTIONS *****
  // COMPANY CHANGE
  const handleCompanyChange = (e: any) => {
    // console.log(e);
    const { name, value } = e.target;
    _setCompanyFormValues({
      ...companiesFormValues,
      [name]: value,
    });
  };

  // COMPANIES SUBMIT
  const handleCompanySubmit = (e: React.FormEvent) => {
    _setCompanyFormValues(initialCompaniesFormState);
    _setIsCreationCompagniesPopupOpen(false);
    _setIsUpdateCompagniesPopupOpen(false);
    e.preventDefault();

    console.log("oui", idUpdateCompany);

    if (isCreationCompagniesPopupOpen) {
      onCreateCompany({
        name: companiesFormValues.name,
      })
        .then(() => {
          _setCompanyFormValues(initialCompaniesFormState);
          onGetAllCompanies()
            .then((response: any) => {
              _setCompanies(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    } else {
      onUpdateCompany({
        id: idUpdateCompany,
        name: companiesFormValues.name,
      })
        .then(() => {
          _setCompanyFormValues(initialCompaniesFormState);
          onGetAllCompanies()
            .then((response: any) => {
              _setCompanies(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    }
  };

  // ACCESS SUBMIT
  const handleAccessSubmit = (e: React.FormEvent) => {
    _setIsAccessPopupOpen(false);
    _setSelectedFormations([]);
    _setFormationsIdsTable([]);
    _setActualSelectedFormation(0);
    e.preventDefault();

    if (idUpdateCompany) {
      onGetCompany(idUpdateCompany).then((response) => {
        onUpdateCompany({
          id: idUpdateCompany,
          name: response.name,
          formations: formationsIdsTable,
        })
          .then(() => {
            _setIsAccessPopupOpen(false);
            _setSelectedFormations([]);
            _setFormationsIdsTable([]);
            _setActualSelectedFormation(0);
            alert(t("companies.accessAdded", { ns: "settingsPage" }));
            onGetAllCompanies()
              .then((response: any) => {
                _setCompanies(response);
              })
              .catch((error: any) => alert(error));
          })
          .catch((error) => alert(error));
      });
    }
  };

  // ADD MEMBER TO SELECTED MEMBERS FOR CREATION
  const addLinkToSelectedFormations = (e: any) => {
    // CHECK if actualSelected is already inside the selectedMembers
    // console.log("ADDED ID : ", actualSelectedMemberId);

    onGetFormation(actualSelectedFormation)
      .then((response) => {
        _setSelectedFormations([...selectedFormations, response]);
        _setFormationsIdsTable([
          ...formationsIdsTable,
          actualSelectedFormation,
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ACCESS CHANGE
  const handleAccessFormationDropdownChange = (e: any) => {
    const { name, value } = e.target;
    _setActualSelectedFormation(value);
  };

  // REMOVE MEMBER TO SELECTED MEMBERS
  const removeFormation = (key: number) => {
    // console.log("KEY : ", key);
    // console.log("REMOVED ID : ", selectedMembers[key]);

    selectedFormations.splice(key, 1);
    _setSelectedFormations([...selectedFormations]);

    formationsIdsTable.splice(key, 1);
    _setFormationsIdsTable([...formationsIdsTable]);

    // console.log("FINAL MEMBER ID TABLE : ", membersIdsTable);
    // console.log("FINAL SELECTED MEMBERS : ", selectedMembers);
  };

  // COMPANY GET VALUES
  const getCompanyValues = (id: number) => {
    onGetCompany(id)
      .then(async (response) => {
        _setCompanyFormValues(response);
        if (
          response &&
          response.formations &&
          response.formations.length !== 0
        ) {
          for (let i = 0; i < response.formations.length; i++) {
            await onGetFormation(response.formations[i])
              .then((response) => {
                selectedFormations.push(response);
                formationsIdsTable.push(response.id);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  // HANDLE DELETE MEMBER
  const handleDeleteCompany = (id: number, name: string) => {
    if (
      confirm(
        t("companies.confirmDelete", { ns: "settingsPage" }) + name + " ?"
      )
    ) {
      _setOperationPopupOpen(false);
      onDeleteCompany(id)
        .then((response) => {
          onGetAllCompanies()
            .then((response: any) => {
              _setCompanies(response);
              _setOperationPopupOpen(true);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //onChange FOR SEARCH INPUT
  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("SEARCH INPUT CHANGED ", value);

    if (value === "") {
      _setNoResultsSentence("");
      onGetAllCompanies().then((response) => {
        _setCompanies(response);
      });
    } else {
      onGetSearchCompanies(value)
        .then((response) => {
          // console.log(response);
          if (response.length === 0) {
            _setNoResultsSentence(t("noResults", { ns: "search" }).toString());
            _setCompanies(response);
          } else {
            _setCompanies(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    onGetAllCompanies()
      .then((response: any) => {
        _setCompanies(response);
      })
      .catch((error: any) => alert(error));

    onGetAllFormations()
      .then((response: any) => {
        _setAllFormations(response);
      })
      .catch((error: any) => alert(error));
  }, []);

  // USE EFFECT FOR CLICK OUTSIDE OF COMPAGNIES POPUP
  useEffect(() => {
    function handleClickCompagniesOutside(event: any) {
      if (
        refCreationCompagniesPopup.current &&
        !refCreationCompagniesPopup.current.contains(event.target)
      ) {
        _setIsCreationCompagniesPopupOpen(false);
        _setIsUpdateCompagniesPopupOpen(false);
        _setCompanyFormValues(initialCompaniesFormState);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickCompagniesOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickCompagniesOutside);
    };
  }, [refCreationCompagniesPopup]);

  // USE EFFECT FOR CLICK OUTSIDE OF GROUP POPUP
  useEffect(() => {
    function handleClickAccessOutside(event: any) {
      if (
        refAccessPopup.current &&
        !refAccessPopup.current.contains(event.target)
      ) {
        // _setAccessGroupFormValues(initialAccessGroupFormState);
        _setIsAccessPopupOpen(false);
        //_setIdUpdateCompany(undefined);
        _setFormationsIdsTable([]);
        _setSelectedFormations([]);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickAccessOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickAccessOutside);
    };
  }, [refAccessPopup]);

  return (
    <SettingsMembersStyle>
      <SearchAndAddContainer
        onChange={handleChangeSearchInput}
        onClick={() => {
          _setIsCreationCompagniesPopupOpen(true);
        }}
        showBtOptions={false}
      />
      {/* HEADER OF COMPAGNIES */}
      <div
        className="mx-28 my-4 gap-4"
        style={{ borderColor: LIGHT_GREY, borderBottomWidth: 1 }}
      >
        <div
          className="flex flex-row items-center basis-12 pt-3 pb-6"
          style={{
            color: GREY,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*<CheckboxCustom
            className="basis-1/12"
            type="checkbox"
        ></CheckboxCustom>*/}
          <p className="basis-3/12">
            {t("companies.headerCompagnies", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("companies.headerMembers", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("companies.headerFormations", { ns: "settingsPage" })}
          </p>
          <p className="basis-5/12 flex justify-center">
            {t("companies.activity", { ns: "settingsPage" })}
          </p>
        </div>
      </div>

      <div className="overflow-scroll" style={{ height: "68vh" }}>
        {/* LIST OF COMPAGNIES */}
        {companies &&
          companies.length > 0 &&
          companies.map((company, key) => {
            return (
              <CompanyLine
                company={company}
                key={key}
                popupOperations={isOperationPopupOpen}
                numberOfFormations={(company.formations || []).length}
                onEdit={() => {
                  getCompanyValues(company.id);
                  _setIdUpdateCompany(company.id);
                  _setIsUpdateCompagniesPopupOpen(true);
                }}
                onDelete={() => {
                  handleDeleteCompany(company.id, company.name);
                }}
                onAccess={() => {
                  getCompanyValues(company.id);
                  _setIdUpdateCompany(company.id);
                  _setIsAccessPopupOpen(true);
                }}
              />
            );
          })}
        {companies && companies.length < 1 && (
          <NoContentSentence>
            {t("companies.noCompanies", { ns: "settingsPage" })}
          </NoContentSentence>
        )}
      </div>

      {/* FORM CREATION FORM COMPAGNIES */}
      <CreationPopupContent
        style={{
          display:
            isCreationCompagniesPopupOpen || isUpdateCompagniesPopupOpen
              ? "block"
              : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-40 py-12 px-16"
          ref={refCreationCompagniesPopup}
        >
          <form onSubmit={handleCompanySubmit}>
            <h3>
              {isUpdateCompagniesPopupOpen &&
                t("form.nameCompagniesUpdate", { ns: "settingsPage" })}
              {isCreationCompagniesPopupOpen &&
                t("form.nameCompagniesCreation", { ns: "settingsPage" })}
            </h3>

            {/* NAME */}
            <InputWithSubtitle>
              <label htmlFor="name" className="mt-4 mb-1">
                {t("form.placeholders.name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="name"
                name="name"
                placeholder={
                  "" + t("form.placeholders.name", { ns: "settingsPage" })
                }
                required
                onChange={handleCompanyChange}
                value={companiesFormValues.name}
              />
            </InputWithSubtitle>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                text={
                  isUpdateCompagniesPopupOpen
                    ? t("form.buttonUpdate", { ns: "settingsPage" })
                    : t("form.button", { ns: "settingsPage" })
                }
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsCreationCompagniesPopupOpen(false);
                _setIsUpdateCompagniesPopupOpen(false);
                _setCompanyFormValues(initialCompaniesFormState);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>

      {/* ACCESS FORM */}
      <CreationPopupContent
        style={{
          display: isAccessPopupOpen ? "block" : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-36 py-12 px-16"
          ref={refAccessPopup}
        >
          <form onSubmit={handleAccessSubmit}>
            <h3>{t("form.addAccess", { ns: "settingsPage" })}</h3>

            {/* LINKS FORMATIONS */}
            <InputWithSubtitle>
              <label htmlFor="formations" className="mt-4 mb-1">
                {t("form.placeholders.formations", {
                  ns: "settingsPage",
                })}
              </label>
              <div className="flex items-center">
                <select
                  id="formations"
                  name="formations"
                  onChange={handleAccessFormationDropdownChange}
                  defaultValue={""}
                >
                  <option value={""}></option>
                  {allFormations &&
                    allFormations
                      .filter(
                        (f) => !selectedFormations.find((fs) => fs.id === f.id)
                      )
                      .map((oneFormation, key) => {
                        return (
                          <option key={key} value={oneFormation.id}>
                            {oneFormation.title}
                          </option>
                        );
                      })}
                </select>
                <div
                  onClick={
                    actualSelectedFormation
                      ? addLinkToSelectedFormations
                      : undefined
                  }
                  className="m-3"
                  style={{
                    cursor: actualSelectedFormation ? "pointer" : "",
                  }}
                >
                  <IoAddCircle
                    color={actualSelectedFormation ? BLUE : GREY}
                    size={24}
                  />
                </div>
              </div>
            </InputWithSubtitle>

            {/* SELECTED FORMATIONS */}
            <div
              className="my-4 flex gap-2 flex-wrap overflow-scroll overflow-visible	"
              style={{ maxWidth: "450px", maxHeight: "125px" }}
            >
              {selectedFormations && selectedFormations.length < 1 && (
                <p className="text-center">Aucun liens.</p>
              )}
              {selectedFormations &&
                selectedFormations.map((formation, key) => {
                  return (
                    <LinkPill
                      key={key}
                      formation={formation}
                      onClick={() => {
                        removeFormation(key);
                      }}
                    />
                  );
                })}
            </div>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                disabled={selectedFormations.length < 1}
                text={t("form.addAccess", { ns: "settingsPage" })}
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsAccessPopupOpen(false);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>
    </SettingsMembersStyle>
  );
};

export default SettingsCompanies;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsMembersStyle = styled.div`
  /* width: 100%; */
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;

const CreationPopupContent = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 186, 186, 0.666);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
  }

  & > div > form > h3 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div > select {
    padding: 10px 12px 10px 16px;
    border-radius: 4px;
    background: ${WHITE};
    border-radius: 5px;
    border: 1px solid ${LIGHT_GREY};
    width: 400px;
  }
`;

const FormInput = styled.input`
  display: flex;
  padding: 10px 12px 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
  width: 400px;
`;

const CrossBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${LIGHT_GREY};

  :hover {
    color: ${GREY};
  }
`;
