// Cet object contient tout les chemins nécéssaire à l'application
// Lors de la création d'une nouvelle page pour l'application,
// il faut ajouter ici le chemin vers celle-ci associé à un nom (clé)

const PATH = {
  home: "dashboard",
  signup: "signup",
  login: "login",
  legals: "legals",
  contact: "contact",
  change_pwd: "change-password",
  lost_pwd: "lost-password",
  reset_pwd: "reset-password",
  about: "about",
  account: "account",
  accountSettings: "account-settings",
  items: "items",
  community: "community",
  library: "library",
  teachup: "teachup",
  settings: "settings",
  favorites: "favorites",
};

export default PATH;
