import React from "react";
import styled from "styled-components";
import { BLUE, RED, WHITE } from "../constants/cts_colors";
import { IPostsInfos } from "../interfaces/posts";
import ArrowWithTextButton from "./ArrowWithTextButton";
import ProfileImage from "./ProfileImage";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next, { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import { getTimeAgoTextByLanguage } from "../utils/translations";
const DashboardLastPost = ({ post }: { post: IPostsInfos }) => {
  const { t } = useTranslation();

  return (
    <DashboardLastPostStyles className="flex flex-col gap-5">
      <div className="flex flex-row justify-between">
        <p className="font-bold text-xl lg:text-2xl">
          {t("post.title", { ns: "dashboardPage" })}
        </p>
        <Link to={getPageUrl(PATH.community)}>
          <ArrowWithTextButton
            text={t("post.explore", { ns: "dashboardPage" })}
            arrowPosition="right"
            color=""
            onClick={() => {}}
          />
        </Link>
      </div>
      <PostLittleCard className="w-full">
        {/* HEADER INFOS */}
        <PostHeader>
          <ProfileImage
            src={
              post.userImage
                ? post.userImage
                : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.34264412.1707782400&semt=ais"
            }
          />
          <div className="content w-full flex justify-between">
            <div className="content">
              <div className="flex md:gap-2 md:flex-row flex-col">
                <h2 className="font-bold text-2xl" style={{ color: BLUE }}>
                  {post.userFirstName} {post.userLastName}
                </h2>
                <p className="md:pt-1" style={{ fontSize: 14 }}>
                  {
                    getTimeAgoTextByLanguage({
                      date: new Date(post.created_at),
                      lang: i18next.language,
                      t,
                      additionalText: t("post.itWas", { ns: "circlePage" }),
                    })
                  }
                </p>
              </div>
            </div>
          </div>
        </PostHeader>

        {/* TITLE */}
        <PostTitle>{post.title}</PostTitle>

        {/* CONTENT */}
        <PostContent>
          <ReactQuill value={post.content} readOnly={true} theme={"bubble"} />
        </PostContent>
      </PostLittleCard>
    </DashboardLastPostStyles>
  );
};

export default DashboardLastPost;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const DashboardLastPostStyles = styled.div`
  /* padding: 32px; */
`;

const PostLittleCard = styled.div`
  width: 100%;
  /* margin: 24px auto; */
  padding: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
  /* border: 1px solid ${BLUE}; */
  border-radius: 24px;
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  & > .content {
    margin-left: 16px;
  }
`;

const PostTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;

const PostContent = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: left;
  div {
    div {
      div {
        padding: 0px;
      }
    }
  }
`;
