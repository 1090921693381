import styled from "styled-components";
import { BLUE, LIGHT_GREY, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MdCreate, MdDelete } from "react-icons/md";
import { IPostsInfos } from "../interfaces/posts";
import { useAuthentication } from "../common/contexts/authenticationContext";
import DotsButton from "./DotsButton";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";

const ResourceCard = ({
  resource,
  onClick,
  titleSize,
  langFilter = "",
  handleDeletePost,
  handleUpdatePost,
}: {
  resource: IPostsInfos;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  titleSize: string;
  langFilter?: string;
  handleDeletePost: Function;
  handleUpdatePost: Function;
}) => {
  const { t, i18n } = useTranslation();
  const { isUserAdmin } = useAuthentication();
  const [isHovered, _setIsHovered] = useState<boolean>(false);
  const [podcastBackground, _setPodcastBackground] = useState<string>("");
  const [isMenuOpened, _setIsMenuOpened] = useState<boolean>(false);
  const { onAnalyticsResourceClick } = useAnalyticLogs();

  useEffect(() => {
    if (resource) {
      const images = (resource.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "image"
      );
      if (images.length) {
        _setPodcastBackground(images[0].url);
      }
    }
  }, [resource]);

  const analyticsResourceClick = () => {
    onAnalyticsResourceClick(resource.id)
      .then(() => {})
      .catch((error) => console.error(error));
  };

  return (
    <ResourceCardStyles>
      <div
        className="relative cursor-pointer image dt-size-is-2-1"
        style={{ backgroundImage: `url(${podcastBackground})` }}
        onMouseEnter={() => {
          _setIsHovered(true);
        }}
        onMouseLeave={() => {
          _setIsHovered(false);
        }}
      >
        <PlayDiv
          onClick={(event) => {
            analyticsResourceClick();
            if (onClick) {
              onClick(event);
            }
          }}
          className="absolute top-0 flex justify-center items-center"
          style={{
            visibility: isHovered ? "visible" : "hidden",
          }}
        >
          Lire
        </PlayDiv>
        {isUserAdmin && (
          <DotsArea>
            <DotsButton
              type="post"
              onClick={() => {
                _setIsMenuOpened(!isMenuOpened);
              }}
            />
            {/* 3 DOTS MENU */}
            <DotsMenu
              style={{ display: isMenuOpened ? "block" : "none" }}
              className="absolute rounded-xl"
            >
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleUpdatePost(resource.id);
                }}
              >
                <MdCreate size={24} />
                <p className="font-bold">
                  {t("post.update", { ns: "circlePage" })}
                </p>
              </button>
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleDeletePost(resource.id);
                }}
              >
                <MdDelete size={24} />
                <p className="font-bold">
                  {t("post.delete", { ns: "circlePage" })}
                </p>
              </button>
            </DotsMenu>
          </DotsArea>
        )}
      </div>
      <h3
        className="font-bold mt-3 mb-1 pr-8"
        style={{ color: BLUE, fontSize: "16px" }}
      >
        {resource.title}
      </h3>
      <div className="mt-1 flex gap-2 flex-wrap">
        {(resource.tagsHistory || [])
          .filter((t) => (!t || t.lang === langFilter))
          .map((tag, key) => {
            if (tag !== null)
              return <SmallTagPill key={key}>#{tag.name}</SmallTagPill>;
          })}
      </div>
    </ResourceCardStyles>
  );
};

export default ResourceCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ResourceCardStyles = styled.div`
  > div.image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    position: relative;
  }
`;

const PlayDiv = styled.div`
  background-color: rgba(61, 61, 234, 0.5);
  transition: all 250ms linear;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 16px;
  color: ${WHITE};
  font-weight: bold;
  font-size: 24px;

  :hover {
    opacity: 1;
  }
`;

const DotsMenu = styled.div`
  background-color: ${WHITE};
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: 0;

  button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;

const DotsArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const SmallTagPill = styled.div`
  font-family: "League Spartan";
  font-weight: 700;
  font-size: 12px;
  padding: 4px 8px;
  width: min;
  border-radius: 50px;
  border: 1px solid ${BLUE};
`;
