import React, { createContext, useCallback, useContext, useState } from "react";
import {
  onCreateAccountApi,
  onLostPasswordApi,
  onResetPasswordApi,
  onSendContactMessageApi,
  onChangePasswordApi,
  onGetCurrentUserApi,
  onEditAccountApi,
  onDeleteAccountApi,
  onGetAvailableFormationsApi,
  onChangeProfileImageApi,
  onEditMyFileApi,
  onGetAllUsersApi,
} from "../../api/user.api";
import {
  EMPTY_CONTACT_MESSAGE,
  EMPTY_EMAIL,
  EMPTY_FIRSTNAME,
  EMPTY_LASTNAME,
  EMPTY_PASSWORD,
  EMPTY_TOKEN,
  NOT_VALID_EMAIL,
  NOT_VALID_PHONE_NUMBER,
  PASSWORD_NO_MATCH,
} from "../../constants/cts_formErrors";
import { IUserContext } from "../../interfaces/user";
import { USER_MSG } from "../../constants/cts_contextErrors";
import { useTranslation } from "react-i18next";
import {
  checkEmptyInput,
  checkStringEquality,
  checkValidEmail,
  checkValidPhoneNumber,
} from "../../utils/checkInputs";

const UserContext = createContext<IUserContext | null>(null);

// THE PROVIDER
export const UserProvider = (props: any) => {
  const { t } = useTranslation();
  const [isLoading, _setIsLoading] = useState(false);

  // create an account
  const onCreateAccount = useCallback(
    ({
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      repeatPassword,
    }: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string;
      password: string;
      repeatPassword: string;
    }) => {
      if (!checkEmptyInput(firstName)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FIRSTNAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(lastName)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_LASTNAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(password)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_PASSWORD}`, { ns: "errors" }));
        });
      }
      if (phoneNumber) {
        if (!checkValidPhoneNumber(phoneNumber)) {
          return new Promise((resolve, reject) => {
            reject(t(`form.${NOT_VALID_PHONE_NUMBER}`, { ns: "errors" }));
          });
        }
      }

      if (!checkStringEquality(password, repeatPassword)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${PASSWORD_NO_MATCH}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onCreateAccountApi({
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
      })
        .then((response) => {
          _setIsLoading(false);
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // get current user
  const onGetCurrentUser = useCallback(() => {
    _setIsLoading(true);
    return onGetCurrentUserApi()
      .then((response) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  // edit account
  const onEditAccount = useCallback(
    ({
      userId,
      lastName,
      firstName,
      email,
      phoneNumber,
      profileImage,
    }: {
      userId: number;
      firstName: {
        inputName: string;
        value: string;
      };
      lastName: {
        inputName: string;
        value: string;
      };
      email: {
        inputName: string;
        value: string;
      };
      phoneNumber?: {
        inputName: string;
        value: string;
      };
      profileImage: File;
    }) => {
      if (!checkEmptyInput(firstName.value)) {
        return new Promise((resolve, reject) => {
          reject({
            inputName: firstName.inputName,
            message: t(`form.${EMPTY_FIRSTNAME}`, { ns: "errors" }),
          });
        });
      }
      if (!checkEmptyInput(lastName.value)) {
        return new Promise((resolve, reject) => {
          reject({
            inputName: lastName.inputName,
            message: t(`form.${EMPTY_LASTNAME}`, { ns: "errors" }),
          });
        });
      }
      if (!checkValidEmail(email.value)) {
        return new Promise((resolve, reject) => {
          reject({
            inputName: email.inputName,
            message: t(`form.${NOT_VALID_EMAIL}`, { ns: "errors" }),
          });
        });
      }
      if (phoneNumber) {
        if (!checkValidPhoneNumber(phoneNumber.value)) {
          return new Promise((resolve, reject) => {
            reject({
              inputName: phoneNumber.inputName,
              message: t(`form.${NOT_VALID_PHONE_NUMBER}`, { ns: "errors" }),
            });
          });
        }
      }

      _setIsLoading(true);
      return onEditAccountApi({
        userId,
        lastName: lastName.value,
        firstName: firstName.value,
        email: email.value,
        phoneNumber: phoneNumber ? phoneNumber.value : "",
        profileImage,
      })
        .then((response) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // change profile image
  const onChangeProfileImage = useCallback(
    async ({
      userId,
      newProfileImage,
    }: {
      userId: number;
      newProfileImage: File;
    }) => {
      _setIsLoading(true);
      return onChangeProfileImageApi({ userId, newProfileImage })
        .then((response) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // edit my file
  const onEditMyFile = useCallback(
    async ({
      userId,
      pitch,
      anecdotes,
      linkedinLink,
      position,
    }: {
      userId: number;
      pitch: string | null;
      anecdotes: object | null;
      linkedinLink: string | null;
      position: string | null;
    }) => {
      _setIsLoading(true);
      return onEditMyFileApi({
        userId,
        pitch,
        anecdotes,
        linkedinLink,
        position,
      })
        .then((response) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // delete account
  const onDeleteAccount = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(password)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_PASSWORD}`, { ns: "errors" }));
        });
      }
      _setIsLoading(true);
      return onDeleteAccountApi({ email, password })
        .then((response) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // Lost password
  const onLostPassword = useCallback((email: string) => {
    if (!checkEmptyInput(email)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
      });
    }

    _setIsLoading(true);
    return onLostPasswordApi({ email })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then(() => _setIsLoading(false));
  }, []);

  // Reset Password from email
  const onResetPassword = useCallback(
    ({
      new_password_token,
      email,
      password,
      repeatPassword,
    }: {
      new_password_token: string;
      email: string;
      password: string;
      repeatPassword: string;
    }) => {
      if (!checkEmptyInput(new_password_token)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_TOKEN}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(password)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_PASSWORD}`, { ns: "errors" }));
        });
      }
      if (!checkStringEquality(password, repeatPassword)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${PASSWORD_NO_MATCH}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onResetPasswordApi({ new_password_token, email, password })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // change password from user account settings
  const onChangePassword = useCallback(
    ({
      userId,
      newPassword,
      repeatPassword,
    }: {
      userId: number;
      newPassword: {
        inputName: string;
        value: string;
      };
      repeatPassword: {
        inputName: string;
        value: string;
      };
    }) => {
      if (!checkEmptyInput(newPassword.value)) {
        return new Promise((resolve, reject) => {
          reject({
            inputName: newPassword.inputName,
            message: t(`form.${EMPTY_PASSWORD}`, { ns: "errors" }),
          });
        });
      }
      if (!checkStringEquality(newPassword.value, repeatPassword.value)) {
        return new Promise((resolve, reject) => {
          reject({
            inputName: repeatPassword.inputName,
            message: t(`form.${PASSWORD_NO_MATCH}`, { ns: "errors" }),
          });
        });
      }

      _setIsLoading(true);
      return onChangePasswordApi({ userId, newPassword: newPassword.value })
        .then((response) => {
          _setIsLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // send contact message
  const onSendContactMessage = useCallback(
    ({
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
    }: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      message: string;
    }) => {
      if (!checkEmptyInput(firstName)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FIRSTNAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(lastName)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_LASTNAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(message)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_CONTACT_MESSAGE}`, { ns: "errors" }));
        });
      }
      if (phoneNumber) {
        if (!checkValidPhoneNumber(phoneNumber)) {
          return new Promise((resolve, reject) => {
            reject(t(`form.${NOT_VALID_PHONE_NUMBER}`, { ns: "errors" }));
          });
        }
      }
      _setIsLoading(true);
      return onSendContactMessageApi({
        firstName,
        lastName,
        email,
        phoneNumber,
        message,
      })
        .then((response) => {
          _setIsLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then(() => _setIsLoading(false));
    },
    []
  );

  // get current user
  const onGetAvailableFormations = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetAvailableFormationsApi(id)
      .then((response) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  // get all users
  const onGetAllUsers = useCallback(() => {
    _setIsLoading(true);
    return onGetAllUsersApi()
      .then((response) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <UserContext.Provider
      {...props}
      value={{
        isLoading,
        onCreateAccount,
        onLostPassword,
        onResetPassword,
        onSendContactMessage,
        onChangePassword,
        onGetCurrentUser,
        onEditAccount,
        onChangeProfileImage,
        onEditMyFile,
        onDeleteAccount,
        onGetAvailableFormations,
        onGetAllUsers,
      }}
    />
  );
};

export const useUser = (): IUserContext => {
  const context = useContext(UserContext);
  if (!context) throw new Error(USER_MSG);
  return context;
};
