import React from "react";
import styled from "styled-components";
import { BLACK, BLUE, CLEAR_BLUE, WHITE } from "../constants/cts_colors";
import { MdFavoriteBorder, MdOutlineFavorite } from "react-icons/md";

const FavoriteButton = ({
  isFavorited,
  onClick,
}: {
  isFavorited: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <FavoriteButtonStyles onClick={onClick}>
      <div className="flex flex-row items-center justify-center">
        {isFavorited ? (
          <MdOutlineFavorite size={24} />
        ) : (
          <MdFavoriteBorder size={24} />
        )}
      </div>
    </FavoriteButtonStyles>
  );
};

export default FavoriteButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const FavoriteButtonStyles = styled.button`
  background-color: ${CLEAR_BLUE};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${CLEAR_BLUE};
  transition: background-color 250ms linear, color 250ms linear;

  div {
    color: ${BLACK};
  }

  :hover {
    background-color: rgba(0, 0, 0, 0);

    div {
      color: ${CLEAR_BLUE};
    }
  }
`;
