import { instanceAxios } from "../utils/axios-api";

export const onCreateCompanyApi = ({ name }: { name: string }) => {
  const formData = new FormData();
  formData.append("name", name || "");

  return instanceAxios
    .post("/companies/create-company", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllCompaniesApi = () => {
  return instanceAxios
    .get(`/companies/get-all-companies`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetCompanyApi = (id: number) => {
  return instanceAxios
    .get(`/companies/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteCompanyApi = (id: number) => {
  return instanceAxios
    .put("/companies/delete-company/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateCompanyApi = ({
  id,
  name,
  formations,
}: {
  id: number;
  name: string;
  formations: Array<number>;
}) => {
  console.log("formation", formations);

  return instanceAxios
    .put("/companies/update-company/" + id, {
      name,
      formations,
    })
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetNumberOfMembersApi = (id: number) => {
  return instanceAxios
    .get("/companies/get-number-members/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetSearchCompaniesApi = (search: string) => {
  const formData = new FormData();

  formData.append("search", search || "");

  // console.log("\n\nFRONT API : ", search, "\n\n");

  return instanceAxios
    .post(`/companies/search`, formData)
    .then((response) => (response.data ? response.data.data : null));
};
