import React, { useState, useCallback, createContext } from "react";
import { IAnalyticLogsContext } from "../../interfaces/analyticLogs";
import {
  onAnalyticsCommunityPageViewApi,
  onAnalyticsFormationClickApi,
  onAnalyticsFormationsPageViewApi,
  onAnalyticsGetMyListeningsPercentagesForPodcastApi,
  onAnalyticsLinkedinLinkClickApi,
  onAnalyticsMembersDirectoryPageViewApi,
  onAnalyticsPodcastClickApi,
  onAnalyticsPodcastListeningMinutesApi,
  onAnalyticsPodcastListeningPercentageApi,
  onAnalyticsPodcastsPageViewApi,
  onAnalyticsResourceClickApi,
  onAnalyticsResourcesPageViewApi
} from "../../api/analyticLog.api";

const AnalyticLogsContext = createContext(null);

export function AnalyticLogsProvider(props: any) {
  const [isLoadingAnalyticLogs, _setIsLoadingAnalyticLogs] = useState(false);

  const onAnalyticsPodcastsPageView = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsPodcastsPageViewApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsResourcesPageView = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsResourcesPageViewApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsCommunityPageView = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsCommunityPageViewApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);


  const onAnalyticsMembersDirectoryPageView = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsMembersDirectoryPageViewApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsFormationsPageView = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsFormationsPageViewApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsPodcastClick = useCallback(async (podcastId: number) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsPodcastClickApi(podcastId)
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsResourceClick = useCallback(async (resourceId: number) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsResourceClickApi(resourceId)
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsFormationClick = useCallback(async (formationId: number) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsFormationClickApi(formationId)
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsLinkedinLinkClick = useCallback(async () => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsLinkedinLinkClickApi()
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsPodcastListeningPercentage = useCallback(async ({
    podcastId,
    listeningPercentage,
  }: {
    podcastId: number,
    listeningPercentage: number,
  }) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsPodcastListeningPercentageApi({
      podcastId,
      listeningPercentage,
    })
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsPodcastListeningMinutes = useCallback(async ({
    podcastId,
    listeningMinutes,
  }: {
    podcastId: number,
    listeningMinutes: number,
  }) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsPodcastListeningMinutesApi({
      podcastId,
      listeningMinutes,
    })
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onAnalyticsGetMyListeningsPercentagesForPodcast = useCallback(async (podcastId: number) => {
    _setIsLoadingAnalyticLogs(true);
    return onAnalyticsGetMyListeningsPercentagesForPodcastApi(podcastId)
      .then((response: any) => {
        _setIsLoadingAnalyticLogs(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <AnalyticLogsContext.Provider
      {...props}
      value={{
        isLoadingAnalyticLogs,
        onAnalyticsPodcastsPageView,
        onAnalyticsResourcesPageView,
        onAnalyticsCommunityPageView,
        onAnalyticsMembersDirectoryPageView,
        onAnalyticsFormationsPageView,
        onAnalyticsPodcastClick,
        onAnalyticsResourceClick,
        onAnalyticsFormationClick,
        onAnalyticsLinkedinLinkClick,
        onAnalyticsPodcastListeningPercentage,
        onAnalyticsPodcastListeningMinutes,
        onAnalyticsGetMyListeningsPercentagesForPodcast,
      }}
    />
  );
}

export const useAnalyticLogs = (): IAnalyticLogsContext => {
  const context = React.useContext(AnalyticLogsContext);
  if (!context) throw new Error("useAnalyticLogs must be used in AnalyticLogsProvider");

  return context;
};
