import React, { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";
import penIconGrey from "../../assets/images/form/pen-grey.png";
import InputWithLabel from "../../components/form/InputWithLabel";
import { useTranslation } from "react-i18next";
import { IMyFileFormData, IUserInfos } from "../../interfaces/user";
import { useUser } from "../../common/contexts/userContext";
import { CHANGE_SAVED } from "../../constants/cts_infoMessages";
import TextareaWithLabel from "../../components/form/TextareaWithLabel";
import Button from "../../components/Button";
import { GREY } from "../../constants/cts_colors";

const MyFile = ({user, loadUser}: {user: IUserInfos, loadUser: Function}) => {
  const { t } = useTranslation();
  const [formData, _setFormData] = useState<IMyFileFormData>({
    pitch: {
      inputName: 'pitch',
      value: user.pitch ? user.pitch : '',
      isEditing: false,
    },
    anecdote1: {
      inputName: 'anecdote1',
      value: user.anecdotes && user.anecdotes.anecdote1 ? user.anecdotes.anecdote1 : '',
      isEditing: false,
    },
    anecdote2: {
      inputName: 'anecdote2',
      value: user.anecdotes && user.anecdotes.anecdote2 ? user.anecdotes.anecdote2 : '',
      isEditing: false,
    },
    anecdote3: {
      inputName: 'anecdote3',
      value: user.anecdotes && user.anecdotes.anecdote3 ? user.anecdotes.anecdote3 : '',
      isEditing: false,
    },
    linkedinLink: {
      inputName: 'linkedinLink',
      value: user.linkedinLink ? user.linkedinLink : '',
      isEditing: false,
    },
    position: {
      inputName: 'position',
      value: user.position ? user.position : '',
      isEditing: false,
    },
  });
  const { onEditMyFile } = useUser();
  const [infoMessage, _setInfoMessage] = useState<string>('');

  // HANDLE CHANGE
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target
    let input = null;

    switch (name) {
      case formData.pitch.inputName:
        input = formData.pitch
        break;
      case formData.anecdote1.inputName:
        input = formData.anecdote1
        break;
      case formData.anecdote2.inputName:
        input = formData.anecdote2
        break;
      case formData.anecdote3.inputName:
        input = formData.anecdote3
        break;
      case formData.linkedinLink.inputName:
        input = formData.linkedinLink
        break;
      case formData.position.inputName:
        input = formData.position
        break;
    }

    if (input) {
      _setFormData({
        ...formData,
        [input.inputName]: {
          ...(formData as any)[input.inputName],
          value,
        }
      })
      _setInfoMessage('');
    }
  }

  // HANDLE SUBMIT
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onEditMyFile({
      userId: user.id,
      pitch: formData.pitch.value,
      anecdotes: {
        anecdote1: formData.anecdote1.value,
        anecdote2: formData.anecdote2.value,
        anecdote3: formData.anecdote3.value,
      },
      linkedinLink: formData.linkedinLink.value,
      position: formData.position.value,
    })
      .then(() => {
        _setInfoMessage(
          '' + t(CHANGE_SAVED, { ns: "infoMessages" })
        );
        loadUser();
      })
      .catch((error) => {
        console.error(error)
      });
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      {/* pitch */}
      <TextareaWithLabel
        label={t("myFile.form.labels.pitch", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.pitch", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.pitch.isEditing ? 'active' : ''} />}
        name={formData.pitch.inputName}
        value={formData.pitch.value}
        onChange={handleChange} />
      {/* anecdote 1 */}
      <InputWithLabel
        label={t("myFile.form.labels.anecdote1", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.anecdote1", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.anecdote1.isEditing ? 'active' : ''} />}
        name={formData.anecdote1.inputName}
        value={formData.anecdote1.value}
        onChange={handleChange} />
      {/* anecdote 2 */}
      <InputWithLabel
        label={t("myFile.form.labels.anecdote2", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.anecdote2", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.anecdote2.isEditing ? 'active' : ''} />}
        name={formData.anecdote2.inputName}
        value={formData.anecdote2.value}
        onChange={handleChange} />
      {/* anecdote 3 */}
      <InputWithLabel
        label={t("myFile.form.labels.anecdote3", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.anecdote3", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.anecdote3.isEditing ? 'active' : ''} />}
        name={formData.anecdote3.inputName}
        value={formData.anecdote3.value}
        onChange={handleChange} />
      {/* job position */}
      <InputWithLabel
        label={t("myFile.form.labels.position", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.position", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.position.isEditing ? 'active' : ''} />}
        name={formData.position.inputName}
        value={formData.position.value}
        onChange={handleChange} />
      {/* linkedin profile link */}
      <InputWithLabel
        label={t("myFile.form.labels.linkedinProfileLink", { ns: "accountPage" })}
        placeholder={t("myFile.form.placeholders.linkedinProfileLink", { ns: "accountPage" })}
        icon={<img src={penIconGrey} className={formData.linkedinLink.isEditing ? 'active' : ''} />}
        name={formData.linkedinLink.inputName}
        value={formData.linkedinLink.value}
        onChange={handleChange} />
      {
        infoMessage &&
        <FormInfoMessage>{infoMessage}</FormInfoMessage>
      }
      <Button text={t("myFile.form.saveBtn", { ns: "accountPage" })} onClick={(e) => handleSubmit(e)} />
    </Form>
  )
}

export default MyFile;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  padding-right: 10px;
`

const FormInfoMessage = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${GREY};
`