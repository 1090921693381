import { instanceAxios } from "../utils/axios-api";

export const onSetFavoriteApi = ({
  user_id,
  post_id,
  formation_id,
}: {
  user_id: number;
  post_id: number;
  formation_id: number;
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString() || "");
  if (post_id) formData.append("post_id", post_id.toString() || "");
  if (formation_id)
    formData.append("formation_id", formation_id.toString() || "");

  return instanceAxios
    .post("/favorites/set-favorite", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllFavoritesFromUserApi = (user_id: number) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString() || "");
  return instanceAxios
    .post(`/favorites/get-all-favorites-from-user`, formData)
    .then((response) => (response.data ? response.data.data : null));
};

export const onCheckIfUserFavoritedATypeApi = ({
  user_id,
  type,
  type_id,
}: {
  user_id: number;
  type: string;
  type_id: number;
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString() || "");
  formData.append("type", type);
  formData.append("type_id", type_id.toString() || "");

  return instanceAxios
    .post("/favorites/check-if-favorited", formData)
    .then((response) => (response.data ? response.data.data : null));
};
