import React from "react";
import styled from "styled-components";
import { BLACK, GREY } from "../constants/cts_colors";
import { BsThreeDotsVertical } from "react-icons/bs";
const DotsButton = ({
  type,
  onClick,
}: {
  type: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <DotsButtonStyles onClick={onClick}>
      <BsThreeDotsVertical size={22} />
    </DotsButtonStyles>
  );
};

export default DotsButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const DotsButtonStyles = styled.button`
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  color: ${GREY};

  :hover {
    color: ${BLACK};
  }
`;
