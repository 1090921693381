import { IMembersInfos } from "../interfaces/members";
import { instanceAxios } from "../utils/axios-api";

export const onCreateGroupApi = ({
  name,
  members,
  formations,
}: {
  name: string;
  members: string;
  formations: string;
}) => {
  const formData = new FormData();
  formData.append("name", name || "");
  formData.append("members", members || "");
  formData.append("formations", formations || "");

  return instanceAxios
    .post("/groups/create-group", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllGroupsApi = () => {
  return instanceAxios
    .get(`/groups/get-all-groups`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetGroupApi = (id: number) => {
  return instanceAxios
    .get(`/groups/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteGroupApi = (id: number) => {
  return instanceAxios
    .put("/groups/delete-group/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateGroupApi = ({
  id,
  name,
  members,
  formations,
}: {
  id: number;
  name: string;
  members: Array<number>;
  formations: Array<number>;
}) => {
  // console.log("API ,", formations);

  return instanceAxios
    .put("/groups/update-group/" + id, {
      name,
      members,
      formations,
    })
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetNumberOfMembersApi = (id: number) => {
  return instanceAxios
    .get("/groups/get-number-members/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetSearchGroupsApi = (search: string) => {
  const formData = new FormData();

  formData.append("search", search || "");

  // console.log("\n\nFRONT API : ", search, "\n\n");

  return instanceAxios
    .post(`/groups/search`, formData)
    .then((response) => (response.data ? response.data.data : null));
};
