import { instanceAxios } from "../utils/axios-api";

export const onCreateCoachApi = ({
  first_name,
  last_name,
  email,
  phone_number,
}: {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}) => {
  const formData = new FormData();
  formData.append("first_name", first_name || "");
  formData.append("last_name", last_name || "");
  formData.append("email", email || "");
  formData.append("phone_number", phone_number || "");

  return instanceAxios
    .post("/coachs/create-coach", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllCoachsApi = () => {
  return instanceAxios
    .get(`/coachs/get-all-coachs`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetCoachApi = (id: number) => {
  return instanceAxios
    .get(`/coachs/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteCoachApi = (id: number) => {
  return instanceAxios
    .put("/coachs/delete-coach/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateCoachApi = ({
  id,
  first_name,
  last_name,
  email,
  phone_number,
}: {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}) => {
  return instanceAxios
    .put("/coachs/update-coach/" + id, {
      first_name,
      last_name,
      email,
      phone_number,
    })
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetSearchCoachsApi = (search: string) => {
  const formData = new FormData();

  formData.append("search", search || "");

  return instanceAxios
    .post(`/coachs/search`, formData)
    .then((response) => (response.data ? response.data.data : null));
};
