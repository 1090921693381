import Keycloak from "keycloak-js";

// LOCAL VALUES
const keycloak = new Keycloak({
  url: "https://auth.les-cles.com",
  realm: "master",
  clientId: "les-cles-client",
});

export default keycloak;
