import styled from "styled-components";
import { BLUE, LIGHT_GREY, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import ProfileImage from "./ProfileImage";
import { IoIosArrowRoundForward } from "react-icons/io";

const WriteYourPost = ({
  onClick,
  userImage,
}: {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  userImage: string;
}) => {
  const { t } = useTranslation();

  return (
    <CreatePostDiv onClick={onClick}>
      <ProfileImage src={userImage} />

      <p>{t("post.write", { ns: "circlePage" })}</p>

      <AddPost>
        <IoIosArrowRoundForward size={24} />
      </AddPost>
    </CreatePostDiv>
  );
};

export default WriteYourPost;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const CreatePostDiv = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  position: relative;
  transition: 250ms;
  /* disable selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-weight: bold;

  :hover {
    box-shadow: 2.5px 2.5px 2.5px ${LIGHT_GREY},
      -2.5px 2.5px 2.5px ${LIGHT_GREY};
  }

  & > img {
    position: absolute;
    width: 32px;
    left: 20px;
    top: 16px;
    border-radius: 50%;
  }

  & > button {
    position: absolute;
    right: 20px;
    /* top: 16px; */
    background-color: ${BLUE};
    color: ${WHITE};
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 25ms ease-in-out;

    :hover {
      border: 1px solid ${BLUE};
      background-color: ${WHITE};
      color: ${BLUE};
    }
  }

  margin: auto;
  padding: 16px 12px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  border-radius: 56px;
  cursor: pointer;

  max-width: 55%;

  @media (max-width: 1250px) {
    max-width: 80%;
  }

  & > p {
    flex: 1;
    text-align: left;
  }
`;

const AddPost = styled.button`
  background-color: ${BLUE};
  color: ${WHITE};
  padding: 6px;
  border-radius: 6px;
`;
