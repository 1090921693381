import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import { checkEmptyInput } from "../../utils/checkInputs";
import { EMPTY_COMMENT_CONTENT } from "../../constants/cts_formErrors";

import {
  onCreateCommentApi,
  onDeleteCommentApi,
  onGetAllCommentsFromCommentApi,
  onGetAllCommentsFromPostApi,
  onUpdateCommentApi,
} from "../../api/comment.api";
import { ICommentsContext, ICommentsInfos } from "../../interfaces/comments";

const CommentsContext = createContext(null);

export function CommentsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateComment = useCallback(
    ({ user_id, post_id, comment_id, content }: ICommentsInfos) => {
      if (!checkEmptyInput(content)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COMMENT_CONTENT}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onCreateCommentApi({
        user_id,
        post_id,
        comment_id,
        content,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllCommentsFromPost = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetAllCommentsFromPostApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetAllCommentsFromComment = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetAllCommentsFromCommentApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdateComment = useCallback(
    ({
      id,
      user_id,
      post_id,
      comment_id,
      content,
    }: {
      id: number;
      user_id: number;
      post_id: number;
      comment_id: number;
      content: string;
    }) => {
      if (!checkEmptyInput(content)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COMMENT_CONTENT}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onUpdateCommentApi({
        id,
        user_id,
        post_id,
        comment_id,
        content,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteComment = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteCommentApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <CommentsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateComment,
        onGetAllCommentsFromPost,
        onGetAllCommentsFromComment,
        onUpdateComment,
        onDeleteComment,
      }}
    />
  );
}

export const useComments = (): ICommentsContext => {
  const context = React.useContext(CommentsContext);
  if (!context) throw new Error("useComments must be used in CommentProvider");

  return context;
};
