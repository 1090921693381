import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  MdOutlineReplay10,
  MdForward10,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import {
  FaPauseCircle,
  FaRegPlayCircle,
  FaRegPauseCircle,
  FaPlayCircle,
} from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { BLACK, BLUE } from "../constants/cts_colors";

const AudioPlayer = ({
  audio,
  cancelPlaying,
  onChangeListeningValues,
}: {
  audio: string;
  cancelPlaying: boolean;
  onChangeListeningValues: Function;
}) => {
  const { t } = useTranslation();
  const [audioRef, setAudioRef] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, _setAudioDuration] = useState<number>(0);
  const [currentAudioPos, _setCurrentAudioPos] = useState<number>(0);
  const [currentTimeAlternate, _setCurrentTimeAlternate] = useState<number>(0);
  const [onPlayHovered, _setOnPlayHovered] = useState<boolean>(false);
  const [speedReading, setSpeedReading] = useState<number>(1);
  const [audioTime, _setAudioTime] = useState({
    min: 0,
    sec: 0,
  });
  const [currentTime, _setCurrentTime] = useState({
    min: 0,
    sec: 0,
  });
  const [listeningDuration, _setListeningDuration] = useState<number>(0);

  useEffect(() => {
    if (audio) {
      const a = new Audio(audio);
      a.play();
      setAudioRef(a);
    } else {
      setAudioRef(null);
    }
  }, [audio]);

  useEffect(() => {
    let inter: any = null;
    if (audioRef) {
      inter = setInterval(() => {
        setIsPlaying(!audioRef.paused);
        _setAudioDuration(audioRef.duration);
        _setCurrentAudioPos(audioRef.currentTime);
      }, 500);
    }

    return () => {
      if (inter) {
        clearInterval(inter);
      }

      if (audioRef) {
        audioRef.pause();
      }
    };
  }, [audioRef]);

  useEffect(() => {
    const min = Math.floor(audioDuration / 60);
    _setAudioTime({
      min,
      sec: Math.floor(audioDuration - min * 60),
    });
  }, [audioDuration]);

  useEffect(() => {
    const min = Math.floor(currentAudioPos / 60);
    _setCurrentTime({
      min,
      sec: Math.floor(currentAudioPos - min * 60),
    });
  }, [currentAudioPos]);

  // UPDATE THE LISTENING DURATION
  useEffect(() => {
    if (currentAudioPos > listeningDuration) {
      const currentListeningDuration = currentAudioPos
      _setListeningDuration(currentListeningDuration)
      // send the listening percentage to the parent
      const percentage = currentListeningDuration / audioDuration
      const minutes = currentListeningDuration / 60
      onChangeListeningValues({
        percentage,
        minutes,
      })
    }
  }, [currentAudioPos])

  useEffect(() => {
    _setCurrentTimeAlternate(
      (currentAudioPos / audioDuration) * audioDuration * 100
    );
  }, [audioDuration, currentAudioPos]);

  useEffect(() => {
    if (audioRef) {
      audioRef.playbackRate = speedReading;
    }
  }, [speedReading]);

  const onPlay = () => {
    if (audioRef) {
      if (!isPlaying) {
        audioRef.play();
      } else {
        audioRef.pause();
      }
    }
  };

  const handleTimeChangeAlternate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let seekTime = 0;
    seekTime = parseInt(e.target.value, 10);
    if (audioRef) {
      audioRef.currentTime = seekTime / 100;
      audioRef.play();
    }
  };

  const onMoveTo = (delta: number) => {
    if (audioRef) {
      audioRef.currentTime = audioRef.currentTime + delta;
      audioRef.play();
    }
  };

  return (
    <AudioPlayerStyles className="flex gap-4 flex-col justify-center items-center">
      {/* INPUT RANGE */}
      <div className="flex flex-col gap-2 md:gap-1 w-full">
        <AudioSliderInput
          type="range"
          min={0}
          max={audioDuration * 100}
          step={0.01}
          value={currentTimeAlternate || 0}
          onChange={handleTimeChangeAlternate}
        />
        <div
          className="flex flex-row justify-between items-center"
          style={{ color: BLACK }}
        >
          <p>
            {currentTime.min < 10 ? "0" + currentTime.min : currentTime.min}:
            {currentTime.sec < 10 ? "0" + currentTime.sec : currentTime.sec}
          </p>
          <p>
            {audioTime.min < 10 ? "0" + audioTime.min : audioTime.min}:
            {audioTime.sec < 10 ? "0" + audioTime.sec : audioTime.sec}
          </p>
        </div>
      </div>

      {/* CONTROLS */}
      <div className="flex flex-row gap-4 items-center justify-center">
        <div className="cursor-pointer">
          <MdOutlineKeyboardDoubleArrowLeft
            onClick={() => setSpeedReading(speedReading === 0.5 ? 1 : 0.5)}
            size={40}
            color={speedReading === 0.5 ? BLUE : BLACK}
          />
        </div>
        <div className="cursor-pointer">
          <MdOutlineReplay10
            size={40}
            color={BLACK}
            onClick={() => onMoveTo(-10)}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={onPlay}
          onMouseEnter={() => {
            _setOnPlayHovered(true);
          }}
          onMouseLeave={() => {
            _setOnPlayHovered(false);
          }}
        >
          {isPlaying ? (
            onPlayHovered ? (
              <FaRegPauseCircle size={56} />
            ) : (
              <FaPauseCircle size={56} />
            )
          ) : onPlayHovered ? (
            <FaRegPlayCircle size={56} />
          ) : (
            <FaPlayCircle size={56} />
          )}
        </div>
        <div className="cursor-pointer">
          <MdForward10 onClick={() => onMoveTo(10)} size={40} color={BLACK} />
        </div>
        <div className="cursor-pointer">
          <MdOutlineKeyboardDoubleArrowRight
            onClick={() => setSpeedReading(speedReading === 1.5 ? 1 : 1.5)}
            size={40}
            color={speedReading === 1.5 ? BLUE : BLACK}
          />
        </div>
      </div>
    </AudioPlayerStyles>
  );
};

export default AudioPlayer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const AudioPlayerStyles = styled.div`
  color: ${BLUE};
  transition: background-color 250ms linear, color 250ms linear;
`;

const AudioSliderInput = styled.input`
  margin: 8px 0px;
  max-height: 3px;
  background-color: ${BLACK};
`;
