import React, { ReactNode } from "react";
import styled from "styled-components";
import { WHITE } from "../constants/cts_colors";

const Modal = ({
  onClose,
  children,
  width,
  maxHeight,
  padding,
}: {
  onClose: Function;
  children: ReactNode;
  width?: string;
  maxHeight?: string;
  padding?: string;
}) => {
  return (
    <Container>
      <div
        className='overlay'
        onMouseDown={(e) => {
          // check if user clicked (left click) on the overlay
          if (e.nativeEvent.button === 0 && e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <ModalContainer
          style={{
            width, // custom width
            maxHeight, // custom height
            padding,
          }}
        >
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
        </ModalContainer>
      </div>
    </Container>
  );
};

export default Modal;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.24);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContainer = styled.div`
  width: 50%;
  max-width: 100%;
  max-height: 100%;
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 40px;
  display: flex;
`;

const ChildrenContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`
