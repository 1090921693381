import React, { createContext, useCallback, useContext, useState } from "react";
import { onGetMySettingsApi, onToggleMySettingApi } from "../../api/setting.api";
import { ISettingContext } from "../../interfaces/settings";
import { SETTING_MSG } from "../../constants/cts_contextErrors";

const SettingContext = createContext<ISettingContext | null>(null);

// THE PROVIDER
export const SettingProvider = (props: any) => {
  const [isLoadingSettings, _setIsLoadingSettings] = useState(false);

  // get my settings
  const onGetMySettings = useCallback(async () => {
    _setIsLoadingSettings(true);
    return onGetMySettingsApi()
      .then((response) => {
        _setIsLoadingSettings(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response) => {
        _setIsLoadingSettings(false);
        return response;
      });
  }, []);

  // toggle my setting
  const onToggleMySetting = useCallback(async (userSettingId: number) => {
    _setIsLoadingSettings(true);
    return onToggleMySettingApi(userSettingId)
      .then((response) => {
        _setIsLoadingSettings(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response) => {
        _setIsLoadingSettings(false);
        return response;
      });
  }, []);

  return (
    <SettingContext.Provider
      {...props}
      value={{
        isLoadingSettings,
        onGetMySettings,
        onToggleMySetting,
      }}
    />
  );
};

export const useSettings = (): ISettingContext => {
  const context = useContext(SettingContext);
  if (!context) throw new Error(SETTING_MSG);
  return context;
};
