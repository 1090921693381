import { instanceAxios } from "../utils/axios-api";

export const onCreateCommentApi = ({
  user_id,
  post_id,
  comment_id,
  content,
}: {
  user_id: number;
  post_id: number;
  comment_id: number;
  content: string;
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString());
  if (post_id) formData.append("post_id", post_id.toString());
  if (comment_id) formData.append("comment_id", comment_id.toString());
  formData.append("content", content || "");

  return instanceAxios
    .post("/comments/create-comment", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllCommentsFromPostApi = (id: number) => {
  return instanceAxios
    .get(`/comments/get-all-comments-from-post/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllCommentsFromCommentApi = (id: number) => {
  return instanceAxios
    .get(`/comments/get-all-comments-from-comment/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteCommentApi = (id: number) => {
  return instanceAxios
    .put("/comments/delete-comment/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateCommentApi = ({
  id,
  user_id,
  post_id,
  comment_id,
  content,
}: {
  id: number;
  user_id: number;
  post_id: number;
  comment_id: number;
  content: string;
}) => {
  return instanceAxios
    .put("/comments/update-comment/" + id, {
      user_id,
      post_id,
      comment_id,
      content,
    })
    .then((response) => (response.data ? response.data.data : null));
};
