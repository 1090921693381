import React from "react";
import styled from "styled-components";
import { BLUE, RED, WHITE } from "../constants/cts_colors";
const DeleteButton = ({
  text,
  disabled,
  onClick,
}: {
  text: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <DeleteButtonStyles disabled={disabled} onClick={onClick}>
      {text}
    </DeleteButtonStyles>
  );
};

export default DeleteButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const DeleteButtonStyles = styled.button`
  background-color: ${RED};
  color: ${WHITE};
  border-radius: 5px;
  padding: 13.5px;
  border: 1px solid ${WHITE};
  width: 100%;

  transition: 250ms;

  :disabled {
    opacity: 0.5;
    :hover {
      background-color: ${RED};
      color: ${WHITE};
      border: 1px solid ${WHITE};
    }
  }

  :hover {
    background-color: ${WHITE};
    border: 1px solid ${RED};
    color: ${RED};
  }
`;
