import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";

import { IFavoritesInfos, IFavoritesContext } from "../../interfaces/favorites";
import {
  onCheckIfUserFavoritedATypeApi,
  onGetAllFavoritesFromUserApi,
  onSetFavoriteApi,
} from "../../api/favorite.api";

const FavoritesContext = createContext(null);

export function FavoritesProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onSetFavorite = useCallback(
    ({ user_id, post_id, formation_id }: IFavoritesInfos) => {
      _setIsLoading(true);
      return onSetFavoriteApi({
        user_id,
        post_id,
        formation_id,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllFavoritesFromUser = useCallback((user_id: number) => {
    _setIsLoading(true);
    // console.log("FAV CTX : ", user_id);
    return onGetAllFavoritesFromUserApi(user_id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onCheckIfUserFavoritedAType = useCallback(
    ({
      user_id,
      type,
      type_id,
    }: {
      user_id: number;
      type: string;
      type_id: number;
    }) => {
      _setIsLoading(true);
      return onCheckIfUserFavoritedATypeApi({
        user_id,
        type,
        type_id,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  return (
    <FavoritesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onSetFavorite,
        onGetAllFavoritesFromUser,
        onCheckIfUserFavoritedAType,
      }}
    />
  );
}

export const useFavorites = (): IFavoritesContext => {
  const context = React.useContext(FavoritesContext);
  if (!context) throw new Error("usePosts must be used in FavoriteProvider");
  return context;
};
