import { instanceAxios } from "../utils/axios-api";

// export const onCreateLikeApi = ({
//   user_id,
//   post_id,
//   comment_id,
// }: {
//   user_id: number;
//   post_id: number;
//   comment_id: number;
// }) => {
//   const formData = new FormData();
//   formData.append("user_id", user_id.toString() || "");
//   if (post_id) formData.append("post_id", post_id.toString() || "");
//   if (comment_id) formData.append("comment_id", comment_id.toString() || "");

//   return instanceAxios
//     .post("/likes/create-like", formData)
//     .then((response) => (response.data ? response.data : null));
// };

// export const onGetAllLikesFromUserApi = (id: number) => {
//   return instanceAxios
//     .get(`/likes/get-all-likes-from-user/${id}`)
//     .then((response) => (response.data ? response.data.data : null));
// };

export const onGetFileFromIdApi = (id: number) => {
  return instanceAxios
    .get(`/files/read-file/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// export const onDeleteLikeApi = ({
//   user_id,
//   post_id,
//   comment_id,
// }: {
//   user_id: number;
//   post_id: number;
//   comment_id: number;
// }) => {
//   const formData = new FormData();
//   formData.append("user_id", user_id.toString() || "");
//   formData.append("post_id", post_id.toString() || "");
//   formData.append("comment_id", comment_id.toString() || "");

//   return instanceAxios
//     .put("/likes/delete-like/", formData)
//     .then((response) => (response.data ? response.data.data : null));
// };
