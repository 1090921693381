import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../common/contexts/userContext";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../../constants/cts_colors";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";
import logo from "../../assets/images/logo/logo.png";
import FooterLesCles from "../../components/FooterLesCles";
import Button from "../../components/Button";
import i18next from "i18next";

const LostPassword = () => {
  const { t } = useTranslation();
  const { onLostPassword } = useUser();
  const [email, _SetEmail] = useState<string>("");
  const [submitted, _setSubmitted] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    _SetEmail(e.target.value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onLostPassword(email)
      .then(() => {
        alert(
          t("form.infos.linkSent", { ns: "lostPasswordPage" }) + " " + email
        );
        _setSubmitted(true); // Change la valeur de "submitted" si l'email a bien été envoyé à l'utilisateur
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    document.title = t("title", { ns: "lostPasswordPage" });
  }, [i18next.language]);

  return (
    <Wrapper>
      <Container>
        <LogoContainer className="mb-8 flex flex-row w-full justify-start">
          <img src={logo} alt="Logo Les Clefs" width={240} />
        </LogoContainer>
        <Form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 68,
            paddingRight: 68,
            paddingTop: 48,
            paddingBottom: 48,
          }}
        >
          <FormTitle className="font-bold">
            {t("mainTitle", { ns: "lostPasswordPage" })}
          </FormTitle>
          <InputWithSubtitle className="mt-4 mb-8">
            <div className="mb-1 flex flex-row justify-between items-center w-full">
              <p>{t("form.placeholders.email", { ns: "loginPage" })}</p>
              <ForgotPasswordText className="cursor-pointer">
                <Link to={getPageUrl(PATH.login)}>
                  {t("redirectToLogInLink", { ns: "lostPasswordPage" })}
                </Link>
              </ForgotPasswordText>
            </div>
            <FormInput
              type="email"
              id="email"
              name="email"
              placeholder={
                "" + t("form.placeholders.email", { ns: "loginPage" })
              }
              required
              onChange={handleChange}
              style={{ width: 400 }}
            />
          </InputWithSubtitle>
          {!submitted && (
            <Button text={t("send", { ns: "lostPasswordPage" })} />
          )}
        </Form>
        <FooterLesCles />
      </Container>
    </Wrapper>
  );
};

export default LostPassword;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1260px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const LogoContainer = styled.div`
  padding-left: 20px;
`;

const Form = styled.form`
  border-radius: 4px;
  background: ${WHITE};
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);
`;

const FormTitle = styled.p`
  color: ${BLACK};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
`;

const FormInput = styled.input`
  display: flex;
  padding: 13.5px 12px 13.5px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ForgotPasswordText = styled.div`
  color: ${BLUE};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
