import styled from "styled-components";
import { CgProfile } from "react-icons/cg";

const ProfileImage = ({ src }: { src: string | undefined }) => {
  return (
    <ProfileImageStyles style={{backgroundImage: `url(${src})`}}>
      {!src && (
        <div>
          <CgProfile size={55} />
        </div>
      )}
    </ProfileImageStyles>
  );
};

export default ProfileImage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ProfileImageStyles = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;
