import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IUserInfos } from "../interfaces/user";
import { BLACK, BLUE, WHITE } from "../constants/cts_colors";
import arrowDownPath from "../assets/images/png/arrow_down.png";
import arrowUpPath from "../assets/images/png/arrow_up.png";
import { useUser } from "../common/contexts/userContext";
import { useTranslation } from "react-i18next";
import profileIcon from "../assets/images/topbar/profile.png";
import settingsIcon from "../assets/images/topbar/settings.png";
import logoutIcon from "../assets/images/topbar/logout.png";
import { NavLink, useNavigate } from "react-router-dom";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import Modal from "./Modal";
import logo from "../assets/images/topbar/logo.png";
import { useAuthentication } from "../common/contexts/authenticationContext";
import { MdFavoriteBorder } from "react-icons/md";

const Topbar = () => {
  const [user, _setUser] = useState<IUserInfos | null>(null);
  const { onGetCurrentUser } = useUser();
  const [isOptionsMenuOpened, _setIsOptionsMenuOpened] =
    useState<boolean>(false);
  const refOpenOptionsMenu = useRef<HTMLDivElement>(null);
  const refOptionsMenu = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const menuOptionElementClass = "topbar-profile-menu-option";
  const [openModalConfirmLogout, _setOpenModalConfirmLogout] =
    useState<boolean>(false);
  const { onLogout } = useAuthentication();
  const navigate = useNavigate();

  // GET THE USER INFOS
  useEffect(() => {
    onGetCurrentUser()
      .then((response) => _setUser(response))
      .catch((error) => console.error(error));
  }, []);

  // DETECT WHEN THE USER CLICKS OUTSIDE THE OPTIONS POPUP COMPONENT
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleCloseMenu(event: Event) {
      const optionsMenu = refOptionsMenu.current;
      const openOptionsMenu = refOpenOptionsMenu.current;

      if (
        isOptionsMenuOpened &&
        optionsMenu &&
        !optionsMenu.contains(event.target as Node) && // clicked outside the menu
        openOptionsMenu &&
        !openOptionsMenu.contains(event.target as Node) // clicked outside the open menu btn
      ) {
        _setIsOptionsMenuOpened(false);
      }

      // close menu if an option was selected
      const menuOption = (event.target as HTMLElement).closest(
        `.${menuOptionElementClass}`
      );
      if (menuOption) {
        _setIsOptionsMenuOpened(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleCloseMenu);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [refOptionsMenu.current, refOpenOptionsMenu.current, isOptionsMenuOpened]);

  // logout
  const handleLogout = () => {
    onLogout()
      .catch((error) => alert(error));
  };

  return (
    <Container>
      {user && (
        <Profile>
          {user.image && (
            <ImageFrame>
              <img src={user.image} alt="profile" />
            </ImageFrame>
          )}
          <OpenOptions
            onClick={() => _setIsOptionsMenuOpened(true)}
            ref={refOpenOptionsMenu}
          >
            <Name>{user.firstName + " " + user.lastName}</Name>
            <ArrowIcon
              src={isOptionsMenuOpened ? arrowUpPath : arrowDownPath}
              alt="arrow"
            />
          </OpenOptions>
          {isOptionsMenuOpened && (
            <OptionsMenu ref={refOptionsMenu}>
              {/* favorites */}
              <OptionLink
                to={getPageUrl(PATH.favorites)}
                className={menuOptionElementClass}
              >
                {/* <OptionIcon> */}
                <div
                  className="flex justify-center items-center"
                  style={{ width: "40px", height: "40px" }}
                >
                  <MdFavoriteBorder size={24} color={BLUE} />
                </div>
                {/* </OptionIcon> */}
                <OptionText>
                  {t("favorites.title", { ns: "topbar" })}
                </OptionText>
              </OptionLink>

              {/* edit profile */}
              <OptionLink
                to={getPageUrl(PATH.account)}
                className={menuOptionElementClass}
              >
                <OptionIcon src={profileIcon} alt="profile" />
                <OptionText>
                  {t("profile.editProfile", { ns: "topbar" })}
                </OptionText>
              </OptionLink>

              {/* settings */}
              <OptionLink
                to={getPageUrl(PATH.accountSettings)}
                className={menuOptionElementClass}
              >
                <OptionIcon src={settingsIcon} alt="settings" />
                <OptionText>
                  {t("profile.settings", { ns: "topbar" })}
                </OptionText>
              </OptionLink>

              {/* contact */}
              {/* <OptionLink to={getPageUrl(PATH.contact)} className={menuOptionElementClass}>
              <OptionIcon src={contactIcon} alt="contact" />
              <OptionText>
                {t("profile.contact", { ns: "topbar" })}
              </OptionText>
            </OptionLink> */}

              {/* separator */}
              <OptionsSeparator />

              {/* logout */}
              <OptionButton
                onClick={() => _setOpenModalConfirmLogout(true)}
                className={menuOptionElementClass}
              >
                <OptionIcon src={logoutIcon} alt="logout" />
                <OptionText>{t("profile.logout", { ns: "topbar" })}</OptionText>
              </OptionButton>
            </OptionsMenu>
          )}
        </Profile>
      )}

      {openModalConfirmLogout && (
        <Modal onClose={() => _setOpenModalConfirmLogout(false)}>
          <LogoutModalLogo src={logo} alt="logo" />
          <LogoutText>{t("logoutModal.text", { ns: "topbar" })}</LogoutText>
          <LogoutButtons>
            <ButtonExitLogoutModal
              onClick={() => _setOpenModalConfirmLogout(false)}
            >
              {t("logoutModal.buttonExitModal", { ns: "topbar" })}
            </ButtonExitLogoutModal>
            <ButtonConfirmLogout onClick={() => handleLogout()}>
              {t("logoutModal.buttonConfirmLogout", { ns: "topbar" })}
            </ButtonConfirmLogout>
          </LogoutButtons>
        </Modal>
      )}
    </Container>
  );
};

export default Topbar;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  width: 100%;
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 40px;
`;

const Profile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const ImageFrame = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const Name = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
`;

const OpenOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  display: block;
  width: 16px;
  height: 16px;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: ${WHITE};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1;
`;

const OptionLink = styled(NavLink)`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  padding: 8px 38px 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 250ms, color 250ms;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: ${BLUE}11;
  }
`;

const OptionButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  padding: 8px 38px 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 250ms, color 250ms;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: ${BLUE}11;
  }
`;

const OptionIcon = styled.img`
  display: block;
  width: 40px;
  height: 40px;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const OptionText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const OptionsSeparator = styled.div`
  width: 100%;
  height: 0.5px;
  margin: 24px 0px;
  background-color: ${BLACK};
`;

const LogoutModalLogo = styled.img`
  display: block;
  margin: 32px auto;
`;

const LogoutText = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${BLACK};
`;

const LogoutButtons = styled.div`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 342px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonExitLogoutModal = styled.button`
  padding: 20.5px 32px;
  color: ${WHITE};
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  background-color: ${BLUE};
  transition: opacity 250ms;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonConfirmLogout = styled.button`
  font-size: 16px;
  font-weight: bold;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.8;
  }
`;
