import styled from "styled-components";
import { BLACK, GREY } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";

const FooterLesCles = () => {
  const { t } = useTranslation();

  return (
    <FooterLesClesStyles className="mt-8 w-full flex flex-row gap-2 items-center justify-start">
      <p>© Les Clés </p>
      {/*<span className="dot"></span>
      <a href="">Contact</a>
      <span className="dot"></span>
  <a href="">{t("privacyAndTerms", { ns: "loginPage" })}</a>*/}
    </FooterLesClesStyles>
  );
};

export default FooterLesCles;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const FooterLesClesStyles = styled.div`
  padding-left: 20px;
  color: ${GREY};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 250ms;

  & > a {
    :hover {
      color: ${BLACK};
    }
  }

  & > .dot {
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: ${GREY};
  }
`;
