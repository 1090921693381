import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";

import { onGetFileFromIdApi } from "../../api/file.api";
import { IFilesContext } from "../../interfaces/files";

const FilesContext = createContext(null);

export function FilesProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onGetFileFromId = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetFileFromIdApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <FilesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetFileFromId,
      }}
    />
  );
}

export const useFiles = (): IFilesContext => {
  const context = React.useContext(FilesContext);
  if (!context) throw new Error("useFiles must be used in FileProvider");

  return context;
};
