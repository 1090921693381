import React, { useEffect, useRef, useState, useTransition } from "react";
import styled from "styled-components";
import {
  BLACK,
  WHITE,
  GREY,
  BLUE,
  GREEN,
  LIGHT_GREY,
  CLEAR_GREY,
} from "../constants/cts_colors";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IMembersInfos } from "../interfaces/members";

import edit_logo from "../assets/images/png/edit_logo.png";
import delete_logo from "../assets/images/png/delete_logo.png";
import teachup_logo from "../assets/images/png/teachup_idle.png";
import { useTranslation } from "react-i18next";
import { useCompanies } from "../common/contexts/companyContext";
import { useAuthentication } from "../common/contexts/authenticationContext";
import { useMembers } from "../common/contexts/memberContext";

const UserMemberLine = ({
  member,
  company,
  popupOperations,
  onClick,
  onEdit,
  onDelete,
}: {
  member: IMembersInfos;
  company?: string;
  popupOperations?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onEdit?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onDelete?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const { t } = useTranslation();
  const { isUserAdmin } = useAuthentication();
  const { switchToAdminAMember } = useMembers();

  const [isOperationsOpen, _setIsOperationsOpen] = useState<boolean>(false);

  const { onGetCompany } = useCompanies();
  const [companyName, _setCompanyName] = useState<string>("");

  const refOperationsPopup = useRef<HTMLDivElement>(null);

  // USE EFFECT FOR CLICK OUTSIDE OF GROUP POPUP
  useEffect(() => {
    function handleClickOperationsOutside(event: any) {
      if (
        refOperationsPopup.current &&
        !refOperationsPopup.current.contains(event.target)
      ) {
        _setIsOperationsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOperationsOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOperationsOutside);
    };
  }, [refOperationsPopup]);

  useEffect(() => {
    if (popupOperations) _setIsOperationsOpen(false);
  }, [popupOperations]);

  useEffect(() => {
    if (company) {
      onGetCompany(company)
        .then((response) => {
          _setCompanyName(response.name);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [company]);

  const onSwitchToAdmin = () => {
    switchToAdminAMember({ id: member.id, isAdmin: true }).then(() => {
      _setIsOperationsOpen(false);
      alert("Ok !");
    });
  };

  return (
    <UserMemberStyles className="mx-28 my-4">
      <div
        className="flex basis-12 mr-2 items-center cursor-pointer gap-2"
        style={{
          color: GREY,
          fontSize: 14,
          fontWeight: 500,
          maxHeight: 72,
        }}
        onClick={onClick}
      >
        {/*<div className="h-full basis-1/12 flex justify-center items-center justify-center">
          <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
      </div>*/}

        {/* USER */}
        <div className="basis-3/12 font-bold flex items-center justify-start h-full gap-3">
          <img
            src={member.image || "https://www.pngkey.com/png/full/157-1579943_no-profile-picture-round.png"}
            width={43}
            height={43}
            style={{ borderRadius: "50%" }}
          />
          <p style={{ color: BLACK }}>
            {member.first_name} {member.last_name}
          </p>
        </div>
        <p className="basis-1/12 flex items-center justify-start">
          {member.status ? "Oui" : "Non"}
        </p>
        <p className="basis-2/12 col-start-6 flex items-center justify-start break-all">
          {member.email}
        </p>
        <p className="basis-2/12 col-start-8 flex items-center justify-start">
          {companyName}
        </p>
        <p className="basis-1/12 flex items-center justify-start">
          {member.position}
        </p>
        <p className="basis-1/12">
          <div className="flex flex-col justify-start items-center gap-2">
            {member.access_to_community && (
              <TeachupButton className="py-1 px-2 rounded-xl text-center">
                {t("community", { ns: "settingsPage" })}
              </TeachupButton>
            )}
            {member.access_to_learning && (
              <CommunityButton className="py-1 px-2 rounded-xl text-center">
                TeachUp
              </CommunityButton>
            )}
          </div>
        </p>
        <div className="basis-2/12 flex flex-row justify-center items-center gap-3">
          {popupOperations && (
            <DotsOperations
              className="bg-red-100 p-3 rounded-lg"
              onClick={() => {
                _setIsOperationsOpen(true);
              }}
            >
              <BsThreeDotsVertical size={20} color={BLUE} />
            </DotsOperations>
          )}
        </div>
      </div>
      {/* OPERATIONS MODAL */}
      <OperationsStyles
        style={{
          display: isOperationsOpen
            ? popupOperations
              ? "block"
              : "none"
            : "none",
        }}
        ref={refOperationsPopup}
      >
        <div className="flex flex-row gap-2 items-center" onClick={onEdit}>
          <img src={edit_logo} width={20} />
          {t("edit", { ns: "settingsPage" })}
        </div>
        <div className="flex flex-row gap-2 items-center" onClick={onDelete}>
          <img src={delete_logo} width={20} />
          {t("delete", { ns: "settingsPage" })}
        </div>
        {isUserAdmin && (
          <div
            className="flex flex-row gap-2 items-center"
            onClick={onSwitchToAdmin}
          >
            <img src={teachup_logo} width={20} />
            {t("becomeAdmin", { ns: "settingsPage" })}
          </div>
        )}
      </OperationsStyles>
    </UserMemberStyles>
  );
};

export default UserMemberLine;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const UserMemberStyles = styled.div`
  position: relative;
  padding: 12px 0px;
  border-radius: 8px;

  :hover {
    background-color: ${LIGHT_GREY};
  }
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const DotsOperations = styled.div`
  background-color: ${CLEAR_GREY};

  :hover {
    background-color: ${GREY};
  }
`;

const OperationsStyles = styled.div`
  z-index: 10;
  position: absolute;
  top: 48px;
  right: -6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background-color: ${WHITE};
  border: 1px solid ${GREY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    :first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    :last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;

const TeachupButton = styled.button`
  border: 1px solid ${BLUE};
  background-color: ${BLUE};
  color: ${WHITE};
  transition: 250ms;
  font-weight: bold;
  width: 100px;

  :hover {
    background-color: ${CLEAR_GREY};
    color: ${BLUE};
  }
`;

const CommunityButton = styled.button`
  border: 1px solid ${GREEN};
  background-color: ${GREEN};
  color: ${WHITE};
  transition: 250ms;
  font-weight: bold;
  width: 100px;

  :hover {
    background-color: ${CLEAR_GREY};
    color: ${GREEN};
  }
`;
