import React from "react";
import styled from "styled-components";
import { BLUE, LIGHT_BLUE, CLEAR_BLUE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

const LikePostButton = ({
  isLiked,
  onClick,
}: {
  isLiked: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <LikePostButtonStyles
      onClick={onClick}
      className={isLiked ? "is-liked" : ""}
    >
      <div>
        {isLiked ? <AiFillHeart size={22} /> : <AiOutlineHeart size={22} />}
      </div>
      <p>{t("post.like", { ns: "circlePage" })}</p>
    </LikePostButtonStyles>
  );
};

export default LikePostButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const LikePostButtonStyles = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: ${CLEAR_BLUE};
  border-radius: 8px;

  &.is-liked {
    color: ${BLUE};
  }

  & > p {
    font-weight: bold;
  }

  :hover {
    color: ${BLUE};
    background-color: ${LIGHT_BLUE};
  }
`;
