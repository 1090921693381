import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MdArrowBackIosNew } from "react-icons/md";
import { useState } from "react";

const ArrowWithTextButton = ({
  text,
  arrowPosition,
  color,
  onClick,
}: {
  text: string;
  arrowPosition: string;
  color: string;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const { t } = useTranslation();

  const [isHovered, _setIsHovered] = useState<boolean>(false);

  const arrowStyle = {
    color: color,
    textDecorationColor:
      color === "white"
        ? `${isHovered ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0)"}`
        : `${isHovered ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0)"}`,
  };

  return (
    <ArrowStyles
      className="flex flex-row items-center gap-1 cursor-pointer"
      style={arrowStyle}
      onMouseEnter={() => {
        _setIsHovered(true);
      }}
      onMouseLeave={() => {
        _setIsHovered(false);
      }}
      onClick={onClick}
    >
      {arrowPosition === "left" && (
        <div>
          <MdArrowBackIosNew size={14} />
        </div>
      )}
      <p className="text-base font-light">{text}</p>
      {arrowPosition === "right" && (
        <div className="rotate-180">
          <MdArrowBackIosNew size={14} />
        </div>
      )}
    </ArrowStyles>
  );
};

export default ArrowWithTextButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ArrowStyles = styled.div`
  transition: color 250ms linear, text-decoration-color 250ms linear;
  text-underline-offset: 8px;
  color: inherit;
  text-decoration: underline;
`;
