import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LIGHT_GREY, GREY, WHITE, BLUE } from "../constants/cts_colors";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { useTranslation } from "react-i18next";
import { useCoachs } from "../common/contexts/coachContext";
import { ICoachsInfos } from "../interfaces/coachs";
import UserCoachLine from "./UserCoachLine";
import Button from "./Button";
import { ImCross } from "react-icons/im";

const SettingsCoachs = ({}: {}) => {
  const { t } = useTranslation();

  const initialCoachsFormState = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  };

  // ***** COACHS *****
  const [coachFormValues, _setCoachFormValues] = useState(
    initialCoachsFormState
  );

  const [isOperationPopupOpen, _setOperationPopupOpen] =
    useState<boolean>(true);

  const [idUpdateCoach, _setIdUpdateCoach] = useState<number>();

  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  const {
    onCreateCoach,
    onGetAllCoachs,
    onGetCoach,
    onDeleteCoach,
    onUpdateCoach,
    onGetSearchCoachs,
  } = useCoachs();
  const [coachs, _setCoachs] = useState<Array<ICoachsInfos>>([]);
  const [updateCoachValues, _setUpdateCoachValues] = useState<ICoachsInfos>();

  const refCreationCoachsPopup = useRef<HTMLDivElement>(null);

  const [isCreationCoachsPopupOpen, _setIsCreationCoachsPopupOpen] =
    useState<boolean>(false);
  const [isUpdateCoachsPopupOpen, _setIsUpdateCoachsPopupOpen] =
    useState<boolean>(false);

  // ***** COACHS FUNCTIONS *****
  // COACH CHANGE
  const handleCoachChange = (e: any) => {
    // console.log(e);
    const { name, value } = e.target;
    _setCoachFormValues({
      ...coachFormValues,
      [name]: value,
    });
  };

  // COACH SUBMIT
  const handleCoachSubmit = (e: React.FormEvent) => {
    _setCoachFormValues(initialCoachsFormState);
    _setIsUpdateCoachsPopupOpen(false);
    _setIsCreationCoachsPopupOpen(false);
    e.preventDefault();

    if (isCreationCoachsPopupOpen) {
      onCreateCoach({
        first_name: coachFormValues.first_name,
        last_name: coachFormValues.last_name,
        email: coachFormValues.email,
        phone_number: coachFormValues.phone_number,
      })
        .then(() => {
          _setCoachFormValues(initialCoachsFormState);
          onGetAllCoachs()
            .then((response: any) => {
              _setCoachs(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    } else {
      onUpdateCoach({
        id: idUpdateCoach,
        first_name: coachFormValues.first_name,
        last_name: coachFormValues.last_name,
        email: coachFormValues.email,
        phone_number: coachFormValues.phone_number,
      })
        .then(() => {
          _setCoachFormValues(initialCoachsFormState);
          onGetAllCoachs()
            .then((response: any) => {
              _setCoachs(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    }
  };

  // COACH GET VALUES
  const getCoachValues = (id: number) => {
    onGetCoach(id)
      .then((response) => {
        _setCoachFormValues(response);
      })
      .catch((error) => {
        alert(error);
      });
  };

  // HANDLE DELETE COACH
  const handleDeleteCoach = (
    id: number,
    first_name: string,
    last_name: string
  ) => {
    if (
      confirm(
        t("coachs.confirmDelete", { ns: "settingsPage" }) +
          first_name +
          " " +
          last_name +
          " ?"
      )
    ) {
      _setOperationPopupOpen(false);
      onDeleteCoach(id)
        .then((response) => {
          onGetAllCoachs()
            .then((response: any) => {
              _setCoachs(response);
              _setOperationPopupOpen(true);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.log(error);
        });
      // _setIsOperationsOpen(false);
    }
  };

  useEffect(() => {
    onGetAllCoachs()
      .then((response: any) => {
        _setCoachs(response);
      })
      .catch((error: any) => alert(error));
  }, []);

  // USE EFFECT FOR CLICK OUTSIDE OF COMPAGNIES POPUP
  useEffect(() => {
    function handleClickCoachOutside(event: any) {
      if (
        refCreationCoachsPopup.current &&
        !refCreationCoachsPopup.current.contains(event.target)
      ) {
        _setIsCreationCoachsPopupOpen(false);
        _setIsUpdateCoachsPopupOpen(false);
        _setCoachFormValues(initialCoachsFormState);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickCoachOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickCoachOutside);
    };
  }, [refCreationCoachsPopup]);

  //onChange FOR SEARCH INPUT
  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("SEARCH INPUT CHANGED ", value);

    if (value === "") {
      _setNoResultsSentence("");
      onGetAllCoachs().then((response) => {
        _setCoachs(response);
      });
    } else {
      onGetSearchCoachs(value)
        .then((response) => {
          // console.log(response);
          if (response.length === 0) {
            _setNoResultsSentence(t("noResults", { ns: "search" }).toString());
            _setCoachs(response);
          } else {
            _setCoachs(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangeOrderBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let orderByCoachs = Array<ICoachsInfos>();
    switch (e.target.value) {
      case "first_name":
        orderByCoachs = [...coachs].sort((a, b) =>
          a.first_name > b.first_name ? 1 : -1
        );

        _setCoachs(orderByCoachs);
        break;
      case "last_name":
        orderByCoachs = [...coachs].sort((a, b) =>
          a.last_name > b.last_name ? 1 : -1
        );

        _setCoachs(orderByCoachs);
        break;

      case "email":
        orderByCoachs = [...coachs].sort((a, b) =>
          a.email > b.email ? 1 : -1
        );

        _setCoachs(orderByCoachs);
        break;

      case "phone_number":
        orderByCoachs = [...coachs].sort((a, b) =>
          a.phone_number > b.phone_number ? 1 : -1
        );

        _setCoachs(orderByCoachs);
        break;

      default: //all
        onGetAllCoachs()
          .then((response) => {
            _setCoachs(response);
          })
          .catch((error) => {
            console.log(error);
          });
        break;
    }
  };

  return (
    <SettingsCoachsStyle>
      <SearchAndAddContainer
        onChange={handleChangeSearchInput}
        onClick={() => {
          _setIsCreationCoachsPopupOpen(true);
        }}
        showBtOptions={false}
      />
      {/* ORDER BY */}
      <div className="mx-28 my-4 gap-4">
        {t("sortBy", { ns: "orderBy" })}
        <select
          name="orderBy"
          id="orderBy"
          className="rounded-lg border-2 cursor-pointer ml-2"
          onChange={handleChangeOrderBy}
          style={{
            padding: "5px 12px",
            borderColor: BLUE,
            color: BLUE,
          }}
        >
          <option value="all">{t("all", { ns: "orderBy" })}</option>
          <option value="first_name">
            {t("firstName", { ns: "orderBy" })}
          </option>
          <option value="last_name">{t("lastName", { ns: "orderBy" })}</option>
          <option value="email">{t("email", { ns: "orderBy" })}</option>
          <option value="phone_number">
            {t("phoneNumber", { ns: "orderBy" })}
          </option>
        </select>
      </div>
      {/* HEADER OF COACHS */}
      <div
        className="mx-28 my-4 gap-4"
        style={{ borderColor: LIGHT_GREY, borderBottomWidth: 1 }}
      >
        <div
          className="flex basis-12 items-center pt-3 pb-6 gap-2"
          style={{
            color: GREY,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*<div className="h-full basis-1/12 flex items-center justify-center">
            <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
        </div>*/}
          <p className="basis-3/12">
            {t("coachs.user", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("coachs.email", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("coachs.phoneNumber", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("members.activity", { ns: "settingsPage" })}
          </p>
        </div>
      </div>

      <div className="overflow-scroll" style={{ height: "62vh" }}>
        {/* LIST OF COACHS */}
        {coachs &&
          coachs.map((coach, key) => {
            return (
              <UserCoachLine
                key={key}
                coach={coach}
                popupOperations={isOperationPopupOpen}
                onEdit={() => {
                  getCoachValues(coach.id);
                  _setIdUpdateCoach(coach.id);
                  _setIsUpdateCoachsPopupOpen(true);
                }}
                onDelete={() => {
                  handleDeleteCoach(
                    coach.id,
                    coach.first_name,
                    coach.last_name
                  );
                }}
              />
            );
          })}
        {coachs && coachs.length < 1 && (
          <NoContentSentence>
            {t("coachs.noCoachs", { ns: "settingsPage" })}
          </NoContentSentence>
        )}
      </div>
      {/* FORM CREATION FORM COACHS */}
      <CreationPopupContent
        style={{
          display:
            isCreationCoachsPopupOpen || isUpdateCoachsPopupOpen
              ? "block"
              : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-24 py-12 px-16"
          ref={refCreationCoachsPopup}
        >
          <form onSubmit={handleCoachSubmit}>
            <h3>
              {isUpdateCoachsPopupOpen &&
                t("form.nameCoachsUpdate", { ns: "settingsPage" })}
              {isCreationCoachsPopupOpen &&
                t("form.nameCoachsCreation", { ns: "settingsPage" })}
            </h3>

            {/* FIRST NAME */}
            <InputWithSubtitle>
              <label htmlFor="first_name" className="mt-4 mb-1">
                {t("form.placeholders.first_name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="first_name"
                name="first_name"
                placeholder={
                  "" + t("form.placeholders.first_name", { ns: "settingsPage" })
                }
                required
                onChange={handleCoachChange}
                value={coachFormValues.first_name}
              />
            </InputWithSubtitle>
            {/* LAST NAME */}
            <InputWithSubtitle>
              <label htmlFor="last_name" className="mt-4 mb-1">
                {t("form.placeholders.last_name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="last_name"
                name="last_name"
                placeholder={
                  "" + t("form.placeholders.last_name", { ns: "settingsPage" })
                }
                required
                onChange={handleCoachChange}
                value={coachFormValues.last_name}
              />
            </InputWithSubtitle>
            {/* EMAIL */}
            <InputWithSubtitle>
              <label htmlFor="email" className="mt-4 mb-1">
                {t("form.placeholders.email", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="email"
                id="email"
                name="email"
                placeholder={
                  "" + t("form.placeholders.email", { ns: "settingsPage" })
                }
                required
                onChange={handleCoachChange}
                value={coachFormValues.email}
              />
            </InputWithSubtitle>
            {/* PHONE NUMBER */}
            <InputWithSubtitle>
              <label htmlFor="phone_number" className="mt-4 mb-1">
                {t("form.placeholders.phone_number", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="phone"
                id="phone_number"
                name="phone_number"
                placeholder={
                  "" +
                  t("form.placeholders.phone_number", { ns: "settingsPage" })
                }
                required
                onChange={handleCoachChange}
                value={coachFormValues.phone_number}
              />
            </InputWithSubtitle>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                text={
                  isUpdateCoachsPopupOpen
                    ? t("form.buttonUpdate", { ns: "settingsPage" })
                    : t("form.button", { ns: "settingsPage" })
                }
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsCreationCoachsPopupOpen(false);
                _setIsUpdateCoachsPopupOpen(false);
                _setCoachFormValues(initialCoachsFormState);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>
    </SettingsCoachsStyle>
  );
};

export default SettingsCoachs;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsCoachsStyle = styled.div`
  width: 100%;
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;

const CreationPopupContent = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 186, 186, 0.666);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
  }

  & > div > form > h3 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FormInput = styled.input`
  display: flex;
  padding: 10px 12px 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
  width: 400px;
`;

const CrossBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${LIGHT_GREY};

  :hover {
    color: ${GREY};
  }
`;
