import React from "react";
import styled from "styled-components";
import { BLACK, BLUE, WHITE } from "../constants/cts_colors";
import { MdArrowBackIosNew } from "react-icons/md";
const ArrowButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <ArrowButtonStyles onClick={onClick}>
      <div className="flex flex-row items-center justify-center pr-1">
        <MdArrowBackIosNew size={24} />
      </div>
    </ArrowButtonStyles>
  );
};

export default ArrowButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ArrowButtonStyles = styled.button`
  background-color: ${WHITE};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${WHITE};
  transition: background-color 250ms linear, color 250ms linear;

  div {
    color: ${BLACK};
  }

  :hover {
    background-color: rgba(0, 0, 0, 0);

    div {
      color: ${WHITE};
    }
  }
`;
