import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MdPlayCircleOutline } from "react-icons/md";
import { BLUE, CLEAR_BLUE, WHITE } from "../constants/cts_colors";

const PlayPodcastButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { t } = useTranslation();

  return (
    <PlayPodcastButtonStyles className="flex gap-2 flex-row justify-center items-center rounded-full">
      <div
        className="flex flex-row justify-center items-center gap-2 py-4"
        onClick={onClick}
      >
        <MdPlayCircleOutline size={24} />
        <p className="font-bold pt-1">{t("listen", { ns: "libraryPage" })}</p>
      </div>
    </PlayPodcastButtonStyles>
  );
};

export default PlayPodcastButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const PlayPodcastButtonStyles = styled.button`
  color: ${BLUE};
  border: 1px solid ${CLEAR_BLUE};
  background-color: ${CLEAR_BLUE};
  transition: background-color 250ms linear, color 250ms linear;

  :hover {
    color: ${WHITE};
    background-color: ${BLUE};
  }
`;
