import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  BLACK,
  BLUE,
  CLEAR_GREY,
  GREY,
  LIGHT_GREY,
  WHITE,
} from "../constants/cts_colors";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ICompaniesInfos } from "../interfaces/companies";
import edit_logo from "../assets/images/png/edit_logo.png";
import delete_logo from "../assets/images/png/delete_logo.png";
import { useTranslation } from "react-i18next";
import { useCompanies } from "../common/contexts/companyContext";
import { MdOutlineLock } from "react-icons/md";

const CompanyLine = ({
  company,
  popupOperations,
  numberOfFormations,
  onClick,
  onEdit,
  onDelete,
  onAccess,
}: {
  company: ICompaniesInfos;
  popupOperations: boolean;
  numberOfFormations: number;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onEdit?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onDelete?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onAccess?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const [isOperationsOpen, _setIsOperationsOpen] = useState<boolean>(false);
  const [numberOfMembers, _setNumberOfMembers] = useState<number>(0);
  const refOperationsPopup = useRef<HTMLDivElement>(null);

  const { onGetNumberOfMembers } = useCompanies();

  const { t } = useTranslation();

  // USE EFFECT FOR CLICK OUTSIDE OF GROUP POPUP
  useEffect(() => {
    function handleClickOperationsOutside(event: any) {
      if (
        refOperationsPopup.current &&
        !refOperationsPopup.current.contains(event.target)
      ) {
        _setIsOperationsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOperationsOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOperationsOutside);
    };
  }, [refOperationsPopup]);

  useEffect(() => {
    if (popupOperations) _setIsOperationsOpen(false);
  }, [popupOperations]);

  useEffect(() => {
    onGetNumberOfMembers(company.id)
      .then((response) => {
        _setNumberOfMembers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <CompanyStyles className="mx-28 my-4 gap-4" onClick={onClick}>
      <div
        className="flex flex-row items-center basis-12 gap-2 mr-2"
        style={{
          color: GREY,
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {/*<CheckboxCustom className="basis-1/12" type="checkbox"></CheckboxCustom>*/}
        <p className="basis-3/12" style={{ color: BLACK, fontWeight: 800 }}>
          {company.name}
        </p>
        <p className="basis-3/12">{numberOfMembers}</p>
        <p className="basis-3/12">{numberOfFormations}</p>
        <div className="basis-5/12 flex flex-row items-center justify-center gap-6">
          <DotsOperations
            className="p-3 rounded-lg"
            onClick={() => {
              _setIsOperationsOpen(true);
            }}
          >
            <BsThreeDotsVertical size={20} color={BLUE} />
          </DotsOperations>
        </div>
      </div>
      {/* OPERATIONS MODAL */}
      <OperationsStyles
        style={{
          display: isOperationsOpen
            ? popupOperations
              ? "block"
              : "none"
            : "none",
        }}
        ref={refOperationsPopup}
      >
        <div className="flex flex-row gap-2 items-center" onClick={onEdit}>
          <img src={edit_logo} width={20} />
          {t("edit", { ns: "settingsPage" })}
        </div>
        <div className="flex flex-row gap-2 items-center" onClick={onAccess}>
          <MdOutlineLock size={20} />
          {t("access", { ns: "settingsPage" })}
        </div>
        <div className="flex flex-row gap-2 items-center" onClick={onDelete}>
          <img src={delete_logo} width={20} />
          {t("delete", { ns: "settingsPage" })}
        </div>
      </OperationsStyles>
    </CompanyStyles>
  );
};

export default CompanyLine;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const CompanyStyles = styled.div`
  position: relative;
  padding: 12px 0px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${LIGHT_GREY};
  }
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const OperationsStyles = styled.div`
  z-index: 10;
  position: absolute;
  top: 34px;
  right: 13%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background-color: ${WHITE};
  border: 1px solid ${GREY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    :first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    :last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;

const DotsOperations = styled.div`
  background-color: ${CLEAR_GREY};

  :hover {
    background-color: ${GREY};
  }
`;
