import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  onLoginApi,
  onAutoLoginApi,
  onLogoutApi,
  onGetUserIfAuthenticatedApi,
  onLoginWitoutPasswordApi,
} from "../../api/authentication.api";
import { instanceAxios } from "../../utils/axios-api";
import { IAuthenticationContext } from "../../interfaces/authenticationContext";
import { AUTHENTICATION_MSG } from "../../constants/cts_contextErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import { useTranslation } from "react-i18next";
import { EMPTY_EMAIL, EMPTY_PASSWORD } from "../../constants/cts_formErrors";
import { TOKEN_USER_AUTHENTICATED } from "../../constants/cts_user";
import keycloak from "../../Keycloak";
import { IUserInfos } from "../../interfaces/user";
import { useNavigate } from "react-router-dom";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";

const AuthenticationContext = createContext<IAuthenticationContext | null>(
  null
);

// THE PROVIDER
export const AuthenticationProvider = (props: any) => {
  const { t } = useTranslation();
  const [user, _setUser] = useState<IUserInfos | null>(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [token, _setToken] = useState<string | null>(null);
  const [isLoading, _setIsLoading] = useState(false);
  const [isWaitingAutoLogin, _setIsWaitingAutoLogin] = useState(true);
  const [isWaitingAuthenticationResponse, _setIsWaitingAuthenticationResponse] =
    useState(true);
  const [kcInitialized, setKcInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [requestToAuth, setRequestToAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsUserAdmin(user && user.role === 1 ? true : false)
  }, [user])

  useEffect(() => {
    const getUserDetails = async () => {
      if (!kcInitialized) {
        try {
          const authenticated = await keycloak.init({
            onLoad: "check-sso",
          });

          console.log(
            `User is ${authenticated ? "authenticated" : "not authenticated"}`,
            authenticated
          );
          if (authenticated) {
            const user = await keycloak.loadUserInfo(); // loadUserProfile

            console.log("user", user);

            // @ts-ignore
            if (user && user.email) {
              // @ts-ignore
              await onLoginWitoutPassword({ email: user.email });

              const getStorage = localStorage.getItem('redirectTo')
              if(getStorage) {
                localStorage.removeItem('redirectTo')
                navigate(getStorage.replace(window.location.origin+'/', ''))
              }
            } else {
              onLogout();
            }
          }
        } catch (error) {
          console.error("Failed to initialize adapter:", error);
        }
      }
      setKcInitialized(true);
    };

    getUserDetails();
  }, []);

  useEffect(() => {
    console.log('salut', window.location.href)
    const getUserDetails = async () => {
      try {
        await keycloak.login({
          prompt: "login"
        });
        console.log('is logged')
      } catch (error) {
        console.log('login failed', window.location.href)
        console.error("Failed to initialize adapter:", error);
      }
    };

    if (requestToAuth) {
      getUserDetails();
    }
  }, [requestToAuth]);

  // on request to auth
  const onRequestoToAuth = useCallback(() => {
    setRequestToAuth(true);
  }, []);

  // login
  const onLoginWitoutPassword = useCallback(({ email }: { email: string }) => {
    if (!checkEmptyInput(email)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
      });
    }
    _setIsLoading(true);
    return onLoginWitoutPasswordApi({ email })
      .then((returnUser) => {
        console.log('user les clés', returnUser)
        _setUser(returnUser.user);
        _setToken(returnUser.token);
        instanceAxios.defaults.headers.common["Authorization"] = returnUser.token;
        _setIsLoading(false);
      })
      .catch((err) => {
        _setIsLoading(false);
        if (err.response) {
          throw new Error(err.response.data);
        } else {
          throw new Error(err.message);
        }
      });
  }, []);

  // login
  const onLogin = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(password)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_PASSWORD}`, { ns: "errors" }));
        });
      }
      _setIsLoading(true);
      return onLoginApi({ email, password })
        .then((returnUser) => {
          _setUser(returnUser.user);
          _setToken(returnUser.token);
          _setIsLoading(false);
        })
        .catch((err) => {
          _setIsLoading(false);
          if (err.response) {
            throw new Error(err.response.data);
          } else {
            throw new Error(err.message);
          }
        });
    },
    []
  );

  // auto login function
  const onAutoLogin = useCallback(async () => {
    const returnUser = await onAutoLoginApi();
    if (returnUser && returnUser.user) {
      _setUser(returnUser.user);
      _setToken(returnUser.token);
    }
    _setIsWaitingAutoLogin(false);
  }, []);

  // logout
  const onLogout = useCallback(() => {
    _setIsLoading(true);
    return onLogoutApi()
      .then((returnUser) => {
        _setIsLoading(false);
        _setUser(returnUser.user);
        _setToken(returnUser.token);
        setIsAuthenticated(false);
        localStorage.removeItem(TOKEN_USER_AUTHENTICATED);
        keycloak.logout({redirectUri: window.location.origin});
      })
      .catch((err) => {
        _setIsLoading(false);
        if (err.response) {
          throw new Error(err.response.data);
        } else {
          throw new Error(err.message);
        }
      });
  }, []);

  // set user token
  useEffect(() => {
    if (localStorage.getItem(TOKEN_USER_AUTHENTICATED)) {
      _setToken(localStorage.getItem(TOKEN_USER_AUTHENTICATED));
    }
  }, []);

  // if token found, auto login every time user changes the route
  useEffect(() => {
    if (token) {
      instanceAxios.defaults.headers.common["Authorization"] = token;
      localStorage.setItem(TOKEN_USER_AUTHENTICATED, token);
      setIsAuthenticated(true);
      //onAutoLogin();
    } else {
      setIsAuthenticated(false);
      instanceAxios.defaults.headers.common["Authorization"] = null;
    }
  }, [token]);

  // check if the user / admin is authenticated
  const onGetUserIfIsAuthenticated = useCallback(() => {
    const localStorageToken = localStorage.getItem(TOKEN_USER_AUTHENTICATED);
    if (localStorageToken) {
      const authenticatedToken = localStorageToken;
      instanceAxios.defaults.headers.common["Authorization"] =
        authenticatedToken;
    }
    return onGetUserIfAuthenticatedApi()
      .then((response) => {
        if (response.user) {
          return response.user;
        } else {
          return null;
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <AuthenticationContext.Provider
      {...props}
      value={{
        user,
        isUserAdmin,
        token,
        isLoading,
        isWaitingAutoLogin,
        isWaitingAuthenticationResponse,
        isAuthenticated,
        onLogin,
        onAutoLogin,
        onLogout,
        onGetUserIfIsAuthenticated,
        onRequestoToAuth,
      }}
    />
  );
};

export const useAuthentication = (): IAuthenticationContext => {
  const context = useContext(AuthenticationContext);
  if (!context) throw new Error(AUTHENTICATION_MSG);
  return context;
};
