import { instanceAxios } from "../utils/axios-api";

export const onCreateMemberApi = ({
  first_name,
  last_name,
  image,
  email,
  company,
  position,
  status,
  access_to_community,
  access_to_learning,
}: {
  first_name: string;
  last_name: string;
  image: any;
  email: string;
  company: string;
  position: string;
  status: boolean;
  access_to_community: boolean;
  access_to_learning: boolean;
}) => {
  const formData = new FormData();
  formData.append("image", image || "");
  formData.append("firstName", first_name || "");
  formData.append("lastName", last_name || "");
  formData.append("email", email || "");
  formData.append("company", company || "");
  formData.append("position", position || "");
  formData.append("status", String(status) || "");
  formData.append("access_to_community", String(access_to_community) || "");
  formData.append("access_to_learning", String(access_to_learning) || "");

  return instanceAxios
    .post("/users/create-account-by-admin", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllAdminsApi = () => {
  return instanceAxios
    .get(`/users/get-all-admins`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllMembersApi = () => {
  return instanceAxios
    .get(`/users/get-all-users`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllCommunityMembersApi = () => {
  return instanceAxios
    .get(`/users/get-all-community-users`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetMemberApi = (id: number) => {
  return instanceAxios
    .get(`/users/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteMemberApi = (id: number) => {
  return instanceAxios
    .put("/users/delete-account-by-admin/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onSwitchToAdminAMember = ({
  id,
  isAdmin,
}: {
  id: number;
  isAdmin: boolean;
}) => {
  return instanceAxios
    .put("/users/update-user-to-be-admin/" + id, { isAdmin })
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateMemberApi = ({
  id,
  first_name,
  image,
  last_name,
  email,
  company,
  position,
  status,
  access_to_community,
  access_to_learning,
}: {
  id: number;
  first_name: string;
  image: any;
  last_name: string;
  email: string;
  company: string;
  position: string;
  status: boolean;
  access_to_community: boolean;
  access_to_learning: boolean;
}) => {
  const formData = new FormData();
  formData.append("firstName", first_name || "");
  formData.append("image", image || "");
  formData.append("lastName", last_name || "");
  formData.append("email", email || "");
  formData.append("company", company || "");
  formData.append("position", position || "");
  formData.append("status", String(status) || "");
  formData.append("access_to_community", String(access_to_community) || "");
  formData.append("access_to_learning", String(access_to_learning) || "");

  return instanceAxios
    .put("/users/update-user-by-admin/" + id, formData)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetSearchMembersApi = (search: string) => {
  const formData = new FormData();

  formData.append("search", search || "");

  // console.log("\n\nFRONT API : ", search, "\n\n");

  return instanceAxios
    .post(`/users/search`, formData)
    .then((response) => (response.data ? response.data.data : null));
};
