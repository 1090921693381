import { instanceAxios } from "../utils/axios-api";

export const onGetMySettingsApi = async () => {
  return instanceAxios
    .get("/settings/get-my-settings")
    .then((response) => (response.data ? response.data.data : null));
};

export const onToggleMySettingApi = async (userSettingId: number) => {
  return instanceAxios
    .put("/settings/toggle-my-setting", {userSettingId})
    .then((response) => (response.data ? response.data.data : null));
};