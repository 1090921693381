import React from "react";
import styled from "styled-components";
import { WHITE, BLUE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";
import { IFormationsInfos } from "../interfaces/formations";

const LinkPill = ({
  formation,
  onClick,
}: {
  formation: IFormationsInfos;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <LinkPillStyles
      className="py-1 px-4 flex gap-3 items-center cursor-pointer"
      onClick={onClick}
    >
      <p>{formation.title}</p>
      <div className="flex items-center">
        <ImCross size={16} />
      </div>
    </LinkPillStyles>
  );
};

export default LinkPill;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const LinkPillStyles = styled.div`
  background-color: ${BLUE};
  border: 1px solid ${BLUE};
  transition: 250ms;
  color: ${WHITE};
  border-radius: 16px;

  :hover {
    background-color: ${WHITE};
    color: ${BLUE};
  }
`;
