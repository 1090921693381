import styled from "styled-components";
import { BLUE, CLEAR_BLUE, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import ProfileImage from "./ProfileImage";
import { ICommentsInfos } from "../interfaces/comments";
import { useLikes } from "../common/contexts/likeContext";
import { IUserInfos } from "../interfaces/user";
import { useEffect, useRef, useState } from "react";
import { useComments } from "../common/contexts/commentContext";
import { IoIosArrowRoundForward } from "react-icons/io";
import i18next from "i18next";
import { getTimeAgoTextByLanguage } from "../utils/translations";

const CommentComponent = ({
  user,
  comment,
  isSubComment,
  handleToggleCommentCommentInput,
  onSentComment,
}: {
  user: IUserInfos;
  comment: ICommentsInfos;
  isSubComment: boolean;
  handleToggleCommentCommentInput: Function;
  onSentComment: Function;
}) => {
  const { t } = useTranslation();
  const { onCreateLike, onGetAllLikesFromUser } = useLikes();

  const { onGetAllCommentsFromComment, onCreateComment } = useComments();
  const [subComments, _setSubComments] = useState<Array<ICommentsInfos>>([]);

  const [isCommentLiked, _setIsCommentLiked] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [commentInputShown, _setCommentInputShown] = useState<boolean>(false);

  const initialCommentFormState = {
    user_id: user.id,
    post_id: "",
    comment_id: comment.id,
    content: "",
  };

  const [commentFormValues, _setCommentFormValues] = useState(
    initialCommentFormState
  );

  // HANDLE COMMENT SUBMIT
  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onCreateComment({
      user_id: commentFormValues.user_id,
      post_id: commentFormValues.post_id,
      comment_id: commentFormValues.comment_id,
      content: commentFormValues.content,
    }).then(() => {
      _setCommentFormValues(initialCommentFormState);
      onSentComment(comment.post_id);
    });
  };

  // ON CHANGE COMMENT
  const handleCommentChange = (e: any) => {
    const { name, value } = e.target;
    _setCommentFormValues({
      ...commentFormValues,
      [name]: value,
    });
  };

  // LIKE COMMENT
  const handleLikeComment = (e: any) => {
    e.preventDefault();

    onCreateLike({
      user_id: user.id,
      post_id: null,
      comment_id: comment.id,
    }).then((resLike: any) => {
      _setIsCommentLiked(resLike.data.is_liked);
    });
  };

  // COMMENT COMMENT
  const handleToggleInputComment = (e: any) => {
    e.preventDefault();
    // console.log(inputRef);
    _setCommentInputShown(!commentInputShown);
    if (inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    onGetAllCommentsFromComment(comment.id).then((commentsRes: any) => {
      _setSubComments(commentsRes);
    });
  }, [comment]);

  useEffect(() => {
    onGetAllCommentsFromComment(comment.id).then((commentsRes: any) => {
      _setSubComments(commentsRes);
    });

    onGetAllLikesFromUser(user.id).then((likesRes: any) => {
      _setIsCommentLiked(
        likesRes.some((e: any) => e.comment_id === comment.id)
      );
    });
  }, []);

  return (
    <CommentDiv
      className={isSubComment ? "subComment flex flex-col" : "flex flex-col"}
    >
      <CommentStyles>
        <div style={{ minWidth: 56 }}>
          <ProfileImage
            src={
              comment.comment_user_image
                ? comment.comment_user_image
                : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.34264412.1707782400&semt=ais"
            }
          />
        </div>
        <div className="w-11/12">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <p className="font-bold text-base" style={{ color: BLUE }}>
                {comment.comment_user_firstName} {comment.comment_user_lastName}
              </p>
              <p className="">
                {getTimeAgoTextByLanguage({
                  date: new Date(comment.created_at),
                  lang: i18next.language,
                  t,
                  additionalText: t("post.itWas", { ns: "circlePage" }),
                })}
              </p>
            </div>
            {/* <DotsButton
              type="comment"
              onClick={() => {
                console.log("3 DOTS");
              }}
            /> */}
          </div>
          <div className="mt-1 text-left">
            <p dangerouslySetInnerHTML={{__html: comment.content}}></p>
          </div>
          <ActionsComment className="flex mt-2 gap-1">
            <button
              className={isCommentLiked ? "isLiked" : ""}
              onClick={(e) => {
                handleLikeComment(e);
              }}
            >
              {t("post.like", { ns: "circlePage" })}
            </button>
            •
            <button onClick={handleToggleInputComment}>
              {t("post.respond", { ns: "circlePage" })}
            </button>
          </ActionsComment>
          <div>
            {subComments.map((subComment, subKey) => {
              return (
                <SubComment key={subKey}>
                  <section>
                    <hr className="vertical" />
                    <hr className="horizontal" />
                  </section>
                  <CommentComponent
                    user={user}
                    comment={subComment}
                    key={subKey}
                    isSubComment={true}
                    handleToggleCommentCommentInput={
                      handleToggleCommentCommentInput
                    }
                    onSentComment={onSentComment}
                  />
                </SubComment>
              );
            })}
          </div>
        </div>
      </CommentStyles>
      <div
        className="mt-4"
        style={{ display: commentInputShown ? "block" : "none" }}
      >
        <CommentBox>
          <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.34264412.1707782400&semt=ais" />
          <input
            type="text"
            placeholder="Répondre à un commentaire"
            id="content"
            name="content"
            onChange={handleCommentChange}
            value={commentFormValues.content ? commentFormValues.content : ""}
            ref={inputRef}
          />
          <button onClick={handleCommentSubmit}>
            <IoIosArrowRoundForward size={18} />
          </button>
        </CommentBox>
      </div>
    </CommentDiv>
  );
};

export default CommentComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const CommentDiv = styled.div`
  width: 100%;
  background-color: ${CLEAR_BLUE};
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;

  &.subComment {
    margin-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

const CommentStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const SubComment = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  section {
    position: absolute;
    left: -72px;

    hr.vertical {
      position: absolute;
      top: -17px; //DYNAMIC ojfoziejfoiz ejf oizejf oze
      left: 28px;
      border-radius: 5px;
      border: 1px solid ${BLUE};
      height: 77px; //DYNAMIC AUSSI MAYBE
      width: 1px;
    }

    hr.horizontal {
      position: absolute;
      top: 43px; //DYNAMIC ojfoziejfoiz ejf oizejf oze
      left: 44px;
      border-radius: 5px;
      border: 1px solid ${BLUE};
      transform: rotate(90deg);
      height: 33px; //DYNAMIC AUSSI MAYBE
      width: 1px;
    }
  }
`;

const ActionsComment = styled.div`
  /* disable selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  & > button {
    cursor: pointer;

    :hover {
      color: ${BLUE};
    }
  }

  & > .isLiked {
    color: ${BLUE};
    font-weight: bold;
  }
`;

const CommentBox = styled.form`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  position: relative;

  & > img {
    position: absolute;
    width: 32px;
    left: 20px;
    top: 16px;
    border-radius: 50%;
  }

  & > input {
    border: 1px solid ${BLUE};
    width: 100%;
    border-radius: 96px;
    padding: 0px 64px;
    height: 64px;
    font-size: 16px;
    font-weight: bold;
  }

  & > button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 16px;
    background-color: ${BLUE};
    color: ${WHITE};
    border-radius: 50%;
    width: 32px;
    height: 32px;

    transition: all 25ms ease-in-out;

    :hover {
      border: 1px solid ${BLUE};
      background-color: ${WHITE};
      color: ${BLUE};
    }
  }
`;
