import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  BLACK,
  WHITE,
  GREY,
  BLUE,
  GREEN,
  LIGHT_GREY,
  CLEAR_GREY,
} from "../constants/cts_colors";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ICoachsInfos } from "../interfaces/coachs";

import edit_logo from "../assets/images/png/edit_logo.png";
import delete_logo from "../assets/images/png/delete_logo.png";
import { useTranslation } from "react-i18next";

const UserCoachLine = ({
  coach,
  popupOperations,
  onClick,
  onEdit,
  onDelete,
}: {
  coach?: ICoachsInfos;
  popupOperations: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onEdit?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onDelete?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const { t } = useTranslation();

  const [isOperationsOpen, _setIsOperationsOpen] = useState<boolean>(false);
  const refOperationsPopup = useRef<HTMLDivElement>(null);

  // USE EFFECT FOR CLICK OUTSIDE OF COACH POPUP
  useEffect(() => {
    function handleClickOperationsOutside(event: any) {
      if (
        refOperationsPopup.current &&
        !refOperationsPopup.current.contains(event.target)
      ) {
        _setIsOperationsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOperationsOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOperationsOutside);
    };
  }, [refOperationsPopup]);

  useEffect(() => {
    if (popupOperations) _setIsOperationsOpen(false);

    // onGetCompany(member.company).then((response) => {
    //   _setCompany(response);
    // });
  }, [popupOperations]);

  return (
    <UserCoachStyles className="mx-28 my-4 gap-4" onClick={onClick}>
      <div
        className="flex basis-12 items-center gap-2 mr-2"
        style={{
          color: GREY,
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {/*<div className="h-full basis-1/12 flex items-center justify-center">
          <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
      </div>*/}
        <div className="basis-3/12 font-bold flex items-center gap-3">
          <img
            src="https://www.pngkey.com/png/full/157-1579943_no-profile-picture-round.png"
            width={43}
            height={43}
            style={{ borderRadius: "50%" }}
          />
          <p style={{ color: BLACK }}>
            {coach?.first_name} {coach?.last_name}
          </p>
        </div>
        <p className="basis-3/12">{coach?.email}</p>
        <p className="basis-3/12">{coach?.phone_number}</p>
        <div className="basis-3/12 flex flex-row items-center gap-6">
          {/*<div className="flex flex-col justify-start items-center gap-2">
            <div
              style={{
                maxWidth: 100,
                minWidth: 100,
                backgroundColor: BLUE,
                color: WHITE,
              }}
              className="py-1 px-2 rounded-xl text-center"
            >
              TeachUp
            </div>
            <div
              style={{
                maxWidth: 100,
                minWidth: 100,
                backgroundColor: GREEN,
                color: WHITE,
              }}
              className="bg-green-100 py-1 px-2 rounded-xl text-center"
            >
              {t("community", { ns: "settingsPage" })}
            </div>
            </div>*/}
          <DotsOperations
            className="bg-red-100 p-3 rounded-lg"
            onClick={() => {
              _setIsOperationsOpen(true);
            }}
          >
            <BsThreeDotsVertical size={20} color={BLUE} />
          </DotsOperations>
        </div>
      </div>
      {/* OPERATIONS MODAL */}
      <OperationsStyles
        style={{
          display: isOperationsOpen
            ? popupOperations
              ? "block"
              : "none"
            : "none",
        }}
        ref={refOperationsPopup}
      >
        <div className="flex flex-row gap-2 items-center" onClick={onEdit}>
          <img src={edit_logo} width={20} />
          {t("edit", { ns: "settingsPage" })}
        </div>
        <div className="flex flex-row gap-2 items-center" onClick={onDelete}>
          <img src={delete_logo} width={20} />
          {t("delete", { ns: "settingsPage" })}
        </div>
      </OperationsStyles>
    </UserCoachStyles>
  );
};

export default UserCoachLine;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const UserCoachStyles = styled.div`
  position: relative;
  padding: 12px 0px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${LIGHT_GREY};
  }
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const DotsOperations = styled.div`
  background-color: ${CLEAR_GREY};

  :hover {
    background-color: ${GREY};
  }
`;

const OperationsStyles = styled.div`
  z-index: 10;
  position: absolute;
  top: 48px;
  right: 5%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background-color: ${WHITE};
  border: 1px solid ${GREY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    :first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    :last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;
