import styled from "styled-components";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MdCreate, MdDelete, MdPlayCircleOutline } from "react-icons/md";
import { IPostsInfos } from "../interfaces/posts";
import { useAuthentication } from "../common/contexts/authenticationContext";
import DotsButton from "./DotsButton";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";
import checkedIconPath from "../assets/images/png/circle_check.png";
import { useApp } from "../common/contexts/appContext";
import { MOBILE_WIDTH } from "../constants/cts_sizes";

const PodcastCard = ({
  podcast,
  onClick,
  size,
  titleSize,
  paragraphSize,
  borderSize,
  playSize,
  handleDeletePost,
  handleUpdatePost,
}: {
  podcast: IPostsInfos;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  size: string;
  titleSize: string;
  paragraphSize: string;
  borderSize: string;
  playSize: number;
  handleDeletePost?: Function;
  handleUpdatePost?: Function;
}) => {
  const { t } = useTranslation();
  const {isMobileView} = useApp();
  const { isUserAdmin } = useAuthentication();
  const [isHovered, _setIsHovered] = useState<boolean>(false);
  const [isMenuOpened, _setIsMenuOpened] = useState<boolean>(false);
  const [podcastBackground, _setPodcastBackground] = useState<string>("");
  const { onAnalyticsPodcastClick, onAnalyticsGetMyListeningsPercentagesForPodcast } = useAnalyticLogs();
  const [isListened, _setIsListened] = useState<boolean>(false);

  useEffect(() => {
    if (podcast) {
      const images = (podcast.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "image"
      );
      if (images.length) {
        _setPodcastBackground(images[0].url);
      }
    }
  }, [podcast]);

  const analyticsPodcastClick = () => {
    onAnalyticsPodcastClick(podcast.id)
      .then(() => {})
      .catch((error) => console.error(error))
  }

  // CHECK IF THE PODCAST IS ALREADY LISTENED
  useEffect(() => {
    onAnalyticsGetMyListeningsPercentagesForPodcast(podcast.id)
      .then((logs) => {
        const listeningsPercentages: number[] = []

        logs.forEach(log => {
          // convert the datas (the percentage) to float
          listeningsPercentages.push(parseFloat(log.datas))
        });

        const maxPercentage = Math.max(...listeningsPercentages)

        if (maxPercentage >= 0.8) {
          _setIsListened(true)
        }
      })
      .catch((error) => console.error(error))
  }, [])

  return (
    <PodcastCardStyles>
      <div
        className="relative cursor-pointer image dt-size-is-1-1"
        style={{
          backgroundImage: `url(${podcastBackground})`,
        }}
        onMouseEnter={() => {
          _setIsHovered(true);
        }}
        onMouseLeave={() => {
          _setIsHovered(false);
        }}
      >
        <PlayDiv
          onClick={(event) => {
            analyticsPodcastClick()
            if (onClick) {
              onClick(event)
            }
          }}
          className="absolute top-0 flex justify-center items-center"
          style={{
            visibility: isHovered ? "visible" : "hidden",
            borderRadius: borderSize,
          }}
        >
          <MdPlayCircleOutline size={playSize} color={WHITE} />
        </PlayDiv>

        {isUserAdmin && (
          <DotsArea>
            <DotsButton
              type="post"
              onClick={() => {
                _setIsMenuOpened(!isMenuOpened);
              }}
            />
            {/* 3 DOTS MENU */}
            <DotsMenu
              style={{ display: isMenuOpened ? "block" : "none" }}
              className="absolute rounded-xl"
            >
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleUpdatePost && handleUpdatePost(podcast.id);
                }}
              >
                <MdCreate size={24} />
                <p className="font-bold">
                  {t("post.update", { ns: "circlePage" })}
                </p>
              </button>
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleDeletePost && handleDeletePost(podcast.id);
                }}
              >
                <MdDelete size={24} />
                <p className="font-bold">
                  {t("post.delete", { ns: "circlePage" })}
                </p>
              </button>
            </DotsMenu>
          </DotsArea>
        )}
      </div>
      <h3
        className="font-bold mt-3 mb-1 pr-8"
        style={{ color: BLUE, fontSize: "16px" }}
      >
        {podcast.title}
      </h3>
      {
        isListened &&
        <IsListenedInfo>
          <img src={checkedIconPath} />
          <span>
            {t("post.read", { ns: "circlePage" })}
          </span>
        </IsListenedInfo>
      }
    </PodcastCardStyles>
  );
};

export default PodcastCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const PodcastCardStyles = styled.div`
  .image {
    position: relative;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1024px) {
      border-radius: 8px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      max-width: inherit;
      max-height: inherit;
    }
  }
`;

const PlayDiv = styled.div`
  background-color: rgba(61, 61, 234, 0.5);
  transition: all 250ms linear;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  opacity: 0;

  :hover {
    opacity: 1;
  }
`;

const DotsMenu = styled.div`
  background-color: ${WHITE};
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: 0;

  button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;

const DotsArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 6px;
  padding-top: 6px;
`;

const IsListenedInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  img {
    display: block;
  }

  span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: ${BLACK};
  }
`
