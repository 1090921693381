import React from "react";
import styled from "styled-components";
import { GREY, BLUE, LIGHT_GREY, CLEAR_GREY } from "../constants/cts_colors";
import { BsThreeDotsVertical } from "react-icons/bs";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const SearchAndAddContainer = ({
  onChange,
  onClick,
  showBtOptions = true,
  showBtAdd = true,
}: {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  showBtOptions?: boolean;
  showBtAdd?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="basis-4 flex flex-row mx-28 my-4 gap-4">
      <SearchBar
        type="text"
        placeholder={"" + t("search", { ns: "settingsPage" })}
        name="search"
        id="search"
        className="basis-3/4 m-2 rounded-lg p-2"
        onChange={onChange}
      />
      <div className="basis-1/4 basis-4 flex flex-row justify-evenly gap-4">
        {showBtAdd && (
          <div className="my-auto basis-3/4">
            <Button text={t("add", { ns: "settingsPage" })} onClick={onClick} />
          </div>
        )}
        {showBtOptions && (
          <div className="basis-1/4">
            <OptionsButton className="p-3 m-2 cursor-pointer rounded-lg flex flex-row items-center">
              <BsThreeDotsVertical size={20} color={BLUE} />
            </OptionsButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchAndAddContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SearchBar = styled.input`
  border: 1px solid ${GREY};
`;

const OptionsButton = styled.button`
  background-color: ${CLEAR_GREY};

  :hover {
    background-color: ${LIGHT_GREY};
  }
`;
