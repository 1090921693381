import styled from "styled-components";
import React, { useState } from "react";
import { BLUE, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddCircle,
  MdOutlineAddCircleOutline,
  MdOutlineRemoveCircle,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";

const TagPill = ({
  name,
  type,
  selectType,
  onClick,
  onDelete,
}: {
  name: string;
  type?: string;
  selectType?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();

  const [isHovered, _setIsHovered] = useState<boolean>();
  const [isBtnHovered, _setIsBtnHovered] = useState<boolean>();
  const [isDeleteHovered, _setIsDeleteHovered] = useState<boolean>();

  const tagStyle = {
    color: isHovered ? BLUE : WHITE,
    border: `1px solid ${BLUE}`,
    cursor: "pointer",
    backgroundColor: isHovered ? WHITE : BLUE,
  };

  return (
    <TagPillStyles
      className="flex flex-row gap-4 items-center justify-center py-2 px-4 lg:py-4 lg:px-6 rounded-full"
      style={selectType ? tagStyle : {}}
      onMouseEnter={() => {
        if (selectType) _setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (selectType) _setIsHovered(false);
      }}
    >
      <p className="lg:font-bold">{name}</p>
      <div
        className="flex"
        style={{ display: type || onDelete ? "flex" : "hidden" }}
      >
        {type && (
          <button
            className="m-auto cursor-pointer"
            onMouseEnter={() => {
              _setIsBtnHovered(true);
            }}
            onMouseLeave={() => {
              _setIsBtnHovered(false);
            }}
            onClick={onClick}
          >
            {isBtnHovered && type === "remove" && (
              <MdOutlineRemoveCircle size={24} />
            )}
            {!isBtnHovered && type === "remove" && (
              <MdOutlineRemoveCircleOutline size={24} />
            )}

            {isBtnHovered && type === "add" && <MdOutlineAddCircle size={24} />}
            {!isBtnHovered && type === "add" && (
              <MdOutlineAddCircleOutline size={24} />
            )}
          </button>
        )}

        {onDelete && (
          <button
            className="m-auto cursor-pointer"
            onMouseEnter={() => {
              _setIsDeleteHovered(true);
            }}
            onMouseLeave={() => {
              _setIsDeleteHovered(false);
            }}
            onClick={onDelete}
          >
            {isDeleteHovered && <AiOutlineDelete size={24} />}
            {!isDeleteHovered && <AiFillDelete size={24} />}
          </button>
        )}
      </div>
    </TagPillStyles>
  );
};

export default TagPill;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const TagPillStyles = styled.div`
  background-color: ${BLUE};
  color: ${WHITE};
`;
