import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { BLUE, COLOR_WHITE } from "../constants/cts_colors";

const MainContainer = ({
  about,
  children,
}: {
  about: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Container>
      <PositionRelativeTop>
        {" "}
        {/* this is the top position relative for about container */}
        <About>{about}</About>
      </PositionRelativeTop>
      <Children>
        {" "}
        {/* children components */}
        {children}
      </Children>
    </Container>
  );
};

export default MainContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  position: absolute;
  width: 60%;
  /* top: 10px; */
  left: 50%;
  /* display: flex; */
  background: rgba(256, 256, 256, 0.9);
  transform: translate(-50%);
  padding: 1rem;
  border-radius: 10px;
  border-radius: 4px;
  background: var(--Blanc, #fff);

  /* Card shadow big */
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);

  // ========= MEDIA QUERIES - Container ============
  @media (max-width: 720px) {
    width: 80%;
  }
`;

const PositionRelativeTop = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const About = styled.div`
  /* position: absolute; */
  /* top: -66px; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  padding: 1rem;
  background: ${BLUE};
  color: ${COLOR_WHITE};
  border-radius: 10px;
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);
`;

const Children = styled.div`
  /*  margin-top: 5rem; // give a margin top for all the children componentns from the main container */
`;
