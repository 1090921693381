import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import { checkEmptyInput } from "../../utils/checkInputs";
import { EMPTY_GROUP_NAME } from "../../constants/cts_formErrors";

import { IGroupsContext, IGroupsInfos } from "../../interfaces/groups";
import {
  onCreateGroupApi,
  onDeleteGroupApi,
  onGetAllGroupsApi,
  onGetGroupApi,
  onGetSearchGroupsApi,
  onUpdateGroupApi,
  onGetNumberOfMembersApi,
} from "../../api/group.api";

const GroupsContext = createContext(null);

export function GroupsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateGroup = useCallback(
    ({ name, members, formations }: IGroupsInfos) => {
      // console.log("\n\n--- FRONT --- \n members: ", members, "\n\n");

      if (!checkEmptyInput(name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_GROUP_NAME}`, { ns: "errors" }));
        });
      }

      // if (!checkEmptyInput(members)) {
      //   return new Promise((resolve, reject) => {
      //     reject(t(`form.${EMPTY_GROUP_MEMBERS}`, { ns: "errors" }));
      //   });
      // }

      // let finalMembersIds = [];

      // for (let i = 0; i < members.length; i++) {
      //   finalMembersIds.push(members[i]);
      // }

      // console.log("\n\n--- FRONT --- \n finalMembers: ", finalMembersIds, "\n\n");

      _setIsLoading(true);
      return onCreateGroupApi({
        name,
        members: members,
        formations: formations,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllGroups = useCallback(() => {
    _setIsLoading(true);
    return onGetAllGroupsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetGroup = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetGroupApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdateGroup = useCallback(
    ({
      id,
      name,
      members,
      formations,
    }: {
      id: number;
      name: string;
      members: Array<number>;
      formations: Array<number>;
    }) => {
      if (!checkEmptyInput(name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_GROUP_NAME}`, { ns: "errors" }));
        });
      }

      // if (members.length < 1) {
      //   return new Promise((resolve, reject) => {
      //     reject(t(`form.${EMPTY_GROUP_MEMBERS}`, { ns: "errors" }));
      //   });
      // }

      // console.log("CONTEXT : ", formations);

      _setIsLoading(true);
      return onUpdateGroupApi({
        id,
        name,
        members,
        formations,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteGroup = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteGroupApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetNumberOfMembers = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetNumberOfMembersApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetSearchGroups = useCallback((search: string) => {
    _setIsLoading(true);
    return onGetSearchGroupsApi(search)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <GroupsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateGroup,
        onGetAllGroups,
        onGetGroup,
        onUpdateGroup,
        onDeleteGroup,
        onGetSearchGroups,
        onGetNumberOfMembers,
      }}
    />
  );
}

export const useGroups = (): IGroupsContext => {
  const context = React.useContext(GroupsContext);
  if (!context) throw new Error("useGroups must be used in GroupProvider");

  return context;
};
