import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BLUE, LIGHT_GREY, GREY, WHITE } from "../constants/cts_colors";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { IMembersInfos } from "../interfaces/members";
import { useTranslation } from "react-i18next";
import UserMemberLine from "./UserMemberLine";
import { useMembers } from "../common/contexts/memberContext";
import Button from "./Button";
import { ImCross } from "react-icons/im";
import { useCompanies } from "../common/contexts/companyContext";
import { ICompaniesInfos } from "../interfaces/companies";
import { useAuthentication } from "../common/contexts/authenticationContext";

const SettingsMembers = ({}: {}) => {
  const { t } = useTranslation();
  const { isUserAdmin } = useAuthentication();
  const [tmpImage, setTmpImage] = useState();
  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  const initialMembersFormState = {
    image: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    position: "",
    status: true,
    access_to_community: true,
    access_to_learning: true,
  };

  const [memberFormValues, _setMemberFormValues] = useState(
    initialMembersFormState
  );

  const refCreationMembersPopup = useRef<HTMLDivElement>(null);

  const [isOperationPopupOpen, _setOperationPopupOpen] =
    useState<boolean>(true);

  const [isCreationMembersPopupOpen, _setIsCreationMembersPopupOpen] =
    useState<boolean>(false);
  const [isUpdateMembersPopupOpen, _setIsUpdateMembersPopupOpen] =
    useState<boolean>(false);

  const [members, _setMembers] = useState<Array<IMembersInfos>>([]);

  const {
    onCreateMember,
    onGetAllMembers,
    onGetMember,
    onDeleteMember,
    onUpdateMember,
    onGetSearchMembers,
  } = useMembers();

  const [idUpdateMember, _setIdUpdateMember] = useState<number>();

  const { onGetAllCompanies } = useCompanies();
  const [companies, _setCompanies] = useState<Array<ICompaniesInfos>>([]);

  // ***** MEMBERS FUNCTIONS *****
  // MEMBER CHANGE
  const handleMemberChange = (e: any) => {
    let { name, value, files } = e.target;
    if(files && files.length) {
      value = files[0]
    }
    if (value === "on" || value === "off") {
      // @ts-ignore
      value = !memberFormValues[name];
    }
    _setMemberFormValues({
      ...memberFormValues,
      [name]: value,
    });
  };

  // MEMBER SUBMIT
  const handleMemberSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isCreationMembersPopupOpen) {
      onCreateMember(memberFormValues)
        .then(() => {
          _setIsUpdateMembersPopupOpen(false);
          _setIsCreationMembersPopupOpen(false);

          _setMemberFormValues(initialMembersFormState);
          onGetAllMembers()
            .then((response: any) => {
              _setMembers(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    } else {
      onUpdateMember({
        id: idUpdateMember,
        ...memberFormValues,
      })
        .then(() => {
          _setIsUpdateMembersPopupOpen(false);
          _setIsCreationMembersPopupOpen(false);

          _setMemberFormValues(initialMembersFormState);
          onGetAllMembers()
            .then((response: any) => {
              _setMembers(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // MEMBER GET VALUES
  const getMemberValues = (id: number) => {
    onGetMember(id)
      .then((response) => {
        _setMemberFormValues({
          ...response,
          access_to_community:
            response.access_to_community === null
              ? true
              : response.access_to_community,
          access_to_learning:
            response.access_to_learning === null
              ? true
              : response.access_to_learning,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    onGetAllMembers()
      .then((response: any) => {
        _setMembers(response);
      })
      .catch((error: any) => alert(error));

    onGetAllCompanies()
      .then((response: any) => {
        _setCompanies(response);
      })
      .catch((error: any) => alert(error));
  }, []);

  // USE EFFECT FOR CLICK OUTSIDE OF MEMBERS POPUP
  useEffect(() => {
    function handleClickMembersOutside(event: any) {
      if (
        refCreationMembersPopup.current &&
        !refCreationMembersPopup.current.contains(event.target)
      ) {
        _setIsCreationMembersPopupOpen(false);
        _setIsUpdateMembersPopupOpen(false);
        _setMemberFormValues(initialMembersFormState);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickMembersOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickMembersOutside);
    };
  }, [refCreationMembersPopup]);

  // HANDLE DELETE MEMBER
  const handleDeleteMember = (
    id: number,
    first_name: string,
    last_name: string
  ) => {
    if (
      confirm(
        t("members.confirmDelete", { ns: "settingsPage" }) +
          first_name +
          " " +
          last_name +
          " ?"
      )
    ) {
      _setOperationPopupOpen(false);
      onDeleteMember(id)
        .then((response) => {
          onGetAllMembers()
            .then((response: any) => {
              _setMembers(response);
              _setOperationPopupOpen(true);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.log(error);
        });
      // _setIsOperationsOpen(false);
    }
  };

  //onChange FOR SEARCH INPUT
  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("SEARCH INPUT CHANGED ", value);

    if (value === "") {
      _setNoResultsSentence("");
      onGetAllMembers().then((response) => {
        _setMembers(response);
      });
    } else {
      onGetSearchMembers(value)
        .then((response) => {
          // console.log(response);
          if (response.length === 0) {
            _setNoResultsSentence(t("noResults", { ns: "search" }).toString());
            _setMembers(response);
          } else {
            _setMembers(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangeOrderBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let orderByMembers = Array<IMembersInfos>();
    switch (e.target.value) {
      case "first_name":
        orderByMembers = [...members].sort((a, b) =>
          a.first_name > b.first_name ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      case "last_name":
        orderByMembers = [...members].sort((a, b) =>
          a.last_name > b.last_name ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      case "status":
        orderByMembers = [...members].sort((a, b) =>
          a.status > b.status ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      case "email":
        orderByMembers = [...members].sort((a, b) =>
          a.email > b.email ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      case "company":
        orderByMembers = [...members].sort((a, b) =>
          a.company > b.company ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      case "position":
        orderByMembers = [...members].sort((a, b) =>
          a.position > b.position ? 1 : -1
        );

        _setMembers(orderByMembers);
        break;
      default: //all
        onGetAllMembers()
          .then((response) => {
            _setMembers(response);
          })
          .catch((error) => {
            console.log(error);
          });
        break;
    }
  };

  return (
    <SettingsMembersStyle>
      <SearchAndAddContainer
        onChange={handleChangeSearchInput}
        onClick={() => {
          _setIsCreationMembersPopupOpen(true);
        }}
        showBtOptions={false}
      />
      {/* ORDER BY */}
      <div className="mx-28 my-4 gap-4">
        {t("sortBy", { ns: "orderBy" })}
        <select
          name="orderBy-members"
          id="orderBy-members"
          className="rounded-lg border-2 cursor-pointer ml-2"
          onChange={handleChangeOrderBy}
          style={{
            padding: "5px 12px",
            borderColor: BLUE,
            color: BLUE,
          }}
        >
          <option value="all">{t("all", { ns: "orderBy" })}</option>
          <option value="first_name">
            {t("firstName", { ns: "orderBy" })}
          </option>
          <option value="last_name">{t("lastName", { ns: "orderBy" })}</option>
          <option value="status">{t("status", { ns: "orderBy" })}</option>
          <option value="email">{t("email", { ns: "orderBy" })}</option>
          <option value="company">{t("companies", { ns: "orderBy" })}</option>
          <option value="position">{t("position", { ns: "orderBy" })}</option>
        </select>
      </div>

      {/* HEADER OF MEMBERS */}
      <div
        className="mx-28 my-4"
        style={{ borderColor: LIGHT_GREY, borderBottomWidth: 1 }}
      >
        <div
          className="flex basis-12 items-center pt-3 pb-6"
          style={{
            color: GREY,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*<div className="h-full basis-1/12 flex items-center justify-center">
            <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
        </div>*/}
          <p className="basis-3/12">
            {t("members.user", { ns: "settingsPage" })}
          </p>
          <p className="basis-1/12">
            {t("members.status", { ns: "settingsPage" })}
          </p>
          <p className="basis-2/12 col-start-6">
            {t("members.email", { ns: "settingsPage" })}
          </p>
          <p className="basis-2/12 col-start-8">
            {t("members.company", { ns: "settingsPage" })}
          </p>
          <p className="basis-1/12">
            {t("members.poste", { ns: "settingsPage" })}
          </p>
          <p className="basis-1/12">
            {t("members.access", { ns: "settingsPage" })}
          </p>
          <p className="basis-2/12 text-center">
            {t("members.activity", { ns: "settingsPage" })}
          </p>
        </div>
      </div>

      <div className="overflow-scroll" style={{ height: "62vh" }}>
        {/* LIST OF MEMBERS */}
        {members &&
          members.map((member, key) => {
            return (
              <UserMemberLine
                key={key}
                member={member}
                company={member.company}
                popupOperations={isOperationPopupOpen}
                onEdit={() => {
                  getMemberValues(member.id);
                  _setIdUpdateMember(member.id);
                  _setIsUpdateMembersPopupOpen(true);
                }}
                onDelete={() => {
                  handleDeleteMember(
                    member.id,
                    member.first_name,
                    member.last_name
                  );
                }}
              />
            );
          })}

        {members && members.length < 1 && (
          <NoContentSentence className="w-full text-center">
            {t("members.noMembers", { ns: "settingsPage" })}
          </NoContentSentence>
        )}
      </div>

      {/* FORM CREATION FORM MEMBERS */}
      <CreationPopupContent
        style={{
          display:
            isCreationMembersPopupOpen || isUpdateMembersPopupOpen
              ? "block"
              : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-8 py-12 px-16"
          ref={refCreationMembersPopup}
        >
          <form onSubmit={handleMemberSubmit}>
            <h3>
              {isUpdateMembersPopupOpen &&
                t("form.nameMembersUpdate", { ns: "settingsPage" })}
              {isCreationMembersPopupOpen &&
                t("form.nameMembersCreation", { ns: "settingsPage" })}
            </h3>

            {/* FIRST NAME */}
            <InputWithSubtitle>
              <label htmlFor="image" className="mt-4 mb-1">
                {t("form.placeholders.image", {
                  ns: "settingsPage",
                })}
              </label>
              {memberFormValues.image && (
                <ImageFrame>
                  <img src={typeof memberFormValues.image === "object" ? URL.createObjectURL(memberFormValues.image) : memberFormValues.image} alt="profile" />
                </ImageFrame>
              )}
              <input
                type="file"
                id="profileImage"
                name="image"
                onChange={handleMemberChange}
                multiple={false}
              />
            </InputWithSubtitle>

            {/* FIRST NAME */}
            <InputWithSubtitle>
              <label htmlFor="first_name" className="mt-4 mb-1">
                {t("form.placeholders.first_name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="first_name"
                name="first_name"
                placeholder={
                  "" + t("form.placeholders.first_name", { ns: "settingsPage" })
                }
                required
                onChange={handleMemberChange}
                value={memberFormValues.first_name}
              />
            </InputWithSubtitle>

            {/* LAST NAME */}
            <InputWithSubtitle>
              <label htmlFor="last_name" className="mt-4 mb-1">
                {t("form.placeholders.last_name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="last_name"
                name="last_name"
                placeholder={
                  "" + t("form.placeholders.last_name", { ns: "settingsPage" })
                }
                required
                onChange={handleMemberChange}
                value={memberFormValues.last_name}
              />
            </InputWithSubtitle>
            {/* EMAIL */}
            <InputWithSubtitle>
              <label htmlFor="email" className="mt-4 mb-1">
                {t("form.placeholders.email", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="email"
                id="email"
                name="email"
                placeholder={
                  "" + t("form.placeholders.email", { ns: "settingsPage" })
                }
                required
                onChange={handleMemberChange}
                value={memberFormValues.email}
              />
            </InputWithSubtitle>

            {/* COMPANY */}
            <InputWithSubtitle>
              <label htmlFor="company" className="mt-4 mb-1">
                {t("form.placeholders.company", {
                  ns: "settingsPage",
                })}
              </label>
              <select
                id="company"
                name="company"
                onChange={handleMemberChange}
                value={memberFormValues.company}
                required
              >
                <option value={undefined}></option>
                {companies &&
                  companies.map((company, key) => {
                    return (
                      <option key={key} value={company.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </select>
            </InputWithSubtitle>

            {/* POSITION */}
            <InputWithSubtitle>
              <label htmlFor="position" className="mt-4 mb-1">
                {t("form.placeholders.position", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="position"
                name="position"
                placeholder={
                  "" + t("form.placeholders.position", { ns: "settingsPage" })
                }
                required
                onChange={handleMemberChange}
                value={memberFormValues.position}
              />
            </InputWithSubtitle>

            {/* ACCESS TO COMMUNITY */}
            <InputWithSubtitle>
              <label htmlFor="status" className="mt-4 mb-1">
                {t("form.labels.accessToCommunity", {
                  ns: "settingsPage",
                })}
              </label>
              <CheckboxCustom
                type="checkbox"
                id="status"
                name="access_to_community"
                onChange={handleMemberChange}
                checked={memberFormValues.access_to_community}
              />
            </InputWithSubtitle>

            {/* ACCESS TO LEARNING */}
            <InputWithSubtitle>
              <label htmlFor="status" className="mt-4 mb-1">
                {t("form.labels.accessToFormations", {
                  ns: "settingsPage",
                })}
              </label>
              <CheckboxCustom
                type="checkbox"
                id="status"
                name="access_to_learning"
                onChange={handleMemberChange}
                checked={memberFormValues.access_to_learning}
              />
            </InputWithSubtitle>

            {/* STATUS */}
            <InputWithSubtitle>
              <label htmlFor="status" className="mt-4 mb-1">
                {t("form.placeholders.status", {
                  ns: "settingsPage",
                })}
              </label>
              <CheckboxCustom
                type="checkbox"
                id="status"
                name="status"
                onChange={handleMemberChange}
                checked={memberFormValues.status}
              />
            </InputWithSubtitle>

            {/* BUTTONS CREATE */}
            <div className="mt-4 flex justify-center">
              <Button
                text={
                  isUpdateMembersPopupOpen
                    ? t("form.buttonUpdate", { ns: "settingsPage" })
                    : t("form.button", { ns: "settingsPage" })
                }
              />
            </div>

            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsCreationMembersPopupOpen(false);
                _setIsUpdateMembersPopupOpen(false);
                _setMemberFormValues(initialMembersFormState);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>
    </SettingsMembersStyle>
  );
};

export default SettingsMembers;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsMembersStyle = styled.div`
  width: 100%;
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;

const CreationPopupContent = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 186, 186, 0.666);

  & > div {
    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
  }

  & > div > form > h3 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > select {
    padding: 10px 12px 10px 16px;
    border-radius: 4px;
    background: ${WHITE};
    border-radius: 5px;
    border: 1px solid ${LIGHT_GREY};
    width: 400px;
  }
`;

const FormInput = styled.input`
  display: flex;
  padding: 10px 12px 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
  width: 400px;
`;

const CrossBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${LIGHT_GREY};

  :hover {
    color: ${GREY};
  }
`;

const ImageFrame = styled.div`
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
