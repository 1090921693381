import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import {
  checkEmptyInput,
  checkValidPhoneNumber,
} from "../../utils/checkInputs";
import {
  EMPTY_COACH_FIRST_NAME,
  EMPTY_COACH_LAST_NAME,
  EMPTY_COACH_EMAIL,
  EMPTY_COACH_PHONE_NUMBER,
} from "../../constants/cts_formErrors";

import { ICoachsInfos, ICoachsContext } from "../../interfaces/coachs";
import {
  onCreateCoachApi,
  onDeleteCoachApi,
  onGetAllCoachsApi,
  onGetCoachApi,
  onUpdateCoachApi,
  onGetSearchCoachsApi,
} from "../../api/coach.api";

const CoachsContext = createContext(null);

export function CoachsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateCoach = useCallback(
    ({ first_name, last_name, email, phone_number }: ICoachsInfos) => {
      if (!checkEmptyInput(first_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_FIRST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(last_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_LAST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkValidPhoneNumber(phone_number)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_PHONE_NUMBER}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onCreateCoachApi({
        first_name,
        last_name,
        email,
        phone_number,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllCoachs = useCallback(() => {
    _setIsLoading(true);
    return onGetAllCoachsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetCoach = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetCoachApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdateCoach = useCallback(
    ({
      id,
      first_name,
      last_name,
      email,
      phone_number,
    }: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
    }) => {
      if (!checkEmptyInput(first_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_FIRST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(last_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_LAST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkValidPhoneNumber(phone_number)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COACH_PHONE_NUMBER}`, { ns: "errors" }));
        });
      }
      _setIsLoading(true);
      return onUpdateCoachApi({
        id,
        first_name,
        last_name,
        email,
        phone_number,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteCoach = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteCoachApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetSearchCoachs = useCallback((search: string) => {
    _setIsLoading(true);
    return onGetSearchCoachsApi(search)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <CoachsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateCoach,
        onGetAllCoachs,
        onGetCoach,
        onUpdateCoach,
        onDeleteCoach,
        onGetSearchCoachs,
      }}
    />
  );
}

export const useCoachs = (): ICoachsContext => {
  const context = React.useContext(CoachsContext);
  if (!context) throw new Error("useCoachs must be used in CoachProvider");

  return context;
};
