export const getTimeAgoTextByLanguage = ({
  date,
  lang,
  t,
  additionalText,
}: {
  date: Date;
  lang: string;
  t: Function;
  additionalText?: string | null;
}) => {
  const currentTime = new Date().getTime();
  const givenTime = date.getTime();

  let secondsBetweenDates = Math.floor(currentTime - givenTime) / 1000;

  if (secondsBetweenDates < 0 && date) {
    return `Le ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  // get the correct conversion hour(s) / day(s) / month(s) / year(s)
  const getTimeIntervalAndUnit = () => {
    let interval = secondsBetweenDates / 31536000; // seconds / year
    if (interval >= 1) {
      const floorInterval = Math.floor(interval);
      return {
        interval: floorInterval,
        unit:
          floorInterval > 1
            ? t("years", { ns: "timeUnits" })
            : t("year", { ns: "timeUnits" }),
      };
    }

    interval = secondsBetweenDates / 2592000; // seconds / month
    if (interval >= 1) {
      const floorInterval = Math.floor(interval);
      return {
        interval: floorInterval,
        unit:
          floorInterval > 1
            ? t("months", { ns: "timeUnits" })
            : t("month", { ns: "timeUnits" }),
      };
    }

    interval = secondsBetweenDates / 86400; // seconds / day
    if (interval >= 1) {
      const floorInterval = Math.floor(interval);
      return {
        interval: floorInterval,
        unit:
          floorInterval > 1
            ? t("days", { ns: "timeUnits" })
            : t("day", { ns: "timeUnits" }),
      };
    }

    interval = secondsBetweenDates / 3600; // seconds / hour
    if (interval >= 1) {
      const floorInterval = Math.floor(interval);
      return {
        interval: floorInterval,
        unit:
          floorInterval > 1
            ? t("hours", { ns: "timeUnits" })
            : t("hour", { ns: "timeUnits" }),
      };
    }

    interval = secondsBetweenDates / 60; // seconds / minute
    if (interval > 1) {
      const floorInterval = Math.floor(interval);
      return {
        interval: floorInterval,
        unit:
          floorInterval > 1
            ? t("minutes", { ns: "timeUnits" })
            : t("minute", { ns: "timeUnits" }),
      };
    }

    const floorInterval = Math.floor(interval);
    return {
      interval: floorInterval,
      unit:
        floorInterval > 1
          ? t("seconds", { ns: "timeUnits" })
          : t("second", { ns: "timeUnits" }),
    };
  };

  const { interval, unit } = getTimeIntervalAndUnit();
  const intervalAndUnit = interval + " " + unit;
  let response = intervalAndUnit;

  // toggle left / right additional text
  if (additionalText) {
    switch (lang) {
      case "en":
        response = intervalAndUnit + " " + additionalText;
        break;
      case "fr":
        response = additionalText + " " + intervalAndUnit;
        break;
    }
  }

  return response;
};
