import styled from "styled-components";
import { BLUE, WHITE } from "../constants/cts_colors";
import { IPostsInfos } from "../interfaces/posts";
import { useTranslation } from "react-i18next";
import PodcastLittleCard from "./PodcastLittleCard";
import PATH from "../constants/cts_routes";
import { Link } from "react-router-dom";
import { getPageUrl } from "../locales/i18n";
import ResourceLittleCard from "./ResourceLittleCard";

const DashboardPodcasts = ({
  podcasts,
  resources,
}: {
  podcasts: Array<IPostsInfos>;
  resources: Array<IPostsInfos>;
}) => {
  const { t } = useTranslation();

  return (
    <DashboardPodcastsStyles className="flex flex-col gap-8 lg:flex-row">
      {/* TEXTS */}
      <div className="flex flex-col justify-between gap-4 lg:gap-10 basis-1/2">
        <div className="">
          <p className="font-semibold mb-2">
            {t("podcasts.title", { ns: "dashboardPage" })}
          </p>
          <h3 className="font-black text-3xl lg:text-4xl">
            {t("podcasts.description", { ns: "dashboardPage" })}
          </h3>
        </div>
        <div className="">
          <Link to={getPageUrl(PATH.library)}>
            <SeeAllButton className="font-bold py-4 px-8 rounded-full w-full lg:w-fit cursor-pointer">
              {t("podcasts.button", { ns: "dashboardPage" })}
            </SeeAllButton>
          </Link>
        </div>
      </div>
      {/* 3 LASTS PODCASTS */}
      <div className="flex flex-row flex-wrap lg:flex-nowrap justify-evenly items-center gap-6 basis-1/2">
        {podcasts.map((podcast, key) => {
          return (
            <Link to={{
              pathname: getPageUrl(PATH.library),
              search: `?id=${podcast.id}`,
            }} key={key}>
              <PodcastLittleCard podcast={podcast} onClick={() => {}} />
            </Link>
          );
        })}
        {resources.map((resource, key) => {
          return (
            <Link
              to={{
                pathname: getPageUrl(PATH.library),
                search: `?t=resources&id=${resource.id}`,
              }}
              key={key}
            >
              <ResourceLittleCard resource={resource} />
            </Link>
          );
        })}
      </div>
    </DashboardPodcastsStyles>
  );
};

export default DashboardPodcasts;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const DashboardPodcastsStyles = styled.div`
  border-radius: 16px;
  background-color: ${BLUE};
  color: ${WHITE};
  padding: 32px;
  justify-content: space-between;
`;

const SeeAllButton = styled.div`
  background-color: ${WHITE};
  color: ${BLUE};
  border: 1px solid ${WHITE};
  transition: all 250ms;
  text-align: center;

  :hover {
    background-color: ${BLUE};
    color: ${WHITE};
  }
`;
