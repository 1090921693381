import React, { useState } from "react";
import styled from "styled-components";
import { BLACK, GREY, RED, WHITE } from "../constants/cts_colors";
import french_logo from "../assets/images/png/french_logo.png";
import english_logo from "../assets/images/png/english_logo.png";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";
import { MOBILE_WIDTH } from "../constants/cts_sizes";

const FormationCard = ({
  formationId,
  title,
  description,
  language,
  image,
  onClick,
}: {
  formationId: number;
  title: string;
  description?: string;
  language: string;
  image: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const [isCardHovered, _setIsCardHovered] = useState<boolean>(false);
  const { onAnalyticsFormationClick } = useAnalyticLogs();

  const analyticsFormationClick = () => {
    onAnalyticsFormationClick(formationId)
      .then(() => {})
      .catch((error) => console.error(error))
  }

  return (
    <FormationCardStyles
      style={{
        backgroundImage: "url(" + image + ")",
      }}
      onClick={(event) => {
        analyticsFormationClick()
        if (onClick) {
          onClick(event)
        }
      }}
      onMouseEnter={() => {
        _setIsCardHovered(true);
      }}
      onMouseLeave={() => {
        _setIsCardHovered(false);
      }}
    >
      <BlackHover style={{ opacity: isCardHovered ? 0.75 : 0.6 }}></BlackHover>
      <ContentCard className="flex flex-col basis-2 p-3">
        <div className="w-full basis-1/2" style={{ zIndex: 10 }}>
          <img
            src={language === "french" ? french_logo : english_logo}
            width={28}
          />
        </div>
        <div className="w-full font-bold break-keep flex flex-col justify-end basis-1/2">
          <h2 style={{ fontSize: 20, zIndex: 10 }}>{title}</h2>
          {description && <p>{description}</p>}
        </div>
      </ContentCard>
    </FormationCardStyles>
  );
};

export default FormationCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const FormationCardStyles = styled.div`
  width: 75%;
  max-width: 800px;
  height: 250px;
  border-radius: 8px;
  color: ${WHITE};
  cursor: pointer;
  transition: 250ms;
  background-size: cover;
  background-position: center;
  position: relative;

  & > img {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    max-width: 100%;
    width: calc(100% - 48px);
    margin: 16px 24px;
  }
`;

const ContentCard = styled.div`
  height: 100%;
`;

const BlackHover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 8px;

  transition: 250ms;
  background: linear-gradient(#00000000, ${BLACK});
`;

const DeleteButton = styled.button`
  z-index: 10;
  color: ${GREY};

  :hover {
    color: ${RED};
  }
`;
