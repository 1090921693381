import styled from "styled-components";
import React, { useState } from "react";
import { BLUE, WHITE } from "../constants/cts_colors";
import { ImCross } from "react-icons/im";

const SelectTagPill = ({
  name,
  onClick,
}: {
  name: string;
  onClick: Function;
}) => {
  const [isHovered, _setIsHovered] = useState<boolean>();
  const [isSelected, _setIsSelected] = useState<boolean>(false);

  const selectedTagStyle = {
    color: isHovered ? BLUE : WHITE,
    border: `1px solid ${BLUE}`,
    cursor: "pointer",
    backgroundColor: isHovered ? WHITE : BLUE,
  };

  const idleTagStyle = {
    color: isHovered ? WHITE : BLUE,
    border: `1px solid ${BLUE}`,
    cursor: "pointer",
    backgroundColor: isHovered ? BLUE : WHITE,
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();

    _setIsSelected(!isSelected);

    onClick();
  };

  return (
    <TagPillStyles
      className="flex flex-row gap-4 items-center justify-center py-2 px-4 lg:py-4 lg:px-6 rounded-full"
      style={idleTagStyle}
      onMouseEnter={() => {
        _setIsHovered(true);
      }}
      onMouseLeave={() => {
        _setIsHovered(false);
      }}
      onClick={handleOnClick}
    >
      <p className="lg:font-bold">{name}</p>
      {isSelected && <ImCross size={14} />}
    </TagPillStyles>
  );
};

export default SelectTagPill;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const TagPillStyles = styled.div`
  background-color: ${BLUE};
  color: ${WHITE};
`;
