import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PATH from "../../constants/cts_routes";
import { useTranslation } from "react-i18next";
import { getPageUrl } from "../../locales/i18n";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../../constants/cts_colors";
import logo from "../../assets/images/logo/logo.png";
import Button from "../../components/Button";
import FooterLesCles from "../../components/FooterLesCles";
import i18next from "i18next";

const LoginPage = () => {
  const { t } = useTranslation();
  const { onRequestoToAuth } = useAuthentication();
  const [redirectToHome, _setRedirectToHome] = useState(false);

  useEffect(() => {
    document.title = t("title", { ns: "loginPage" });
  }, [i18next.language]);

  return (
    <Wrapper>
      <Container>
        <LogoContainer className="mb-8 flex flex-row w-full justify-start">
          <img src={logo} alt="Logo Les Clefs" width={240} />
        </LogoContainer>
        <Panel>
          <FormTitle className="font-bold">
            {" "}
            {t("description", { ns: "loginPage" })}{" "}
          </FormTitle>
          <Button
            text={t("mainTitle", { ns: "loginPage" })}
            onClick={onRequestoToAuth}
          />
          <div className="mb-1 mt-4 flex flex-row justify-between items-center w-full">
            <p>{t("form.placeholders.password", { ns: "loginPage" })}</p>
            <ForgotPasswordText className="cursor-pointer">
              <Link to={getPageUrl(PATH.lost_pwd)}>
                {t("form.links.lostPassword", { ns: "loginPage" })}
              </Link>
            </ForgotPasswordText>
          </div>
        </Panel>

        <FooterLesCles />
      </Container>
    </Wrapper>
  );

  /*return (
    <Wrapper>
      <Container>
        <LogoContainer className="mb-8 flex flex-row w-full justify-start">
          <img src={logo} alt="Logo Les Clefs" width={240} />
        </LogoContainer>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 68,
            paddingRight: 68,
            paddingTop: 48,
            paddingBottom: 48,
          }}
          onSubmit={handleSubmit}
        >
          <FormTitle className="font-bold">
            {" "}
            {t("description", { ns: "loginPage" })}{" "}
          </FormTitle>
          <InputWithSubtitle>
            <p className="mb-1">
              {t("form.placeholders.email", { ns: "loginPage" })}
            </p>
            <FormInput
              type="email"
              id="email"
              name="email"
              placeholder={
                "" + t("form.placeholders.email", { ns: "loginPage" })
              }
              required
              onChange={handleChange}
              style={{ width: 400 }}
            />
          </InputWithSubtitle>

          <InputWithSubtitle className="my-8">
            <div className="mb-1 flex flex-row justify-between items-center w-full">
              <p>{t("form.placeholders.password", { ns: "loginPage" })}</p>
              <ForgotPasswordText className="cursor-pointer">
                <Link to={getPageUrl(PATH.lost_pwd)}>
                  {t("form.links.lostPassword", { ns: "loginPage" })}
                </Link>
              </ForgotPasswordText>
            </div>

            <FormInput
              type="password"
              id="password"
              name="password"
              placeholder={
                "" + t("form.placeholders.password", { ns: "loginPage" })
              }
              minLength={6}
              required
              onChange={handleChange}
              style={{ width: 400 }}
            />
          </InputWithSubtitle>
          <Button
            text={t("mainTitle", { ns: "loginPage" })}
            onClick={handleSubmit}
          />
        </Form>

        <FooterLesCles />
      </Container>
    </Wrapper>
  );*/
};

export default LoginPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1260px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const LogoContainer = styled.div`
  padding-left: 20px;
`;

const Panel = styled.div`
  border-radius: 4px;
  background: ${WHITE};
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;
  padding-left: 68px;
  padding-right: 68px;
  padding-top: 48px;
  padding-bottom: 48px;
`;

const FormTitle = styled.p`
  color: ${BLACK};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
`;

const FormInput = styled.input`
  display: flex;
  padding: 13.5px 12px 13.5px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
`;

const ForgotPasswordText = styled.div`
  color: ${BLUE};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
