import { instanceAxios } from "../utils/axios-api";

export const onCreatePostApi = ({
  user_id,
  title,
  content,
  files,
  type,
  tags,
  date_to_publish,
}: {
  user_id: number;
  title: string;
  content: string;
  files: Array<File>;
  type: string;
  tags: Array<number>;
  date_to_publish: Date;
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString());
  formData.append("title", title || "");
  formData.append("content", content || "");
  formData.append("type", type || "");
  formData.append(
    "date_to_publish",
    (date_to_publish || new Date()).toString()
  );

  (files || []).map((file) => {
    formData.append("files", file);
  });

  (tags || []).map((tag: any) => {
    formData.append("tags", tag);
  });

  return instanceAxios
    .post("/posts/create-post", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllPostsApi = () => {
  return instanceAxios
    .get(`/posts/get-all-posts`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllPodcastsApi = () => {
  return instanceAxios
    .get(`/posts/get-all-podcasts`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetRecentsPodcastsApi = () => {
  return instanceAxios
    .get(`/posts/get-recents-podcasts`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetRecentsResourcesApi = () => {
  return instanceAxios
    .get(`/posts/get-recents-resources`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllResourcesApi = () => {
  return instanceAxios
    .get(`/posts/get-all-resources`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetPostApi = (id: number) => {
  return instanceAxios
    .get(`/posts/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetLastPostApi = () => {
  return instanceAxios
    .get(`/posts/get-last-post`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeletePostApi = (id: number) => {
  return instanceAxios
    .put(`/posts/delete-post/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdatePostApi = ({
  id,
  user_id,
  title,
  content,
  files,
  type,
  tags,
  date_to_publish,
}: {
  id: number;
  user_id: number;
  title: string;
  content: string;
  files: Array<any>;
  type: string;
  tags: Array<number>;
  date_to_publish: Date;
}) => {
  const formData = new FormData();
  formData.append("id", id.toString());
  formData.append("user_id", user_id.toString());
  formData.append("title", title || "");
  formData.append("content", content || "");
  formData.append("type", type || "");
  formData.append(
    "date_to_publish",
    (date_to_publish || new Date()).toString()
  );

  (files || []).map((file) => {
    formData.append("files", file.id ? JSON.stringify(file) : file);
  });

  (tags || []).map((tag: any) => {
    formData.append("tags", tag);
  });

  return instanceAxios
    .put("/posts/update-post/" + id, formData)
    .then((response) => (response.data ? response.data.data : null));
};
