import React from "react";
import styled from "styled-components";
import { BLUE, LIGHT_BLUE, CLEAR_BLUE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { AiOutlineComment } from "react-icons/ai";

const CommentPostButton = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <CommentPostButtonStyles onClick={onClick}>
      <div>
        <AiOutlineComment size={22} />
      </div>
      <p>{t("post.comment", { ns: "circlePage" })}</p>
    </CommentPostButtonStyles>
  );
};

export default CommentPostButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const CommentPostButtonStyles = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: ${CLEAR_BLUE};
  border-radius: 8px;

  &.is-liked {
    color: ${BLUE};
  }

  & > p {
    font-weight: bold;
  }

  :hover {
    color: ${BLUE};
    background-color: ${LIGHT_BLUE};
  }
`;
