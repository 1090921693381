import { useTranslation } from "react-i18next";
import styled from "styled-components";
import TopMenu from "../../components/TopMenu";
import { useFavorites } from "../../common/contexts/favoriteContext";
import { useEffect, useState } from "react";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { IPostsInfos } from "../../interfaces/posts";
import PodcastCard from "../../components/PodcastCard";
import FullPodcastPreview from "../../components/FullPodcastPreview";
import { IoHeartDislike } from "react-icons/io5";
import { GREY, LIGHT_GREY } from "../../constants/cts_colors";
import ResourceCard from "../../components/ResourceCard";
import FullResourcePreview from "../../components/FullResourcePreview";

const FavoritePage = () => {
  const { t } = useTranslation();
  const { user } = useAuthentication();

  const { onGetAllFavoritesFromUser } = useFavorites();

  const menuSections = {
    podcasts: "podcastsFavoris",
    resources: "resourcesFavoris",
  };
  const [selectedSection, _setSelectedSection] = useState<string>(
    menuSections.podcasts
  );

  // --- RESOURCES ---
  const [resources, _setResources] = useState<Array<IPostsInfos>>([]);
  const [resourcePreview, _setResourcePreview] = useState<IPostsInfos>();
  const [resourceIndex, setResourceIndex] = useState<number>(-1);

  // --- PODCASTS ---
  const [podcasts, _setPodcasts] = useState<Array<IPostsInfos>>([]);
  const [podcastPreview, _setPodcastPreview] = useState<IPostsInfos>();
  const [podcastIndex, setPodcastIndex] = useState<number>(-1);

  useEffect(() => {
    if (podcastPreview) {
      setPodcastIndex(podcasts.findIndex((p) => p.id === podcastPreview.id));
    }
  }, [podcastPreview]);

  useEffect(() => {
    if (resourcePreview) {
      setResourceIndex(resources.findIndex((r) => r.id === resourcePreview.id));
    }
  }, [resourcePreview]);

  useEffect(() => {
    if (user) {
      onGetAllFavoritesFromUser(user.id).then((response: any) => {
        console.log(" ALL FAVORITES ", response);
        _setPodcasts(response.podcasts);
        _setResources(response.resources);
      });
    }
  }, []);

  return (
    <Wrapper>
      {/* TOP MENU */}
      <TopMenu
        sections={[menuSections.podcasts, menuSections.resources]}
        selectedSection={selectedSection}
        fromPage="favoritesPage"
        onClick={_setSelectedSection}
      />
      {menuSections.podcasts === selectedSection && (
        <div className="m-8 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-x-8 gap-y-8">
          {podcasts.length === 0 && (
            <div className="col-span-3 h-full flex justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <IoHeartDislike size={114} color={GREY} opacity={0.5} />
                <p className="font-bold text-xl pl-4" style={{ color: GREY }}>
                  Aucun podcasts.
                </p>
              </div>
            </div>
          )}
          {podcasts &&
            podcasts.map((podcast, key) => {
              return (
                <PodcastCard
                  key={key}
                  podcast={podcast}
                  size="25vw"
                  titleSize="24px"
                  paragraphSize="16px"
                  borderSize="16px"
                  playSize={56}
                  onClick={() => {
                    _setPodcastPreview(podcast);
                  }}
                  // handleDeletePost={handlePostDelete}
                  // handleUpdatePost={handleUpdatePost}
                />
              );
            })}

          {podcastPreview && (
            <FullPodcastPreview
              podcast={podcastPreview}
              isPodcastPreviewOpen={podcastPreview ? true : false}
              onClick={() => {
                _setPodcastPreview(undefined);
              }}
              onReadPrevious={
                podcastIndex <= 0
                  ? null
                  : () => {
                      _setPodcastPreview(podcasts[podcastIndex - 1]);
                    }
              }
              onReadNext={
                podcastIndex === -1 || podcastIndex + 2 > podcasts.length
                  ? null
                  : () => {
                      _setPodcastPreview(podcasts[podcastIndex + 1]);
                    }
              }
            />
          )}
        </div>
      )}
      {menuSections.resources === selectedSection && (
        <div>
          {/* RESOURCES LIST */}
          <div className="m-8 flex flex-col grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-8">
            {resources.length === 0 && (
              <div className="col-span-3 h-full flex justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                  <IoHeartDislike size={114} color={GREY} opacity={0.5} />
                  <p className="font-bold text-xl pl-4" style={{ color: GREY }}>
                    Aucune ressource.
                  </p>
                </div>
              </div>
            )}
            {resources &&
              resources.map((resource, key) => {
                return (
                  <ResourceCard
                    key={key}
                    resource={resource}
                    titleSize="24px"
                    onClick={() => {
                      _setResourcePreview(resource);
                    }}
                    handleDeletePost={() => {}}
                    handleUpdatePost={() => {}}
                  />
                );
              })}
          </div>

          {resourcePreview && (
            <FullResourcePreview
              resource={resourcePreview}
              isPodcastPreviewOpen={resourcePreview ? true : false}
              onClick={() => {
                _setResourcePreview(undefined);
              }}
              onReadPrevious={
                resourceIndex <= 0
                  ? null
                  : () => {
                      _setResourcePreview(resources[resourceIndex - 1]);
                    }
              }
              onReadNext={
                resourceIndex === -1 || resourceIndex + 2 > resources.length
                  ? null
                  : () => {
                      _setResourcePreview(resources[resourceIndex + 1]);
                    }
              }
            />
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default FavoritePage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div``;
