import styled from "styled-components";
import { BLACK, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import ArrowWithTextButton from "./ArrowWithTextButton";
import { IPostsInfos } from "../interfaces/posts";
import { useEffect, useState } from "react";
import AudioPlayer from "./AudioPlayer";
import ReactQuill from "react-quill";
import PlayPodcastButton from "./PlayPodcastButton";
import ArrowButton from "./ArrowButton";
import FavoriteButton from "./FavoriteButton";
import { useFavorites } from "../common/contexts/favoriteContext";
import { useAuthentication } from "../common/contexts/authenticationContext";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";

const FullPodcastPreview = ({
  podcast,
  isPodcastPreviewOpen,
  onClick,
  onReadPrevious,
  onReadNext,
}: {
  podcast: IPostsInfos;
  isPodcastPreviewOpen: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onReadPrevious?: Function | null;
  onReadNext?: Function | null;
}) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();

  const [backgroundImage, _setImageBackground] = useState<string>("");
  const [isFavorited, _setIsFavorited] = useState<boolean>(false);
  const [audio, _setAudio] = useState<string>("");

  const { onCheckIfUserFavoritedAType, onSetFavorite } = useFavorites();

  const [startAudio, _setStartAudio] = useState<boolean>(false);
  const [listeningPercentage, _setListeningPercentage] = useState<number>(0);
  const [listeningMinutes, _setListeningMinutes] = useState<number>(0);
  const {
    onAnalyticsPodcastListeningPercentage,
    onAnalyticsPodcastListeningMinutes,
  } = useAnalyticLogs();

  useEffect(() => {
    if (podcast) {
      onCheckIfUserFavoritedAType({
        user_id: user.id,
        type: "post",
        type_id: podcast.id,
      }).then((response: any) => {
        _setIsFavorited(response);
      });

      const audios = (podcast.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "audio"
      );
      if (audios.length) {
        _setAudio(audios[0].url);
      }

      const images = (podcast.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "image"
      );
      if (images.length > 1) {
        _setImageBackground(images[1].url);
      } else if (images.length) {
        _setImageBackground(images[0].url);
      }
    }
  }, [podcast]);

  const handleSetFavorite = () => {
    _setIsFavorited(!isFavorited);
    onSetFavorite({
      user_id: user.id,
      post_id: podcast.id,
      formation_id: null,
    }).catch((error) => {
      console.log(error);
    });
  };

  // ANALYTICS PODCAST LISTENING PERCENTAGE
  const analyticsPodcastListening = () => {
    if (listeningPercentage > 0) {
      onAnalyticsPodcastListeningPercentage({podcastId: podcast.id, listeningPercentage})
        .then(() => {})
        .catch((error) => console.error(error))
      onAnalyticsPodcastListeningMinutes({podcastId: podcast.id, listeningMinutes})
        .then(() => {})
        .catch((error) => console.error(error))
    }
  }

  return (
    <FullPodcastPreviewStyles
      className="flex w-full top-0 left-0"
      style={{ visibility: isPodcastPreviewOpen ? "visible" : "hidden" }}
    >
      {/* LEFT ARROW */}
      <div
        className="hidden md:flex md:basis-1/6 items-center justify-center"
        style={{ color: WHITE }}
      >
        {onReadPrevious && (
          <ArrowButton
            onClick={() => {
              analyticsPodcastListening()
              _setStartAudio(false);
              onReadPrevious();
            }}
          />
        )}
      </div>
      {/* CONTENT */}
      <div className="podcast-main-preview">
        <PodcastPreview className="w-full">
          {/* TOP SECTION */}
          <section
            className="preview relative dt-size-is-3-1"
            style={{
              background: `url(${backgroundImage}) no-repeat center / cover`,
            }}
          >
            <div className="action-row">
                <ArrowWithTextButton
                  text={t("back", { ns: "libraryPage" })}
                  arrowPosition="left"
                  color="white"
                  onClick={(e: any) => {
                    analyticsPodcastListening()
                    _setStartAudio(false);
                    onClick(e);
                  }}
                />
                <FavoriteButton
                  isFavorited={isFavorited}
                  onClick={handleSetFavorite}
                />
              </div>
          </section>
          {/* BOTTOM SECTION */}
          <section className="other-podcasts">
            <div
              className="flex flex-col gap-4"
              style={{ color: BLACK, minWidth: "55vw" }}
            >
              <h3 className="font-bold text-2xl">{podcast.title}</h3>
              <ReactQuill
                value={podcast.content}
                readOnly={true}
                theme={"bubble"}
              />
              {!startAudio && (
                <PlayPodcastButton
                  onClick={() => {
                    analyticsPodcastListening()
                    _setStartAudio(true);
                  }}
                />
              )}

              {startAudio && (
                <AudioPlayer audio={audio} cancelPlaying={false} onChangeListeningValues={({
                  percentage,
                  minutes,
                }: {
                  percentage: number,
                  minutes: number
                }) => {
                  _setListeningPercentage(percentage)
                  _setListeningMinutes(minutes)
                }} />
              )}
            </div>
          </section>
        </PodcastPreview>
      </div>

      {/* RIGHT ARROW */}
      <div className="hidden md:flex md:basis-1/6 items-center justify-center rotate-180">
        {onReadNext && (
          <ArrowButton
            onClick={() => {
              analyticsPodcastListening()
              _setStartAudio(false);
              onReadNext();
            }}
          />
        )}
      </div>
    </FullPodcastPreviewStyles>
  );
};

export default FullPodcastPreview;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const FullPodcastPreviewStyles = styled.div`
  background-color: rgba(46, 50, 56, 0.4);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  .podcast-main-preview {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

const PodcastPreview = styled.div`
  border-radius: 24px;
  overflow: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  max-height: calc(100vh - 32px);

  .preview {
    padding: 48px 56px;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;

    @media (max-width: 728px) {
      padding: 24px;
    }
  }

  .action-row {
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: relative;
    align-items: flex-start;
  }

  .other-podcasts {
    padding: 40px 56px;
    background-color: ${WHITE};
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    div > div.ql-editor {
      padding: 0;
    }

    @media (max-width: 728px) {
      padding: 24px;
    }
  }
`;
