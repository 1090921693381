import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import home_logo from "../../assets/images/png/home_logo.png";
import teachup_logo from "../../assets/images/png/teachup_logo.png";
import users_logo from "../../assets/images/png/users_logo.png";

import { MdKeyboardArrowRight } from "react-icons/md";

import { LIGHT_BLUE, LIGHT_GREY } from "../../constants/cts_colors";
import SettingsMembers from "../../components/SettingsMembers";
import SettingsCompanies from "../../components/SettingsCompanies";
import SettingsGroups from "../../components/SettingsGroups";
import SettingsTeachup from "../../components/SettingsTeachUp";
import SettingsCoachs from "../../components/SettingsCoachs";
import SettingsAdmin from "../../components/SettingsAdmin";
import TopMenu from "../../components/TopMenu";
import i18next from "i18next";

const SettingsPage = () => {
  const { t } = useTranslation();

  // ***** USERS *****
  const [isUsersMenuOpen, _setIsUsersMenuOpen] = useState<boolean>(false);
  const [actualSettingsCategory, _setActualSettingsCategory] =
    useState<string>("");

  useEffect(() => {
    document.title = t("mainTitle", { ns: "settingsPage" });
  }, [i18next.language]);

  return (
    <Wrapper>
      {/* HEADER */}
      <SecondaryHeader>
        <TopMenu
          sections={["settings"]}
          selectedSection="settings"
          fromPage="settingsPage"
        />
      </SecondaryHeader>

      {/* CONTENT PAGE */}
      <SettingsContent className="flex pl-4">
        <SecondaryMenu>
          <SecondaryItem
            onClick={() => {
              _setIsUsersMenuOpen(!isUsersMenuOpen);
            }}
            className="flex flex-row gap-2 items-center cursor-pointer"
          >
            <img src={users_logo} width={20} />
            <p>{t("users", { ns: "settingsHeader" })}</p>
            <MdKeyboardArrowRight
              size={18}
              style={{
                rotate: isUsersMenuOpen ? "90deg" : "0deg",
                transition: "250ms",
              }}
            />
          </SecondaryItem>
          {/* USERS MENU */}
          <div
            style={{ display: isUsersMenuOpen ? "block" : "none" }}
            className="flex flex-col gap-2 ml-8 mr-2"
          >
            <SubItem
              onClick={() => {
                _setActualSettingsCategory("users_members");
              }}
              style={{
                backgroundColor:
                  actualSettingsCategory === "users_members" ? LIGHT_BLUE : "",
              }}
            >
              {t("members", { ns: "settingsHeader" })}
            </SubItem>
            <SubItem
              onClick={() => {
                _setActualSettingsCategory("users_groups");
              }}
              style={{
                backgroundColor:
                  actualSettingsCategory === "users_groups" ? LIGHT_BLUE : "",
              }}
            >
              {t("groups", { ns: "settingsHeader" })}
            </SubItem>
            <SubItem
              onClick={() => {
                _setActualSettingsCategory("users_coachs");
              }}
              style={{
                backgroundColor:
                  actualSettingsCategory === "users_coachs" ? LIGHT_BLUE : "",
              }}
            >
              {t("coachs", { ns: "settingsHeader" })}
            </SubItem>
            <SubItem
              onClick={() => {
                _setActualSettingsCategory("users_admins");
              }}
              style={{
                backgroundColor:
                  actualSettingsCategory === "users_admins" ? LIGHT_BLUE : "",
              }}
            >
              Admins
            </SubItem>
          </div>

          <SecondaryItem
            className="flex flex-row gap-2 items-center cursor-pointer"
            onClick={() => {
              _setActualSettingsCategory("compagnies");
            }}
            style={{
              backgroundColor:
                actualSettingsCategory === "compagnies" ? LIGHT_BLUE : "",
            }}
          >
            <img src={home_logo} width={16} />
            {t("companies", { ns: "settingsHeader" })}
          </SecondaryItem>
          <SecondaryItem
            className="flex flex-row gap-2 items-center cursor-pointer"
            onClick={() => {
              _setActualSettingsCategory("teachup");
            }}
            style={{
              backgroundColor:
                actualSettingsCategory === "teachup" ? LIGHT_BLUE : "",
            }}
          >
            <img src={teachup_logo} width={24} />
            TeachUp
          </SecondaryItem>
        </SecondaryMenu>

        {/* MEMBERS */}
        {actualSettingsCategory === "users_members" && (
          <SectionContent>
            <SettingsMembers />
          </SectionContent>
        )}

        {/* GROUPS */}
        {actualSettingsCategory === "users_groups" && (
          <SectionContent>
            <SettingsGroups />
          </SectionContent>
        )}

        {/* COACHS */}
        {actualSettingsCategory === "users_coachs" && (
          <SectionContent>
            <SettingsCoachs />
          </SectionContent>
        )}

        {/* ADMINS */}
        {actualSettingsCategory === "users_admins" && (
          <SectionContent>
            <SettingsAdmin />
          </SectionContent>
        )}

        {/* COMPAGNIES */}
        {actualSettingsCategory === "compagnies" && (
          <SectionContent>
            <SettingsCompanies />
          </SectionContent>
        )}

        {/* TEACHUP */}
        {actualSettingsCategory === "teachup" && (
          <SectionContent>
            <SettingsTeachup />
          </SectionContent>
        )}
      </SettingsContent>
    </Wrapper>
  );
};

export default SettingsPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div``;

const SecondaryHeader = styled.div`
  border-bottom: 1px solid ${LIGHT_GREY};
  padding-bottom: 16px;
`;

const SettingsContent = styled.div``;

const SecondaryMenu = styled.div`
  /* max-width: 12vw; */
  min-width: 180px;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  border-right: 1px solid ${LIGHT_GREY};
`;

const SecondaryItem = styled.div`
  cursor: pointer;
  border-radius: 10px;
  margin: 8px;
  padding: 12px;

  :hover {
    background-color: ${LIGHT_BLUE};
  }
`;

const SubItem = styled.div`
  cursor: pointer;
  font-weight: bold;
  padding: 12px 8px;
  margin: 4px 0px;

  border-radius: 10px;
  :hover {
    background-color: ${LIGHT_BLUE};
  }
`;

const SectionContent = styled.div`
  width: 99%;
`;
