import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GREY, LIGHT_GREY, WHITE } from "../../constants/cts_colors";
import { IFormationsInfos } from "../../interfaces/formations";
import FormationCard from "../../components/FormationCard";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { useFormations } from "../../common/contexts/formationContext";
import TopMenu from "../../components/TopMenu";
import { useAnalyticLogs } from "../../common/contexts/analyticLogContext";
import i18next from "i18next";

const TeachUpPage = () => {
  const { t } = useTranslation();
  const { user, token } = useAuthentication();

  useEffect(() => {
    document.title = t("mainTitle", { ns: "teachupPage" });
  }, [i18next.language]);

  const [isPopupOpen, _setIsPopupOpen] = useState<boolean>(false);
  const [popupContent, _setPopupContent] = useState<string>("");

  const { onGetAllUserFormations } = useFormations();
  const [formations, _setFormations] = useState<Array<IFormationsInfos>>([]);

  const menuSections = {
    formations: "formations",
  };
  const [selectedSection, _setSelectedSection] = useState<string>(
    menuSections.formations
  );

  const { onAnalyticsFormationsPageView } = useAnalyticLogs();

  useEffect(() => {
    if (user && token) {
      onGetAllUserFormations()
        .then((response) => {
          _setFormations(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user, token]);

  const onOpenPopup = (link: string) => {
    window.open(link, "lescles-teachup");
  };

  // ANALITYCS - LOG EVENT - PAGE VIEW
  useEffect(() => {
    switch (selectedSection) {
      case menuSections.formations:
        onAnalyticsFormationsPageView()
          .then(() => {})
          .catch((error) => console.error(error))
        break;
    }
  }, [selectedSection])

  return (
    <Wrapper>
      <TopMenu
        sections={[menuSections.formations]}
        selectedSection={menuSections.formations}
        fromPage="teachupPage"
      />
      <SectionContent className="w-full">
        <div className="overflow-scroll mt-8">
          <div
            className="flex flex-row flex-wrap gap-3 justify-evenly ml-2 my-6 max-w-72"
          >
            {formations &&
              formations.length > 0 &&
              formations.map((formation, key) => {
                return (
                  <FormationCard
                    formationId={formation.id}
                    title={formation.title}
                    description={formation.description}
                    language={formation.language}
                    image={formation.image}
                    key={key}
                    onClick={() => {
                      //_setIsPopupOpen(true);
                      onOpenPopup(formation.link);
                      _setPopupContent(formation.link);
                    }}
                  />
                );
              })}
            {formations && formations.length < 1 && (
              <NoContentSentence>
                {t("formations.noFormations", { ns: "settingsPage" })}
              </NoContentSentence>
            )}
          </div>
        </div>
      </SectionContent>
      {/*<PopupContent style={{ display: isPopupOpen ? "block" : "none" }}>
        <iframe
          title="Teachup Embed"
          src={popupContent}
          frame-border="0"
          width="100%"
          height={window.innerHeight}
        ></iframe>
        <span
          onClick={() => {
            _setIsPopupOpen(false);
            _setPopupContent("");
          }}
          style={{ position: "absolute", right: 20, top: 10 }}
        >
          <ImCross className="cursor-pointer" size={24} />
        </span>
        </PopupContent>*/}
    </Wrapper>
  );
};

export default TeachUpPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const Header = styled.div`
  height: 85px;
  display: flex;
  align-items: center;

  & > h2 {
    padding-left: 25px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const SectionContent = styled.div``;

const PopupContent = styled.div`
  z-index: 15;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  & > span {
    color: ${WHITE};

    :hover {
      color: ${LIGHT_GREY};
    }
  }
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;
