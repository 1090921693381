import styled from "styled-components";
import { IPostsInfos } from "../interfaces/posts";
import { useState } from "react";

const PodcastLittleCard = ({
  podcast,
  onClick,
}: {
  podcast: IPostsInfos;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const [isHovered, _setIsHovered] = useState<boolean>(false);
  const images = (podcast.filesHistory || []).filter(
    (f) => f.type.split("/")[0] === "image"
  );
  let bgImage = "";
  if (images.length > 2) {
    bgImage = images[2].url;
  } else if (images.length > 1) {
    bgImage = images[1].url;
  } else if (images.length) {
    bgImage = images[0].url;
  }

  return (
    <PodcastLittleCardStyles
      className="flex flex-col relative"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
      onMouseEnter={() => {
        _setIsHovered(true);
      }}
      onMouseLeave={() => {
        _setIsHovered(false);
      }}
      onClick={onClick}
    >
      <LinearGradient className="absolute top-0 bottom-0 left-0 right-0 z-[0]" />
      <div
        className="flex flex-col z-[100]"
        style={{ marginBottom: isHovered ? 8 : 0 }}
      >
        <p className="font-bold text-base">{podcast.title}</p>
      </div>
    </PodcastLittleCardStyles>
  );
};

export default PodcastLittleCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const PodcastLittleCardStyles = styled.div`
  cursor: pointer;
  height: 252px;
  width: 186px;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  & > div {
    transition: all 250ms;
  }

  p {
    line-height: 1.1rem;
  }
`;

const LinearGradient = styled.div`
  border-radius: 24px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
`;
